import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Route, Switch } from "react-router-dom";
import FallbackPageWrapper from "../../FallbackPageWrapper";
import { FallbackProvider } from "../../context";
import AdminDashboard from "../../modules/Admin/pages/AdminDashboard";
import { loadUserDataSuccess } from "../../modules/Auth/actions/auth.action";
import { AdminLayoutRoutes } from "../../routes/Admin/AdminPrivateRoutes";
import { routes } from "../../routes/Admin/AdminRoutes";
import { AdminRoutes, MinAdminRoutes } from "../../utils/routes";
import Navbar from "../Admin/Navbar";

const Dashboard = () => {
  const navigationRef = useRef();
  const [navigationToggled, toggleNavigation] = useState(false);
  const dispatch = useDispatch();

  const { sessionUserData: AuthData, adminPageTitle } = useSelector(
    (state) => state.Auth
  );
  const role = AuthData.roles;

  useEffect(() => {
    const localCountry = localStorage.getItem("countryCode");
    dispatch(loadUserDataSuccess({ ...AuthData, country: localCountry }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const Location = useLocation();
  const selected = localStorage.getItem("i18nextLng") || "en-US";
  const forMatLang = selected.split("-")[0] || "en";

  const translation = {
    Pricings: {
      en: "Pricings",
      pg: "preços",
    },
    Notifications: {
      en: "Notifications",
      pg: "Noticações",
    },
    Distributors: {
      en: "Distributors",
      pg: "Distribuidores",
    },
    Customers: {
      en: "Customers",
      pg: "Clientes",
    },
    Reports: {
      en: "Reports",
      pg: "Relatórios",
    },
    Users: {
      en: "Users",
      pg: "Usuarios",
    },
    Summary: {
      en: "Summary",
      pg: "Resumo",
    },
    Products: {
      en: "Products",
      pg: "Produtos",
    },
  };

  const clickOutHandler = (event) => {
    if (navigationRef.current?.contains(event.target)) return;
    toggleNavigation(false);
  };

  useEffect(() => {
    if (navigationToggled) {
      setTimeout(() => window.addEventListener("click", clickOutHandler));
    }
    return () => window.removeEventListener("click", clickOutHandler);
  }, [navigationToggled]);

  return (
    <FallbackPageWrapper>
      <div className="flex flex-col h-screen max-h-screen overflow-y-hidden bg-gray-30">
        <div className="flex h-full max-h-full">
          <div
            ref={navigationRef}
            className={`${
              navigationToggled ? "active" : ""
            } navigation-bar h-screen bg-dark overflow-y-auto`}
          >
            <div className="relative flex items-center justify-center">
              <img
                className="pt-3 pb-5"
                src="/assets/svg/kuja-logo.svg"
                alt="logo"
                width={67}
                height={40}
              />
              <span className="absolute top-0 right-0 block text-white lg:hidden">
                <IconButton
                  size="medium"
                  edge={false}
                  color="inherit"
                  aria-label="menu"
                  onClick={() => toggleNavigation(false)}
                >
                  <CloseIcon color="inherit" className="!text-4xl" />
                </IconButton>
              </span>
            </div>
            {role === "Admin" && (
              <ul className="mt-4">
                {AdminRoutes?.map(({ id, label, link, icon }) => {
                  if (Location.pathname === link) {
                    return (
                      <li
                        key={id}
                        className="py-3 text-center bg-transparent border-l-2 border-white bg-opacity-20"
                      >
                        <Link to={link}>
                          <div className="flex my-[2%] mx-auto p-0.5 text-gold justify-center">
                            {icon}
                          </div>
                          <span className="items-center space-x-6">
                            <span className="text-sm font-customRoboto text-gold">
                              {translation[label][forMatLang]}
                            </span>
                          </span>
                        </Link>
                      </li>
                    );
                  } else {
                    return (
                      <li key={id} className="py-4 text-center">
                        <Link to={link}>
                          <div className="flex my-[2%] mx-auto p-0.5 justify-center text-grey-70">
                            {icon}
                          </div>
                          <span className="items-center space-x-6">
                            <span className="text-sm font-customRoboto text-grey-70">
                              {translation[label][forMatLang]}
                            </span>
                          </span>
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
            )}
            {role === "Mini-Admin" && (
              <ul className="mt-4">
                {MinAdminRoutes?.map(({ id, label, link, icon }) => {
                  if (Location.pathname === link) {
                    return (
                      <li
                        key={id}
                        className="py-3 text-center bg-transparent border-l-2 border-white bg-opacity-20"
                      >
                        <Link to={link}>
                          <div className="justify-center flex my-[2%] mx-auto p-0.5 text-gold">
                            {icon}
                          </div>
                          <span className="items-center space-x-6">
                            <span className="text-sm font-customRoboto text-gold">
                              {translation[label][forMatLang]}
                            </span>
                          </span>
                        </Link>
                      </li>
                    );
                  } else {
                    return (
                      <li key={id} className="py-4 text-center">
                        <Link to={link}>
                          <div className="flex my-[2%] mx-auto p-0.5 justify-center text-grey-70">
                            {icon}
                          </div>
                          <span className="items-center space-x-6">
                            <span className="text-sm font-customRoboto text-grey-70">
                              {translation[label][forMatLang]}
                            </span>
                          </span>
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
            )}
          </div>

          <div className="w-full h-full max-h-full col-span-9 overflow-hidden">
            <Navbar
              toggleNavigation={toggleNavigation}
              pageTitle={adminPageTitle.pageTitle}
              subTitle={adminPageTitle.subTitle}
              back={adminPageTitle.back}
              total={adminPageTitle.total}
            />
            <div className="overflow-y-scroll max-h-full h-[calc(100vh-64px)] relative px-4 lg:pl-10 lg:pr-8 pb-16">
              <FallbackProvider>
                <Switch>
                  <Route exact path={routes.AdminDashboard}>
                    <FallbackPageWrapper>
                      <AdminDashboard />
                    </FallbackPageWrapper>
                  </Route>
                  {AdminLayoutRoutes.map((adminRoute) => {
                    return (
                      <Route
                        exact={adminRoute.exact}
                        key={adminRoute.link}
                        path={adminRoute.link}
                        component={adminRoute.component}
                        roles={[adminRoute.role, "Admin"]}
                      />
                    );
                  })}
                </Switch>
              </FallbackProvider>
            </div>
          </div>
        </div>
      </div>
    </FallbackPageWrapper>
  );
};

export default Dashboard;
