import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ProfileDropdown } from "../../components/common/dropdown";
import LanguageSelect from "../../languageSelect";

import arrowDown from "../../assets/svg/arrowDown.svg";
import profileThumbnail from "../../assets/svg/profileThumbnail.svg";

const NavbarRightSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const Auth = useSelector((state) => state.Auth.sessionUserData);
  const country = Auth?.country;

  const showDropdown = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <div className="relative">
        <div
          onClick={showDropdown}
          className="flex items-center cursor-pointer"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          ref={ref}
          role="button"
        >
          <img className="mr-1" alt="" src={profileThumbnail} />
          <img alt="p-icon" src={arrowDown} />
        </div>
        {isVisible && (
          <ProfileDropdown
            setIsVisible={setIsVisible}
            position="right-0 top-[46px]"
          />
        )}
      </div>
      {country === "Mozambique" && (
        <div className="ml-3 language-select-dropdown">
          <LanguageSelect />
        </div>
      )}
    </>
  );
};

export default NavbarRightSection;
