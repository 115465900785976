export const UNSAVED_CHANGES = "UNSAVED_CHANGES";
export const UNSAVED_DIST_CHANGES = "UNSAVED_DIST_CHANGES";
export const INITIAL_PRICE = "INITIAL_PRICE";
export const VIEW_ALL_PRODUCTS = "VIEW_ALL_PRODUCTS";
export const VIEW_ALL_PRODUCTS_NON_ABI = "VIEW_ALL_PRODUCTS_NON_ABI";
export const SAVE_PRICING = "SAVE_PRICING";
export const DIST_SAVE_PRICING = "DIST_SAVE_PRICING"
export const DISCARD_CHANGES = "DISCARD_CHANGES";
export const UPDATED_PRODUCTS = "UPDATED_PRODUCTS";
export const UPDATED_DIST_PRODUCTS = "UPDATED_DIST_PRODUCTS";
export const COUNTRY_PRODUCTS = "COUNTRY_PRODUCTS";
export const COUNTRY_PRODUCTS_START = "COUNTRY_PRODUCTS_START";
export const COUNTRY_PRODUCTS_FAILURE = "COUNTRY_PRODUCTS_FAILURE";
export const UNSAVED_QUANTITY_CHANGES = "UNSAVED_QUANTITY_CHANGES";
export const UPDATED_INVENTORY = "UPDATED_INVENTORY"
export const SET_UPLOAD_BULK_PRICING = "SET_UPLOAD_BULK_PRICING"
