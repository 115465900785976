import jwtDecode from "jwt-decode";
import { setAlert } from "../../redux/actions/common/alert.action";
import userManager from "../userManager";

export default function setupAxios(instance, store) {
  instance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("userData");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response?.status === 401) {
        userManager.signoutRedirect();
        localStorage.clear();
      } else if (err.response?.status === 403) {
        const token = localStorage.getItem("userData");
        const currentUser = jwtDecode(token);
        const activeCountry = localStorage.getItem("countryCode");
        if (currentUser?.country !== activeCountry && !currentUser?.moderator) {
          localStorage.setItem("countryCode", currentUser.country);
        }
        window.location.href = `/forbidden`;
      } else {
        if (
          (err.response?.config.url.startsWith(
            "/inventory/api/v1/inventory/product-det/"
          ) &&
            err.response?.status === 404) ||
          ((err.response?.config.url.startsWith(
            "/report/get-invoice/getbyid"
          ) ||
            err.response?.config.url.startsWith(
              "/finance/GetFinance/GetPaymentDetails"
            )) &&
            err.response?.status === 400)
        ) {
          return;
        } else if (
          err.response?.status === 503 ||
          err.response?.status === 500
        ) {
          console.log(err.response);
        } else {
          const errTitle =
            err.response?.error?.type ||
            err.response?.statusText ||
            "An error occurred";
          let errMessage = "";
          if (typeof err.response?.data === "string") {
            errMessage = err.response?.data;
          } else {
            const errorMessage = err.response?.data?.error?.message;
            if (errorMessage && typeof errorMessage === "object") {
              errorMessage.forEach((msg) => (errMessage += `${msg}.`));
            } else {
              errMessage =
                err.response?.data?.message ||
                err.response?.data?.msg ||
                err.response?.data?.error?.message;
            }
          }
          store.dispatch(setAlert(errTitle, errMessage, "error"));
        }
      }
      return Promise.reject(err);
    }
  );
}
