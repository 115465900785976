export const routes = {
  MiniAdminDashboard: '/min-admin-dashboard',
  MiniAdminReports: '/min-admin-dashboard/reports',
  MiniAdminTotalSalesReport: '/min-admin-dashboard/reports/totalsales',
  MiniAdminTotalDelivery: '/min-admin-dashboard/reports/delivery',
  MiniAdminTotalVolumeSold: '/min-admin-dashboard/reports/volumesold',
  MiniAdminTotalVolumeReceived: '/min-admin-dashboard/reports/volumereceived',
  MiniAdminStockReceived: '/min-admin-dashboard/reports/stockreceived',
  MiniAdminSingleStockReceived: '/min-admin-dashboard/reports/singlestockreceived/:Dist_Code/:docNo',
  MiniAdminTotalWalkInSales: '/min-admin-dashboard/reports/walk-in-sales',
  MiniAdminTotalVanSales: '/min-admin-dashboard/reports/van-sales',
  MiniAdminDailyStockCount: '/min-admin-dashboard/reports/daily-stock-count',
  MiniAdminDistributorStockLevel: '/min-admin-dashboard/reports/stock-level/:Dist_Code',
  MiniAdminDistributorClosingStock: '/min-admin-dashboard/reports/closing-stock/:Dist_Code',
  MiniAdminStockLevel: '/min-admin-dashboard/reports/stock-level',
  MiniAdminClosingStock: '/min-admin-dashboard/reports/closing-stock',
  MiniAdminSummary: '/min-admin-dashboard/summary',
  MiniAdminCustomers: '/min-admin-dashboard/customers',
  MiniAdminDistroDetails: '/min-admin-dashboard/distributor/:code',
  DistributorCustomers: "/min-admin-dashboard/distributor/:distCode/customers",
	DistributorUsers: "/min-admin-dashboard/distributor/:distCode/users",
	DistributorOrders: "/min-admin-dashboard/distributor/:distCode/orders",
  DistributorReports: "/min-admin-dashboard/distributor/:Dist_Code/reports",
  DistributorTotalSales: "/min-admin-dashboard/dist/:Dist_Code/reports/totalsales",
	DistributorStockReceived: "/min-admin-dashboard/dist/:Dist_Code/reports/stockreceived",
	DistributorStockLevel: "/min-admin-dashboard/dist/:Dist_Code/reports/stock-level",
	DistributorClosingStock: "/min-admin-dashboard/dist/:Dist_Code/reports/closing-stock",
	DistributorVolumeSold: "/min-admin-dashboard/dist/:Dist_Code/reports/volumesold",
	DistributorVolumeReceived: "/min-admin-dashboard/dist/:Dist_Code/reports/volumereceived",
	DistributorTotalDelivery: "/min-admin-dashboard/dist/:Dist_Code/reports/sales-by-delivery",
	DistributorWalkInSales: "/min-admin-dashboard/dist/:Dist_Code/reports/walk-in-sales",
	DistributorVanSales: "/min-admin-dashboard/dist/:Dist_Code/reports/van-sales",
	DistributorOrderSummary:
		"/min-admin-dashboard/dist/:distCode/order-summary/:orderId/:buyerCode",
	CustomerOrderSummary:
		"/min-admin-dashboard/dist/:sysproCode/customer/:buyerCode/order-summary/:orderId/:distCode",
  ManageCustomer: '/min-admin-dashboard/dist/:sysproCode/manage-customer/:id/:distCode',
  PageNotFound: "*"
}
