import { ReactComponent as CustomersIcon } from "../assets/svg/customersIcon.svg";
import { ReactComponent as DistributorIcon } from "../assets/svg/distributor-icon.svg";
import { ReactComponent as FinanceIcon } from "../assets/svg/financeIcon.svg";
import { ReactComponent as FinanceIcon2 } from "../assets/svg/financeIcon2.svg";
import { ReactComponent as HomeIcon } from "../assets/svg/home-icon.svg";
import { ReactComponent as InventoryIcon } from "../assets/svg/inventoryIcon.svg";
import { ReactComponent as LogisticsIcon } from "../assets/svg/logistics1.svg";
import { ReactComponent as NotificationIcon } from "../assets/svg/notificationIcon.svg";
import { ReactComponent as PricingIcon } from "../assets/svg/pricingIcon.svg";
import { ReactComponent as ProductsIcon } from "../assets/svg/products.svg";
import { ReactComponent as ReportIcon } from "../assets/svg/reportIcon.svg";
import { ReactComponent as SummaryIcon } from "../assets/svg/summaryIcon.svg";
import { ReactComponent as TransactionIcon } from "../assets/svg/transactionIcon.svg";
import { ReactComponent as VanWarehouseIcon } from "../assets/svg/vanWarehouseIcon.svg";

export const KpoRoutes = (code, country) => {
  const routes = [
    {
      label: "Home",
      link: `/dashboard/overview/${code}`,
      icon: <HomeIcon />,
    },
    {
      label: `${country === "Tanzania" ? "Stock" : "Inventory"}`,
      link: `/dashboard/inventory/${code}`,
      icon: <InventoryIcon />,
    },
    {
      label: "Transactions",
      link: `/dashboard/transactions/${code}`,
      icon: <TransactionIcon />,
    },
    {
      label: "Finance",
      link: `/dashboard/finance/${code}`,
      icon: <FinanceIcon />,
      icon2: <FinanceIcon2 />,
    },
    {
      label: "Van Warehouse",
      link: `/dashboard/van-inventory/${code}`,
      icon: <VanWarehouseIcon />,
    },
    {
      label: "Customers",
      link: `/dashboard/customers/${code}`,
      icon: <CustomersIcon />,
    },
    {
      label: "Analytics",
      link: `/dashboard/analytics/${code}`,
      icon: <ReportIcon />,
    },
    {
      label: "Logistics",
      link: `/dashboard/logistics/${code}`,
      icon: <LogisticsIcon />,
    },
  ];
  return routes;
};

export const AdminRoutes = [
  {
    id: 0,
    label: "Distributors",
    link: "/admin-dashboard",
    icon: <DistributorIcon />,
  },
  {
    id: 1,
    label: "Notifications",
    link: "/admin-dashboard/notifications",
    icon: <NotificationIcon />,
  },
  {
    id: 2,
    label: "Reports",
    link: "/admin-dashboard/reports",
    icon: <ReportIcon />,
  },
  {
    id: 3,
    label: "Pricings",
    link: "/admin-dashboard/pricings",
    icon: <PricingIcon />,
  },
  {
    id: 4,
    label: "Products",
    link: "/admin-dashboard/products",
    icon: <ProductsIcon />,
  },
  {
    id: 5,
    label: "Customers",
    link: `/admin-dashboard/customers`,
    icon: <CustomersIcon />,
  },
  {
    id: 8,
    label: "Users",
    link: "/admin-dashboard/users",
    icon: <CustomersIcon />,
  },
];

export const MinAdminRoutes = [
  {
    id: 0,
    label: "Distributors",
    link: "/min-admin-dashboard",
    icon: <DistributorIcon />,
  },
  {
    id: 1,
    label: "Reports",
    link: "/min-admin-dashboard/reports",
    icon: <ReportIcon />,
  },
  {
    id: 2,
    label: "Customers",
    link: `/min-admin-dashboard/customers`,
    icon: <CustomersIcon />,
  },
  {
    id: 3,
    label: "Summary",
    link: "/min-admin-dashboard/summary",
    icon: <SummaryIcon />,
  },
];

export const DistributorRoutes = (code, role) => {
  const distributorLink = [
    {
      label: "Overview",
      link:
        role === "Mini-Admin"
          ? `/min-admin-dashboard/distributor/${code}`
          : `/admin-dashboard/distributor/${code}`,
    },
    {
      label: "Customers",
      link:
        role === "Mini-Admin"
          ? `/min-admin-dashboard/distributor/${code}/customers`
          : `/admin-dashboard/distributor/${code}/customers`,
    },
    {
      label: "Users",
      link:
        role === "Mini-Admin"
          ? `/min-admin-dashboard/distributor/${code}/users`
          : `/admin-dashboard/distributor/${code}/users`,
    },
    {
      label: "Reports",
      link:
        role === "Mini-Admin"
          ? `/min-admin-dashboard/distributor/${code}/reports`
          : `/admin-dashboard/distributor/${code}/reports`,
    },
    {
      label: "Orders",
      link:
        role === "Mini-Admin"
          ? `/min-admin-dashboard/distributor/${code}/orders`
          : `/admin-dashboard/distributor/${code}/orders`,
    },
  ];
  return distributorLink;
};
