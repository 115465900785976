export const GET_SINGLE_VAN_INVENTORY = "GET_SINGLE_VAN_INVENTORY";
export const LOAD_VAN_INVENTORY = "LOAD_VAN_INVENTORY";
export const OFF_LOAD_VAN_INVENTORY = "OFF_LOAD_VAN_INVENTORY";
export const UNSAVED_VAN_CHANGES = "UNSAVED_VAN_CHANGES";
export const UPDATED_QUANTITY = "UPDATED_QUANTITY"
export const DISCARD_CHANGES = "DISCARD_CHANGES"
export const DRIVER_DETAILS = "DRIVER_DETAILS"
export const ASSIGN_TO_VAN = "ASSIGN_TO_VAN"
export const DRIVER_TO_WAREHOUSE = "DRIVER_TO_WAREHOUSE"
export const WAREHOUSE_TO_DRIVER = "WAREHOUSE_TO_DRIVER"
export const SET_DRIVER_ID = "SET_DRIVER_ID"