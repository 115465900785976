import { Suspense, createContext, useCallback, useMemo, useState } from "react";
import ProgressBar from "react-topbar-progress-indicator";

// Progress Bar -> Configuration
ProgressBar.config({
  barColors: {
    0: "#E5B611",
    0.5: "#838181",
    "1.0": "#fff86d",
  },
  shadowBlur: 0,
  barThickness: 4,
});

export const FallbackContext = createContext({
  updateFallback: () => {},
});

export const FallbackProvider = ({ children }) => {
  const [fallback, setFallback] = useState(null);

  const updateFallback = useCallback((fallbackElement) => {
    setFallback(() => fallbackElement);
  }, []);

  const renderChildren = useMemo(() => children, [children]);

  return (
    <FallbackContext.Provider value={{ updateFallback }}>
      <Suspense
        fallback={
          <>
            <ProgressBar />
            {fallback}
          </>
        }
      >
        {renderChildren}
      </Suspense>
    </FallbackContext.Provider>
  );
};
