import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ component: Component, roles, oidc, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("userData");
  let AuthData = "";
  if (token) {
    AuthData = jwtDecode(token);
  }

  useEffect(() => {
    if (AuthData && roles?.includes(AuthData.roles)) {
      if (
        !roles.includes("Moderator") ||
        (roles.includes("Moderator") && AuthData.moderator)
      ) {
        setIsAuthenticated(true);
      }
    }
    setLoading(false);
  }, [AuthData, roles]);

  return (
    !loading && (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: `/`, state: { from: props.location } }} />
          )
        }
      />
    )
  );
};

export default PrivateRoute;
