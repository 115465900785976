import React from "react";

const Button = ({ onClick, btnText }) => {
  return (
    <button
      className="px-10 py-3 mt-10 text-base font-semibold text-center rounded bg-gold text-grey-100 focus:outline-none"
      type="button"
      onClick={onClick}
    >
      {btnText}
    </button>
  );
};

export default Button;
