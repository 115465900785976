import { lazy } from "react";
import { routes } from "./AdminRoutes";

const AdminDashboard = lazy(() => import("../../Layout/Admin/Dashboard"));
const AdminNotifications = lazy(() =>
  import("../../modules/Notification/pages/AdminNotificationList")
);
const AdminReports = lazy(() =>
  import("../../modules/Admin/Reports/AdminReports")
);
const AdminTotalSalesReport = lazy(() =>
  import("../../modules/Admin/Reports/AdminReports/TotalSalesReport")
);
const AdminTotalDelivery = lazy(() =>
  import("../../modules/Admin/Reports/AdminReports/TotalSalesByDelivery")
);
const AdminTotalVolumeSold = lazy(() =>
  import("../../modules/Admin/Reports/AdminReports/TotalSalesByVolumeSold")
);
const AdminTotalVolumeReceived = lazy(() =>
  import("../../modules/Admin/Reports/AdminReports/TotalSalesByVolumeReceived")
);
const AdminStockReceived = lazy(() =>
  import("../../modules/Admin/Reports/AdminReports/AdminStockReceived")
);
const AdminSingleStockReceived = lazy(() =>
  import("../../modules/Admin/Reports/AdminReports/AdminSingleStock")
);
const AdminTotalWalkInSales = lazy(() =>
  import("../../modules/Admin/Reports/AdminReports/TotalSalesByWalkin")
);
const AdminTotalVanSales = lazy(() =>
  import("../../modules/Admin/Reports/AdminReports/TotalSalesByVan")
);
const AdminDailyStockCount = lazy(() =>
  import("../../modules/Admin/Reports/AdminReports/DailyStockCount")
);
const AdminClosingStockReport = lazy(() =>
  import("../../modules/Admin/Reports/AdminReports/ClosingStock")
);
const AdminStockLevel = lazy(() =>
  import("../../modules/Admin/Reports/AdminReports/StockLevel")
);
const AdminAllCustomers = lazy(() =>
  import("../../modules/Admin/customer/AllCustomers")
);
const AdminDistDetails = lazy(() =>
  import("../../modules/Admin/pages/AdminDistroDetails")
);
const AdminPricing = lazy(() => import("../../modules/Admin/Pricing"));
const DistributorUsers = lazy(() => import("../../modules/Admin/user"));
const DistributorOrders = lazy(() => import("../../modules/Admin/order/list"));
const DistributorOrderSummary = lazy(() =>
  import("../../modules/Admin/order/AdminDistOrders")
);
const DistributorCustomers = lazy(() =>
  import("../../modules/Admin/customer/list")
);
const DistributorTotalSales = lazy(() =>
  import("../../modules/Admin/Reports/Distributors/TotalSales")
);
const DistributorTotalDelivery = lazy(() =>
  import("../../modules/Admin/Reports/Distributors/SalesByDelivery")
);
const DistributorWalkInSales = lazy(() =>
  import("../../modules/Admin/Reports/Distributors/WalkInSales")
);
const DistributorVanSales = lazy(() =>
  import("../../modules/Admin/Reports/Distributors/VanSales")
);
const DistributorVolumeSold = lazy(() =>
  import("../../modules/Admin/Reports/Distributors/VolumeSold")
);
const DistributorVolumeReceived = lazy(() =>
  import("../../modules/Admin/Reports/Distributors/VolumeReceived")
);
const DistributorStockReceived = lazy(() =>
  import("../../modules/Admin/Reports/Distributors/StockReceived")
);
const DistributorStockLevel = lazy(() =>
  import("../../modules/Admin/Reports/Distributors/StockLevel")
);
const DistributorClosingStock = lazy(() =>
  import("../../modules/Admin/Reports/Distributors/ClosingStock")
);
const DistributorReports = lazy(() =>
  import("../../modules/Admin/Reports/Distributors")
);
const Users = lazy(() => import("../../modules/Admin/Users/ListUsers"));
const ManageUser = lazy(() => import("../../modules/Admin/Users/ManageUsers"));
const ManageCustomer = lazy(() =>
  import("../../modules/Admin/customer/manage")
);
const OrderSummary = lazy(() =>
  import("../../modules/Admin/order/OrderSummary")
);
const CustomerOrderSummary = lazy(() =>
  import("../../modules/Admin/order/AdminCustomerOrders")
);
const Products = lazy(() => import("../../modules/Admin/Products/pages"));
const AddAbiProduct = lazy(() =>
  import("../../modules/Admin/Products/pages/AddAbiProduct")
);
const EditProduct = lazy(() =>
  import("../../modules/Admin/Products/pages/EditProduct")
);
const EditDraftProduct = lazy(() =>
  import("../../modules/Admin/Products/pages/EditDraftProduct")
);
const AddMpProduct = lazy(() =>
  import("../../modules/Admin/Products/pages/AddMPProduct")
);
const CountrySelector = lazy(() =>
  import("../../modules/Admin/pages/CountrySelector")
);
const PageNotFoundMain = lazy(() =>
  import("../../modules/Auth/pages/PageNotFound/404")
);

export const AdminLayoutRoutes = [
  {
    component: AdminNotifications,
    link: routes.AdminNotifications,
    exact: true,
  },
  { component: AdminAllCustomers, link: routes.AdminAllCustomers, exact: true },
  { component: ManageCustomer, link: routes.ManageCustomer, exact: true },
  {
    component: CustomerOrderSummary,
    link: routes.CustomerOrderSummary,
    role: "Mini-Admin",
    exact: true,
  },
  {
    component: DistributorOrderSummary,
    link: routes.DistributorOrderSummary,
    role: "Mini-Admin",
    exact: true,
  },
  {
    component: DistributorTotalSales,
    link: routes.DistributorTotalSales,
    role: "Mini-Admin",
    exact: true,
  },
  {
    component: DistributorWalkInSales,
    link: routes.DistributorWalkInSales,
    role: "Mini-Admin",
    exact: true,
  },
  {
    component: DistributorStockReceived,
    link: routes.DistributorStockReceived,
    role: "Mini-Admin",
    exact: true,
  },
  {
    component: DistributorStockLevel,
    link: routes.DistributorStockLevel,
    role: "Mini-Admin",
    exact: true,
  },
  {
    component: DistributorClosingStock,
    link: routes.DistributorClosingStock,
    role: "Mini-Admin",
    exact: true,
  },
  {
    component: DistributorVolumeSold,
    link: routes.DistributorVolumeSold,
    role: "Mini-Admin",
    exact: true,
  },
  {
    component: DistributorVolumeReceived,
    link: routes.DistributorVolumeReceived,
    role: "Mini-Admin",
    exact: true,
  },
  {
    component: DistributorTotalDelivery,
    link: routes.DistributorTotalDelivery,
    role: "Mini-Admin",
    exact: true,
  },
  {
    component: DistributorVanSales,
    link: routes.DistributorVanSales,
    role: "Mini-Admin",
    exact: true,
  },
  { component: AdminDistDetails, link: routes.AdminDistDetails },
  { component: AdminPricing, link: routes.AdminPricing, exact: true },
  { component: AdminReports, link: routes.AdminReports, exact: true },
  {
    component: AdminTotalSalesReport,
    link: routes.AdminTotalSalesReport,
    exact: true,
  },
  {
    component: AdminTotalDelivery,
    link: routes.AdminTotalDelivery,
    exact: true,
  },
  {
    component: AdminTotalVolumeSold,
    link: routes.AdminTotalVolumeSold,
    exact: true,
  },
  {
    component: AdminTotalVolumeReceived,
    link: routes.AdminTotalVolumeReceived,
    exact: true,
  },
  {
    component: AdminStockReceived,
    link: routes.AdminStockReceived,
    exact: true,
  },
  {
    component: AdminSingleStockReceived,
    link: routes.AdminSingleStockReceived,
    exact: true,
  },
  {
    component: AdminTotalWalkInSales,
    link: routes.AdminTotalWalkInSales,
    exact: true,
  },
  {
    component: AdminTotalVanSales,
    link: routes.AdminTotalVanSales,
    exact: true,
  },
  {
    component: AdminDailyStockCount,
    link: routes.AdminDailyStockCount,
    exact: true,
  },
  {
    component: AdminClosingStockReport,
    link: routes.AdminClosingStockReport,
    exact: true,
  },
  { component: AdminStockLevel, link: routes.AdminStockLevel, exact: true },
  {
    component: AdminTotalSalesReport,
    link: routes.AdminTotalSalesReport,
    exact: true,
  },
  { component: AdminReports, link: routes.AdminReports, exact: true },
  { component: Users, link: routes.Users, exact: true },
  { component: ManageUser, link: routes.ManageUser, exact: true },
  { component: AddAbiProduct, link: routes.AddAbiProduct, exact: true },
  { component: AddMpProduct, link: routes.AddMpProduct, exact: true },
  { component: EditProduct, link: routes.EditProduct, exact: true },
  { component: EditDraftProduct, link: routes.EditDraftProduct, exact: true },
  { component: Products, link: routes.Products, exact: true },
  {
    component: OrderSummary,
    link: routes.OrderSummary,
    role: "Mini-Admin",
    exact: true,
  },
  { component: PageNotFoundMain, link: routes.PageNotFound },
];

export const AdminDistRoutes = [
  {
    component: DistributorCustomers,
    link: routes.DistributorCustomers,
    exact: true,
  },
  { component: DistributorUsers, link: routes.DistributorUsers, exact: true },
  {
    component: DistributorReports,
    link: routes.DistributorReports,
    exact: true,
  },
  { component: DistributorOrders, link: routes.DistributorOrders, exact: true },
];

export const PrivateRoutes = [
  {
    component: CountrySelector,
    link: routes.CountrySelector,
    role: "Moderator",
  },
  { component: AdminDashboard, link: routes.AdminDashboard },
];
