import {
  VIEW_ALL_ORDERS,
  VIEW_SINGLE_ORDER,
  VIEW_ORDERS_BY_BUYER_CODE,
  GET_ALL_ORDERS,
  ASSIGN_ORDER_TO_DRIVER,
  GET_ALL_DRIVERS_BY_OWNER_ID,
  GET_ALL_DRIVERS,
  DIST_COMPLETED_ORDERS,
  CAPTURE_SALES,
  CAPTURE_SALES_START,
  CAPTURE_SALES_FAILED,
  CAPTURE_SALES_CLEAR,
  RELOAD_INVENTORY,
  RELOAD_INVENTORY_END,
  GET_ALL_ORDERS_BY_DATE,
  GET_ALL_ORDERS_SUMMARY_BY_DATE,
  GET_PAGINATED_ORDERS_BY_DATE,
  GET_ALL_ORDERS_BY_DATE_FOR_REPORT,
  GET_ORDERS_BY_DATE_AND_STATUS_FOR_REPORT,
  GET_MINI_ADMIN_SUMMARY,
  GET_ALL_VSM,
  GET_ALL_ORDERS_BY_DRIVER,
  RESET,
  ORDERS_DATA,
  SET_VEHICLE_ID,
  UNSUSPEND_VEHICLE,
  SUSPEND_VEHICLE,
  SET_ORDER_DATA,
  SET_SINGLE_ORDER,
  SET_LOADING,
  GET_TOTAL_CUSTOMERS_BREAKDOWN,
  SET_SELECTED_ORDERS,
  ASSIGN_MODAL,
  REVERT_ORDER,
  SELECTED_DATE_RANGE
} from "../actions/types";

const initial_state = {
  order: {},
  total_orders: {},
  single_order: {},
  all_orders: [],
  totalOrdersCount: 0,
  total_orders_amount: 0,
  buyer_orders: [],
  all_system_orders: [],
  all_orders_by_date: [],
  all_orders_by_date_report: [],
  orders_by_date_and_status_report: [],
  all_drivers: [],
  all_orders_driver: [],
  assigned_orders: {},
  dist_completed_orders: [],
  captureSalesRes: {},
  loadingcaptureSalesRes: false,
  captureSalesError: null,
  reloadinv: false,
  vsms: [],
  loading: true,
  all_system_drivers: [],
  vehicle_id: 0,
  suspend_vehicle: false,
  unsuspend_vehicle: false,
  order_data: "Placed",
  total_customers: 0,
  high_end: 0,
  bulkbreaker: 0,
  low_end: 0,
  mainstream: 0,
  reseller: 0,
  poc: 0,
  stockist: 0,
  previouslySelectedItems: {
    orders: [],
    currentPage: 1,
    checkedOrders: {},
    selectedOrders: [],
    assignedDrivers: {}
  },
  showAssignModal: false,
  orderReverted: false,
  selected_day_range: {},
  summary:{}
};

const ordersReducer = (state = initial_state, action) => {
  switch (action.type) {
    case VIEW_ALL_ORDERS: {
      return { 
        ...state, all_orders: action.all_orders, 
        totalOrdersCount: action.totalOrdersCount,
        loading: false
      };
    }
    case GET_TOTAL_CUSTOMERS_BREAKDOWN: {
			return {
				...state,
				total_customers: action.total_customers,
				high_end: action.high_end,
				bulkbreaker: action.bulkbreaker,
				low_end: action.low_end,
				mainstream: action.mainstream,
				reseller: action.reseller,
        poc: action.poc,
        stockist: action.stockist
			};
		}
    case ORDERS_DATA: {
      return { ...state, total_orders: action.data };
    }
    case VIEW_SINGLE_ORDER: {
      return {
        ...state,
        order: action.single_order,
        loadingsales: false,
        loading: false,
      };
    }
    case RESET: {
      return { ...state, order: {}, loadingsales: true };
    }
    case VIEW_ORDERS_BY_BUYER_CODE: {
      return { ...state, buyer_orders: action.order };
    }
    case GET_ALL_ORDERS_BY_DRIVER: {
      return { ...state, all_orders_driver: action.all_orders_driver };
    }
    case GET_ALL_ORDERS: {
      return { ...state, all_system_orders: action.all_system_orders };
    }
    case GET_ALL_ORDERS_BY_DATE: {
      return {
        ...state,
        all_orders_by_date: action.all_orders_by_date,
        totalOrdersCount: action.count,
        loading: action.loading,
      };
    }
    case GET_ALL_ORDERS_SUMMARY_BY_DATE: {
      return {
        ...state,
        summary: action.summary,
        loading: action.loading,
      };
    }
    case GET_PAGINATED_ORDERS_BY_DATE: {
      return {
        ...state,
        all_orders_by_date: action.all_orders_by_date,
        totalOrdersCount: action.count,
        loading: action.loading,
      };
    }
    case GET_ALL_ORDERS_BY_DATE_FOR_REPORT: {
      return {
        ...state,
        all_orders_by_date_report: action.all_orders_by_date,
        total_orders_amount: action.total_orders_amount
      };
    }
    case GET_ORDERS_BY_DATE_AND_STATUS_FOR_REPORT: {
      return {
        ...state,
        orders_by_date_and_status_report: action.orders_by_date_and_status
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case GET_MINI_ADMIN_SUMMARY: {
      return {
        ...state,
        orders_by_date: action.orders_by_date,
        stocks_by_date: action.stocks_by_date,
        dists: action.dists,
        loading: action.loading,
      };
    }
    case ASSIGN_ORDER_TO_DRIVER: {
      return { ...state, assigned_orders: action.assigned_order };
    }
    case GET_ALL_DRIVERS_BY_OWNER_ID: {
      return { ...state, all_drivers: action.all_drivers };
    }
    case GET_ALL_DRIVERS: {
      return { ...state, all_system_drivers: action.all_system_drivers };
    }
    case SET_ORDER_DATA: {
      return { ...state, order_data: action.order_data };
    }
    case SET_VEHICLE_ID: {
      return { ...state, vehicle_id: action.vehicleId };
    }
    case SET_SINGLE_ORDER: {
      return { ...state, single_order: action.single_order };
    }
    case SUSPEND_VEHICLE: {
      return { ...state, suspend_vehicle: action.suspendVehicle };
    }
    case UNSUSPEND_VEHICLE: {
      return { ...state, unsuspend_vehicle: action.unsuspendVehicle };
    }
    case DIST_COMPLETED_ORDERS: {
      return { ...state, dist_completed_orders: action.dist_completed_orders };
    }
    case CAPTURE_SALES_START: {
      return { ...state, loadingcaptureSalesRes: true };
    }
    case RELOAD_INVENTORY: {
      return { ...state, reloadinv: true };
    }
    case RELOAD_INVENTORY_END: {
      return { ...state, reloadinv: false };
    }
    case GET_ALL_VSM: {
      return { ...state, vsms: action.vsms };
    }
    case CAPTURE_SALES: {
      return {
        ...state,
        loadingcaptureSalesRes: false,
        captureSalesRes: action.payload,
        captureSalesError: null,
      };
    }
    case CAPTURE_SALES_FAILED: {
      return {
        ...state,
        loadingcaptureSalesRes: false,
        captureSalesRes: null,
        captureSalesError: action.payload,
      };
    }
    case CAPTURE_SALES_CLEAR: {
      return {
        ...state,
        loadingcaptureSalesRes: false,
        captureSalesRes: {},
        captureSalesError: null,
      };
    }
    case SET_SELECTED_ORDERS: {
      return {
        ...state,
        previouslySelectedItems: {
          orders: action.orders,
          currentPage: action.currentPage,
          checkedOrders: action.checkedOrders,
          selectedOrders: action.selectedOrders,
          assignedDrivers: action.assignedDrivers
        }
      }
    }
    case ASSIGN_MODAL: {
      return {
        ...state,
        showAssignModal: action.showAssignModal
      }
    }
    case REVERT_ORDER: {
      return {
        ...state,
        orderReverted: action.orderReverted
      }
    }
    case SELECTED_DATE_RANGE:
      return {
        ...state,
        selected_day_range: action.range,
      };
    default:
      return state;
  }
};

export default ordersReducer;
