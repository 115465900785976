import React from 'react'
import { useSelector } from "react-redux";
import AuthLayout from '../../Layout';
import Main from './404';
import AdminDashboard from '../../../../Layout/Admin/Dashboard';
import MiniAdminDashboard from '../../../../Layout/Mini-Admin/Dashboard';
import KPODashboard from '../../../../Layout/Dashboard';

const PageNotFound = () => {
  const user = useSelector((state) => state.Auth.sessionUserData);

  return (
    <>
      {
        user?.roles === 'Admin'
      ?
        <AdminDashboard />
      :
        user?.roles === 'Mini-Admin'
      ?
        <MiniAdminDashboard />
      :
        user?.roles === 'KPO'
      ?
        <KPODashboard />
      :
        <AuthLayout
          main={<Main />}
        />
      }
    </>
  )
}

export default PageNotFound