import React from "react";
import AuthSidebar from "../../../components/common/AuthSidebar";
import userManager from "../../../utils/userManager";
import { useTranslation } from "react-i18next";

const Blocked = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="h-screen w-screen flex overflow-hidden">
        <div className="hidden md:block h-full w-[30rem]">
          <AuthSidebar />
        </div>
        <div className="h-100 w-[78rem]">
          <div
            className="mt-80 ml-72 px-0 flex flex-col h-full"
          >
            <p
              className="text-black-400 font-normal font-customGilroy pt-3 pb-2 text-[25px] text-active"
            >
              {t(
                "Your_account_has_been_blocked_hence_you_are_not_permitted_to_access_your_dashboard"
              )}
            </p>
            <button
              className="bg-gold w-full md:w-56 lg:w-56 my-5 py-3 text-grey-100 focus:outline-none rounded text-center text-base font-bold font-customRoboto"
              type="button"
              onClick={() => userManager.signinRedirect()}
            >
              {t("continue")}
            </button>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blocked;
