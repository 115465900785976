import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Route, Switch } from "react-router-dom";
import FallbackPageWrapper from "../../FallbackPageWrapper";
import { FallbackProvider } from "../../context";
import { loadUserDataSuccess } from "../../modules/Auth/actions/auth.action";
import MiniAdminDashboard from "../../modules/Mini-Admin/pages/Dashboard";
import { MiniAdminLayoutRoutes } from "../../routes/MiniAdmin/MiniAdminPrivateRoutes";
import { routes } from "../../routes/MiniAdmin/MiniAdminRoutes";
import { MinAdminRoutes } from "../../utils/routes";
import Navbar from "../Admin/Navbar";

const Dashboard = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("userData");
  const navigationRef = useRef();
  const [navigationToggled, toggleNavigation] = useState(false);

  const { sessionUserData: AuthData, adminPageTitle } = useSelector(
    (state) => state.Auth
  );

  useEffect(() => {
    if (token) {
      dispatch(loadUserDataSuccess(AuthData));
    }
  }, [AuthData, token, dispatch]);

  const Location = useLocation();

  const clickOutHandler = (event) => {
    if (navigationRef.current?.contains(event.target)) return;
    toggleNavigation(false);
  };

  useEffect(() => {
    if (navigationToggled) {
      setTimeout(() => window.addEventListener("click", clickOutHandler));
    }
    return () => window.removeEventListener("click", clickOutHandler);
  }, [navigationToggled]);

  return (
    <FallbackPageWrapper>
      <div className="flex flex-col h-screen max-h-screen overflow-y-hidden bg-gray-30">
        <div className="flex h-full max-h-full">
          <div
            ref={navigationRef}
            className={`${
              navigationToggled ? "active" : ""
            } navigation-bar h-screen bg-dark`}
          >
            <img
              className="pt-3 pb-5 mx-auto"
              src="/assets/svg/kuja-logo.svg"
              alt="logo"
              width={67}
              height={40}
            />
            <span className="absolute top-0 right-0 block text-white lg:hidden">
              <IconButton
                size="medium"
                edge={false}
                color="inherit"
                aria-label="menu"
                onClick={() => toggleNavigation(false)}
              >
                <CloseIcon color="inherit" className="!text-4xl" />
              </IconButton>
            </span>
            <ul className="mt-4">
              {MinAdminRoutes.map(({ icon, id, label, link }) => {
                if (Location.pathname === link) {
                  return (
                    <li
                      key={id}
                      className="py-3 text-center bg-transparent border-l-2 border-white bg-opacity-20"
                    >
                      <Link to={link}>
                        <div className="justify-center text-gold p-0.5 flex my-[2%] mx-auto">
                          {icon}
                        </div>
                        <span className="items-center space-x-6">
                          <span className="text-sm font-customRoboto text-gold">
                            {label}
                          </span>
                        </span>
                      </Link>
                    </li>
                  );
                } else {
                  return (
                    <li key={id} className="py-4 text-center">
                      <Link to={link}>
                        <div className="flex my-[2%] mx-[auto] p-0.5 justify-center text-grey-70">
                          {icon}
                        </div>
                        <span className="items-center space-x-6">
                          <span className="text-sm font-customRoboto text-grey-70">
                            {label}
                          </span>
                        </span>
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>
          </div>

          <div className="w-full h-full max-h-full col-span-9 overflow-hidden">
            <Navbar
              toggleNavigation={toggleNavigation}
              pageTitle={adminPageTitle.pageTitle}
              back={adminPageTitle.back}
              total={adminPageTitle.total}
            />
            <div className="overflow-y-scroll max-h-full h-[calc(100vh-64px)] px-4 lg:pl-10 lg:pr-8 pb-16">
              <FallbackProvider>
                <Switch>
                  <Route exact path={routes.MiniAdminDashboard}>
                    <MiniAdminDashboard />
                  </Route>
                  {MiniAdminLayoutRoutes.map((adminRoute) => {
                    return (
                      <Route
                        exact={adminRoute.exact}
                        key={adminRoute.link}
                        path={adminRoute.link}
                        component={adminRoute.component}
                      />
                    );
                  })}
                </Switch>
              </FallbackProvider>
            </div>
          </div>
        </div>
      </div>
    </FallbackPageWrapper>
  );
};

export default Dashboard;
