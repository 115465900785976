import AuthSidebar from "../../../components/common/AuthSidebar";

const AuthLayout = ({ isWelcome, main }) => {

	const welcomeDivStyle = {
		backgroundImage: "url('/assets/svg/Background.svg')",
		backgroundPosition: "bottom",
		backgroundRepeat: "no-repeat",
		backgroundColor: '#EBEBEB'
	}

  return (
		<div className="h-screen w-screen flex overflow-hidden flex-col md:flex-row">
			<div className="splash-sidebar relative">
				<AuthSidebar />
			</div>
			<div className={`h-100 w-100 relative flex items-start justify-center ${isWelcome && "welcome-div bg-contain"}`}
				style={isWelcome && welcomeDivStyle}
			>
				{main}
			</div>
		</div>
	);
};

export default AuthLayout;
