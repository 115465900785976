import {
  GET_SINGLE_VAN_INVENTORY,
  LOAD_VAN_INVENTORY,
  OFF_LOAD_VAN_INVENTORY,
  UNSAVED_VAN_CHANGES,
  UPDATED_QUANTITY,
  DISCARD_CHANGES,
  DRIVER_DETAILS,
  ASSIGN_TO_VAN,
  DRIVER_TO_WAREHOUSE,
  WAREHOUSE_TO_DRIVER,
  SET_DRIVER_ID
} from "./types";
import urls from "../../../utils/urls";
import axiosInstance from "../../../utils/axios";

const { inventory, vehicleNet } = urls

export const getAllSingleVanInventory = (vanId) => async (dispatch) => {
  vanId && axiosInstance
    .get(`${inventory}van/${vanId}`)
    .then((response) => {
      const { data } = response.data;
      dispatch({
        type: GET_SINGLE_VAN_INVENTORY,
        all_single_inventory: data,
      });
    })
    .catch((error) => {
      return;
    });
};

export const transferQuantity =
  (quantitiesToSave, distCode, driverId) => (dispatch) => {
    dispatch({
      type: UPDATED_QUANTITY,
      transfer_quantities: quantitiesToSave,
      driverId,
      distCode,
    });
  };

export const discardChanges = () => (dispatch) => {
  dispatch({
    type: DISCARD_CHANGES,
  });
};

export const updateTransferQuantity = (action) => (dispatch) => {
  dispatch({
    type: UNSAVED_VAN_CHANGES,
    transfer_change: action,
  });
};

export const loadVanData = (values, replenishmentType, deductWarehouseInventory=true) => async (dispatch) => {
  axiosInstance
    .post(
      `${inventory}van/replenish?replenishmentType=${replenishmentType}&deductWarehouseInventory=${deductWarehouseInventory}`,
      values
    )
    .then((response) => {
      return dispatch({
        type: LOAD_VAN_INVENTORY,
        loadVan: response.data,
      });
    })
    .catch((error) => {
      return;
    });
};
export const vanSalesReturn = (values) => async (dispatch) => {
  axiosInstance
		.post(`${inventory}van/sales-return`, values)
		.then((response) => {
			return dispatch({
				type: OFF_LOAD_VAN_INVENTORY,
				loadVan: response?.data?.affected_products_in_vehicle,
			});
		})
		.catch((error) => {
			return;
		});
};

export const getReplenishmentRecords = (vehicleId,date,channel) => async (dispatch) => {
  axiosInstance
    .get(
      `${inventory}van/replenishment-records/${vehicleId}/?date=${date}&channel=${channel}`,
    )
    .then((response) => {
      if(channel === "driver-warehouse"){
        return dispatch({
          type: DRIVER_TO_WAREHOUSE,
          driverToWarehouse: response.data.data,
        });
      }else{
        return dispatch({
          type: WAREHOUSE_TO_DRIVER,
          warehouseToDriver: response.data.data,
        });
      }
      
    })
    .catch((error) => {
      return;
    });
};

export const assignToVan = (values) => async (dispatch) => {
  let walkInStock = [];
  let otherStock = [];
  const companyCode = values.companyCode;
  const vehicleId = values.vehicleId;
  values.stocks.forEach((item) => {
    item.route === "Walk-In-Sales"
      ? walkInStock.push(item)
      : otherStock.push(item);
  });
  if (walkInStock.length > 0) {
    axiosInstance
      .post(
        `${inventory}van/assign-order?is_walk_in=true`,
        {
          companyCode,
          vehicleId,
          stocks: walkInStock,
        }
      )
      .then((response) => {
        return dispatch({
          type: ASSIGN_TO_VAN,
          assignToVan: response.data,
        });
      });
  }
  if (otherStock.length > 0) {
    axiosInstance
      .post(`${inventory}van/assign-order`, {
        companyCode,
        vehicleId,
        stocks: otherStock,
      })
      .then((response) => {
        return dispatch({
          type: ASSIGN_TO_VAN,
          assignToVan: response.data,
        });
      });
  }

};

export const getDriverDetails = (vehicleID) => async (dispatch) => {
  axiosInstance
    .get(`${vehicleNet}GetVehicle/GetByVehicleId/${vehicleID}`)
    .then((response) => {
      return dispatch({
        type: DRIVER_DETAILS,
        driverDetails: response.data,
      });
    })
    .catch(() => {
      return;
    });
};

export const setDriverId = (vehicleId) => async (dispatch) => {
  return dispatch({
    type: SET_DRIVER_ID,
    driverId: vehicleId,
  });
}

export const setVanInventoryToDefault = () => async (dispatch) => {
  return dispatch({
    type: GET_SINGLE_VAN_INVENTORY,
    all_single_inventory: []
  });
}
