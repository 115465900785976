import {
  ADD_ADMIN_ACTION,
  CHANGEDIST_ACTION,
  CHANGEROLE_ACTION,
  EDIT_ACTION,
  FILTERED_DISTRIBUTORS,
  GET_NEW_USERS_FAILURE,
  GET_NEW_USERS_START,
  GET_NEW_USERS_SUCCESS,
  LOADING_STATE,
  NEW_USER_DETAILS,
  SAVE_DISTRIBUTORS,
  SELECTED_DISTRIBUTORS,
  SUSPEND_ACTION,
  UNSUSPEND_ACTION,
  UPDATE_USER,
  UPDATE_USER_DATA,
  VIEW_ALL_DISTRIBUTORS,
  VIEW_ALL_USERS,
} from "../types/UserTypes";

import axiosInstance from "../../../../utils/axios";
import urls from "../../../../utils/urls";

const { userNet, distributorNet, vehicleNet } = urls;

export const getAllUsers = (country) => async (dispatch) => {
  axiosInstance
    .get(`${userNet}fetchuser/getbycountry/${country}`)
    .then((response) => {
      const { data } = response.data;
      dispatch({
        type: VIEW_ALL_USERS,
        all_users: data,
        assignedUsersLength: data.filter(
          (user) => user.roles && user.roles !== "new"
        )?.length,
      });
    })
    .catch((error) => {
      return;
    });
};

export const getUsersByStatus =
  (country, status, currentPage, pageSize, search, role) =>
  async (dispatch) => {
    const roles = role ? `&roles=${role}` : "";
    try {
      dispatch({ type: GET_NEW_USERS_START });
      const { data } = await axiosInstance.get(
        `${userNet}fetchuser/getbycountry/${country}?status=${status}&page=${currentPage}&limit=${pageSize}&search=${search}${roles}`
      );
      if (data) {
        dispatch({
          type: GET_NEW_USERS_SUCCESS,
          newUsers: data.data,
          newUsersCount: data.meta.totalCount,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_NEW_USERS_FAILURE,
        newUsersFailure: error.response.data.msg,
      });
      return;
    }
  };

export const updateUser =
  (id, params, newUsersCopy, country) => async (dispatch) => {
    dispatch({
      type: LOADING_STATE,
      loading: true,
    });
    axiosInstance
      .post(`${userNet}edit-profile/details/${id}`, params)
      .then(() => {
        dispatch(getAllUsers(country));
        dispatch({
          type: LOADING_STATE,
          loading: false,
        });
        dispatch(setEditKPOOverlay(false));
      })
      .catch((error) => {
        return;
      });
  };

export const updateUserData = (userData) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_DATA,
    userData: userData,
  });
};

export const updateVehicle =
  (id, params, vehicleDetails, vehicleId, newUsersCopy, country) =>
  (dispatch) => {
    axiosInstance
      .post(`${userNet}edit-profile/details/${id}`, params)
      .then(async () => {
        axiosInstance.patch(
          `${vehicleNet}EditVehicle/${vehicleId}`,
          vehicleDetails
        );
      })
      .finally(() => {
        dispatch(getAllUsers(country));
        dispatch({
          type: UPDATE_USER,
          allUsers: newUsersCopy,
        });
      });
  };

export const setEditKPOOverlay = (action, kpo_id) => (dispatch) => {
  dispatch({
    type: EDIT_ACTION,
    edit_action: action,
    kpo_id: kpo_id,
  });
};

export const setSuspendKPOOverlay =
  (action, kpo_id, userPhoneNumber) => (dispatch) => {
    dispatch({
      type: SUSPEND_ACTION,
      suspend_action: action,
      kpo_id: kpo_id,
      userPhoneNumber: userPhoneNumber,
    });
  };

export const setUnSuspendKPOOverlay =
  (action, kpo_id, userPhoneNumber) => (dispatch) => {
    dispatch({
      type: UNSUSPEND_ACTION,
      unsuspend_action: action,
      kpo_id: kpo_id,
      userPhoneNumber: userPhoneNumber,
    });
  };

export const setChangeRoleOverlay = (action, kpo_id) => (dispatch) => {
  dispatch({
    type: CHANGEROLE_ACTION,
    changerole_action: action,
    kpo_id: kpo_id,
  });
};

export const setChangeDistOverlay = (action, customer_id) => (dispatch) => {
  dispatch({
    type: CHANGEDIST_ACTION,
    changedist_action: action,
    customer_id: customer_id,
  });
};

export const setAddAdminOverlay = (action, kpo_id) => (dispatch) => {
  dispatch({
    type: ADD_ADMIN_ACTION,
    add_admin_action: action,
    kpo_id: kpo_id,
  });
};

export const getAllDistributors = (country) => async (dispatch) => {
  axiosInstance
    .get(`${distributorNet}company/companies/${country}`)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: VIEW_ALL_DISTRIBUTORS,
        all_distributors: data.result,
      });
    })
    .catch((error) => {
      return;
    });
};

export const setFilteredDistributors = (data) => (dispatch) => {
  dispatch({
    type: FILTERED_DISTRIBUTORS,
    filtered_distributors: data,
  });
};

export const setSelectedDistributors = (data) => (dispatch) => {
  dispatch({
    type: SELECTED_DISTRIBUTORS,
    selected_distributors: data,
  });
};

export const saveDist = (kpo_id, params, selectedDist) => (dispatch) => {
  axiosInstance
    .patch(`${userNet}edit-profile/${kpo_id}`, params)
    .then((result) => {
      dispatch({
        type: SAVE_DISTRIBUTORS,
        new_distributors: selectedDist,
      });
    })
    .catch((error) => {
      return;
    });
};

export const setDetailsForAssignRole = (data) => (dispatch) => {
  dispatch({
    type: NEW_USER_DETAILS,
    user_details: data,
  });
};
