import { useEffect, useState } from "react";
import { Checked } from "../../../assets/svg/modalIcons";
import Modal from "../../../components/common/Modal";
import {
  getDistricts,
  getRegions,
  setDefaultDistrict,
} from "../../../utils/getDistrictsRegions";

const DistributorModal = ({
  showModal,
  handleClose,
  handleSubmit,
  distributor,
  setDistributor,
  t,
  modalTitle,
  country,
  disabled,
  isLoading,
  modalType,
  approvalModal,
  setApprovalModal,
  error,
  handleApprovalClose,
  warningModal,
  setWarningModal,
}) => {
  const defaultDistrict = setDefaultDistrict(country);
  const districts = getDistricts(country);
  const [regions, setRegions] = useState(getRegions(country, defaultDistrict));

  if (!distributor.region) {
    distributor = { ...distributor, region: regions[0] };
  }
  if (!distributor.district) {
    distributor = { ...distributor, district: districts[0] };
  }

  const handleEInput = (evt) =>
    ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();

  useEffect(() => {
    const regions = getRegions(country, distributor.district);
    if (regions) {
      setRegions(regions);
      setDistributor((distributor) => ({ ...distributor, region: regions[0] }));
    }
  }, [distributor.district, country, setDistributor]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDistributor({ ...distributor, [name]: value });
  };

  const disabledInput = modalType === "edit";

  return (
    <>
      <Modal
        show={showModal}
        handleClose={() => setWarningModal(true)}
        modalWidth="w-[95%] md:w-[800px]"
        children={
          <div className="text-left bg-white">
            <p className="px-6 mb-8 md:px-12 text-larger-text">{modalTitle}</p>
            <div className="w-full px-6 overflow-y-scroll h-modal scrollbar-hide md:px-12">
              <div className="mb-6">
                <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-3">
                  {t("business_name_required")}
                </label>
                <input
                  required
                  onChange={handleChange}
                  value={distributor.company_name}
                  name="company_name"
                  type="text"
                  placeholder="Type here"
                  className="block w-full px-4 py-3 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                />
              </div>
              <div className="mb-6">
                <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-70">
                  {t("business_owner_name_required")}
                </label>
                <input
                  required
                  onChange={handleChange}
                  value={distributor.Owner_Name}
                  name="Owner_Name"
                  type="text"
                  placeholder={t("type_here")}
                  className="w-full py-3 pl-4 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                />
              </div>
              <div className="mb-6">
                <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-70">
                  {t("business_owner_phone_required")}
                </label>
                <input
                  required
                  onChange={handleChange}
                  onKeyDown={handleEInput}
                  value={distributor.Owner_Phone}
                  name="Owner_Phone"
                  type="number"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  placeholder={t("type_here")}
                  className="w-full py-3 pl-4 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                />
              </div>
              <div className="flex form-control-disp">
                <div>
                  <label className="block text-base not-italic font-medium font-customGilroy text-grey-70">
                    {t("email_address_required")}
                  </label>
                  <input
                    required
                    onChange={handleChange}
                    value={distributor.email}
                    name="email"
                    type="email"
                    placeholder={t("type_here")}
                    className="w-full py-3 pl-4 mb-6 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                  />
                </div>
                <div>
                  <label className="block text-base not-italic font-medium font-customGilroy text-grey-70">
                    {t("address")}({t("required")})*
                  </label>
                  <input
                    required
                    onChange={handleChange}
                    value={distributor.address}
                    name="address"
                    type="text"
                    placeholder={t("type_here")}
                    disabled={disabledInput}
                    className="w-full py-3 pl-4 mb-6 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                  />
                </div>
              </div>
              <div className="flex form-control-disp">
                <div className="mb-6">
                  <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-70">
                    {t("country")}({t("required")})*
                  </label>
                  <div className="py-3 pl-4 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy">
                    {distributor.country || country}
                  </div>
                </div>
                <div className="mb-6">
                  <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-70">
                    {t("state/city")}({t("required")})*
                  </label>
                  <input
                    required
                    onChange={handleChange}
                    value={distributor.state}
                    name="state"
                    type="text"
                    placeholder={t("type_here")}
                    disabled={disabledInput}
                    className="w-full py-3 pl-4 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                  />
                </div>
              </div>
              <div className="flex form-control-disp">
                <div className="mb-6">
                  <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-70">
                    {t("district_required")}
                  </label>
                  <select
                    required
                    onChange={handleChange}
                    value={distributor.district}
                    name="district"
                    type="text"
                    placeholder={t("type_here")}
                    className="w-full py-3 pl-4 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                  >
                    {districts?.map((item, key) => (
                      <option key={key}>{item}</option>
                    ))}
                  </select>
                </div>
                <div className="mb-6">
                  <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-70">
                    {t("region_required")}
                  </label>
                  <select
                    required
                    onChange={handleChange}
                    value={distributor.region}
                    name="region"
                    type="text"
                    placeholder={t("type_here")}
                    className="w-full py-3 pl-4 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                  >
                    {regions?.map((item, key) => (
                      <option key={key}>{item}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex form-control-disp">
                <div className="mb-6">
                  <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-70">
                    {country === "South Africa"
                      ? "Stp Code"
                      : t("salesforce_code")}
                  </label>
                  <input
                    onChange={handleChange}
                    value={distributor.SF_Code}
                    name="SF_Code"
                    type="text"
                    placeholder={t("type_here")}
                    disabled={disabledInput}
                    className="w-full py-3 pl-4 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                  />
                </div>
                <div className="mb-6">
                  <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-70">
                    {t("syspro_code")}
                  </label>
                  <input
                    required
                    onChange={handleChange}
                    value={distributor.SYS_Code}
                    name="SYS_Code"
                    type="text"
                    placeholder={t("type_here")}
                    disabled={disabledInput}
                    className="w-full py-3 pl-4 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                  />
                </div>
              </div>
              <div className="flex form-control-disp">
                <div className="mb-6">
                  <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-70">
                    Longitude({t("required")})*
                  </label>
                  <input
                    required
                    onChange={handleChange}
                    value={distributor.long}
                    name="long"
                    type="number"
                    step="0.000001"
                    placeholder={t("type_here")}
                    className="w-full py-3 pl-4 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                  />
                </div>
                <div className="mb-6">
                  <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-70">
                    Latitude({t("required")})*
                  </label>
                  <input
                    required
                    onChange={handleChange}
                    value={distributor.lat}
                    name="lat"
                    type="number"
                    step="0.000001"
                    placeholder={t("type_here")}
                    className="w-full py-3 pl-4 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                  />
                </div>
              </div>
              <p className="mb-5 text-sm not-italic font-semibold font-customGilroy text-red-main">
                {country === "Tanzania"
                  ? "Distributor Specialist"
                  : t("distributor_developer")}
              </p>
              <div className="flex justify-between">
                <div className="w-full mb-6">
                  <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-70">
                    {t("name")}({t("required")})*
                  </label>
                  <input
                    onChange={handleChange}
                    value={distributor.DD_Name}
                    name="DD_Name"
                    type="text"
                    placeholder={t("type_here")}
                    disabled={disabledInput}
                    className="w-full py-3 pl-4 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                  />
                </div>
              </div>
              <div className="flex form-control-disp">
                <div className="mb-6">
                  <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-70">
                    {t("email_address")}({t("required")})*
                  </label>
                  <input
                    onChange={handleChange}
                    value={distributor.DD_Email}
                    name="DD_Email"
                    type="email"
                    placeholder={t("type_here")}
                    disabled={disabledInput}
                    className="w-full py-3 pl-4 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                  />
                </div>
                <div className="mb-6">
                  <label className="block mb-2 text-base not-italic font-medium font-customGilroy text-grey-70">
                    {t("phone_number")}({t("required")})*
                  </label>
                  <input
                    onChange={handleChange}
                    onKeyDown={handleEInput}
                    value={distributor.DD_Phone}
                    name="DD_Phone"
                    type="number"
                    placeholder={t("type_here")}
                    className="w-full py-3 pl-4 text-base not-italic font-medium bg-white border border-solid rounded border-grey-25 font-customGilroy"
                  />
                </div>
              </div>
            </div>
          </div>
        }
        modalFooterMargin="mt-0"
        modalFooter={
          <div className="flex flex-row-reverse lg:mr-2">
            <button
              type="button"
              className={`bg-gold font-semibold text-sm rounded ml-4 w-36 ${
                !disabled ? "opacity-100" : "opacity-30"
              }`}
              onClick={handleSubmit}
              disabled={disabled}
            >
              {isLoading ? t("saving") : t("save")}
            </button>
            <button
              type="button"
              className="revoke-button w-36 text-dark"
              onClick={() => setWarningModal(true)}
            >
              {t("Cancel")}
            </button>
          </div>
        }
      />

      {/* ApprovalModal */}
      <Modal
        show={approvalModal}
        handleClose={handleApprovalClose}
        modalWidth="w-[95%] md:w-[800px]"
        children={
          <div className="flex flex-col items-center justify-center h-small-modal">
            {!error ? (
              <>
                <Checked className="text-center" />
                <p className="mt-4 text-xl not-italic font-medium text-center font-customGilroy text-grey-85">
                  {modalType === "edit"
                    ? t("distributor_details_updated")
                    : t("new_distributor_created")}
                  !
                </p>
              </>
            ) : (
              <>
                <p className="text-xl">❌</p>
                <p className="mt-4 text-xl not-italic font-medium text-center font-customGilroy text-grey-85">
                  {t("an_error_occured")}!
                </p>
                <p className="mt-3">{error}</p>
              </>
            )}
          </div>
        }
        modalFooterMargin="mt-0"
        modalFooter={
          <button
            className={`bg-gold outline-none rounded text-center text-sm font-bold px-14 py-2`}
            onClick={handleApprovalClose}
          >
            {t("okay")}
          </button>
        }
      />

      {/* warningModal */}
      <Modal
        show={warningModal}
        handleClose={() => setWarningModal(false)}
        modalWidth="w-[95%] md:w-[800px]"
        children={
          <div className="flex flex-col items-center justify-center h-mini-modal">
            <p className="px-4 text-base not-italic font-medium font-customGilroy">
              {t("exit_distributor_creation")}
            </p>
          </div>
        }
        modalFooterMargin="mt-0"
        modalFooter={
          <>
            <button
              className="py-2 ml-3 text-sm not-italic font-bold text-center bg-white border border-solid rounded font-customGilroy text-grey-70 px-7"
              onClick={() => setWarningModal(false)}
            >
              {t("cancel")}
            </button>
            <button
              className="py-2 text-sm not-italic font-bold text-center rounded bg-gold font-customGilroy px-14"
              onClick={handleClose}
            >
              {t("yes_exit")}
            </button>
          </>
        }
      />
    </>
  );
};

export default DistributorModal;
