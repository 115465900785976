import React from "react";
import userManager from "../../../utils/userManager";
import { useTranslation } from "react-i18next";
import AuthLayout from ".";


const Auth = ({ title, label, buttonText }) => {
  const { t } = useTranslation();
  const onLoginButtonClick = async (e) => {
    e.preventDefault();
    userManager.signinRedirect();
  };

  return (
		<AuthLayout 
			isWelcome={true}
			main={
				<div className="w-full px-3 pb-14 md:mb-0 lg:mt-36 flex flex-col justify-center items-center flex-none welcome-div-text">
					<p
						className="text-black-400 font-normal font-customGilroy text-active pt-3 pb-2 text-center text-5xl"
					>
						{title}
					</p>
					<div className="w-full">
						<form className="w-full">
							<div className="pt-4 pb-8 mb-4 flex flex-col items-center w-full">
								<p
									className="text-lg pb-3 font-normal font-customGilroy text-grey-3"
								>
									{t("welcome_btn_text")}
								</p>
								<div className="text-center px-3 w-full flex justify-center">
									<button
										className="bg-gold w-full md:w-96 text-grey-100 lg:w-96 my-5 py-3 focus:outline-none rounded text-center text-base font-bold font-customRoboto sm:w-96"
										type="button"
										onClick={(e) => onLoginButtonClick(e)}
									>
										Continue
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			}
		/>
	);
};

export default Auth;
