import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { CloseModal, Checked } from "../../assets/svg/modalIcons";
import Loading from "./Loading";
import {
  discardChanges,
  getAllInventory,
  receiveEmpties,
  updateEmptiesQuantity,
  returnQuantity,
} from "../../modules/Inventory/actions/inventoryProductAction";
import { updateCustomer } from "../../modules/Admin/customer/actions/customer";
import { useHistory } from "react-router-dom";
import urls from "../../utils/urls";
import axiosInstance from "../../utils/axios";
import { useTranslation } from "react-i18next";

const { inventoryNet } = urls;

const UnsavedTransferChanges = ({ Dist_Code }) => {
  const { t } = useTranslation();

  const returnEmpties = useSelector(
    (state) => state.InventoryReducer.return_quantities
  );

  const openReceiveEmpties = useSelector(
    (state) => state.InventoryReducer.receive_empties_quantities
  );
  const customer = useSelector((state) => state.CustomerReducer.customer);
  let dist_code = useSelector((state) => state.DistReducer.dist_code);
  if (!dist_code) {
    dist_code = Dist_Code;
  }

  const [approvalModal, setApprovalModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [save, setSave] = useState(
    openReceiveEmpties.length > 0 ? t("yes_receive") : t("yes_return")
  );
  const [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  let AuthData = useSelector((state) => state.Auth.sessionUserData);
  const roles = AuthData.roles;


  const handleReset = () => {
    setApprovalModal(false);
  };
  const updateDB = () => {
    let processedArray = returnEmpties?.map((item) => ({
      productId: parseInt(item.productId),
      quantity: parseInt(item.quantity),
    }));

    let toDB = {
      companyCode: dist_code,
    };
    setSave(t("updating"));
    setLoading(true);
    if (openReceiveEmpties.length > 0) {
      toDB["customerName"] = customer.customerName;
      toDB["phoneNumber"] = customer.phoneNumber;
      toDB["stocks"] = openReceiveEmpties;
      axiosInstance
        .post(`${inventoryNet}empties/take-in`, toDB)
        .then((response) => {
          setApprovalModal(false);
          setSuccessModal(true);
          setSave(t("yes_recieve"));
          setLoading(false);
          dispatch(updateEmptiesQuantity(false));
          dispatch(receiveEmpties([]));
          dispatch(getAllInventory(dist_code));
          dispatch(updateCustomer({}));
          setTimeout(() => {
            history.push("/dashboard/inventory/" + dist_code);
          }, 1000);
        })
        .catch((error) => {
          setErrorModal(true);
          setLoading(false);
          setSave(t("yes_receive"));
          setTimeout(() => {
            setApprovalModal(false);
            setErrorModal(false);
          }, 4000);
        });
    } else if (returnEmpties) {
      toDB["stocks"] = processedArray;

      axiosInstance
        .post(`${inventoryNet}empties/take-out`, toDB)
        .then((response) => {
          setApprovalModal(false);
          setSuccessModal(true);
          setSave(t("yes_return"));
          setLoading(false);
          dispatch(updateEmptiesQuantity(false));
          dispatch(returnQuantity([]));
          dispatch(getAllInventory(dist_code));
          setTimeout(() => {
            history.push("/dashboard/inventory/" + dist_code);
          }, 1000);
        });
    } else {
      toDB["stocks"] = processedArray;

      axiosInstance
        .post(`${inventoryNet}return-empties/`, toDB)
        .then((response) => {
          setApprovalModal(false);
          setSuccessModal(true);
          setSave(t("yes_return"));
          setLoading(false);
          dispatch(updateEmptiesQuantity(false));
          dispatch(getAllInventory(dist_code));
          setTimeout(() => {
            history.push("/dashboard/inventory/" + dist_code);
          }, 2000);
        });
    }

  };

  return (
    <>
      <Fade>
        <div className="bg-gold flex flex-col sm:flex-row sm:items-center shadow-[0px_4px_16px_rgba(89, 85, 84, 0.15)] sm:justify-between shadow-xl lg:h-[72px] py-3 px-4 lg:pr-8 w-full lg:gap-5">
          <div className="w-full flex items-end flex-col md:flex-row md:items-center md:justify-between pl-4">
            <div className="font-customGilroy lg:w-8/12 italic font-semibold text-gray-100 text-xl py-3">
              {t("you_have_unsaved_changes_Do_you_want_to_save_your_changes?")}
            </div>
            <div className="inline-flex justify-between flex-wrap sm:flex-nowrap gap-4">
              <div
                className="border border-white rounded p-3 cursor-pointer text-center text-white font-semibold bg-transparent"
                onClick={() => {
                  dispatch(discardChanges());
                  window.location.reload();
                }}
                onKeyDown={() => {
                  dispatch(discardChanges());
                  window.location.reload();
                }}
                role="button"

              >
                {t("discard_changes")}
              </div>
              {roles === "Mini-Admin" ? (
                <div className="rounded p-3 bg-[#BEBEBE] text-black w-[5.5rem] text-base text-center font-semibold cursor-pointer">
                  Save
                </div>
              ) : (
                <div
                  className="bg-dark rounded p-3 w-[5.5rem] text-gold text-base text-center font-semibold cursor-pointer"
                    onClick={() => setApprovalModal(true)}
                    onKeyDown={() => setApprovalModal(true)}
                    role="button"

                >
                  {t("save")}
                </div>
              )}
            </div>
          </div>
        </div>
      </Fade>

      <Transition.Root show={approvalModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setApprovalModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-mini-modal">
                <CloseModal
                  className="ml-auto m-4 mb-0"
                  onClick={handleReset}
                />

                {errorModal ? (
                  <div className="h-mini-modal flex flex-col justify-center items-center">
                    <p className="text-xl">❌</p>
                    <p className="font-customGilroy not-italic text-lg font-medium px-4">
                      Ooops, something went wrong!!!
                    </p>
                    <p className="font-customGilroy not-italic text-base font-medium px-4">
                      Please check and try again.
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="h-mini-modal flex justify-center items-center">
                      <p className="font-customGilroy not-italic text-base font-medium px-4">
                        {save === t("yes_receive")
                          ? t(
                              "are_you_sure_you_want_to_receive_your_selection(s)?"
                            )
                          : t(
                              "are_you_sure_you_want_to_return_your_selection(s)?"
                            )}
                      </p>
                    </div>
                    <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                      <button
                        className="bg-gold rounded flex font-customGilroy text-gray-100 text-center text-sm font-bold not-italic px-14 py-2"
                        onClick={() => updateDB()}
                      >
                        {save}
                        {loading ? <Loading /> : ""}
                      </button>

                      <button
                        className="bg-white border border-solid rounded font-customGilroy text-grey-70 text-center text-sm font-bold not-italic px-7 py-2"
                        onClick={handleReset}
                      >
                        {t("cancel")}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={successModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setSuccessModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                <button
                  className="bg-gold text-gray-100 flex justify-center ml-auto m-4 mb-0 "
                  onClick={() => setSuccessModal(false)}
                >
                  <CloseModal />
                </button>
                <div className="flex flex-col justify-center items-center w-modal h-sub-modal -mt-12">
                  <Checked />
                  <p className="font-customGilroy not-italic font-medium text-xl text-center text-grey-85 mt-4">
                    {t("your_inventory_has_been_updated")}
                  </p>
                </div>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className="bg-gold rounded font-customGilroy text-gray-100 text-center text-sm font-bold not-italic px-14 py-2"
                    onClick={() => setSuccessModal(false)}
                  >
                    {t("okay")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default UnsavedTransferChanges;
