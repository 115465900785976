import {
  GET_SINGLE_VAN_INVENTORY,
  UPDATED_QUANTITY,
  UNSAVED_VAN_CHANGES,
  LOAD_VAN_INVENTORY,
  OFF_LOAD_VAN_INVENTORY,
  DISCARD_CHANGES,
  DRIVER_DETAILS,
  DRIVER_TO_WAREHOUSE,
  WAREHOUSE_TO_DRIVER,
  SET_DRIVER_ID
} from "../actions/types";

const initial_state = {
  all_single_van_inventory: [],
  load_van: {},
  transfer_quantities: [],
  driverId: null,
  distCode: null,
  driverDetails: {},
  transferVanChange: false,
  driverToWarehouse: [],
  warehouseToDriver: [],
  reverted_inventory: null
};

const VanReducer = (state = initial_state, action) => {
  switch (action.type) {
    case DRIVER_TO_WAREHOUSE:
      return {
        ...state,
        driverToWarehouse: action.driverToWarehouse
      }
    case WAREHOUSE_TO_DRIVER:
      return {
        ...state,
        warehouseToDriver: action.warehouseToDriver
      }
    case LOAD_VAN_INVENTORY:
      return {
        ...state,
        all_single_van_inventory: [
          ...state.all_single_van_inventory,
          action.loadVan,
        ],
      };
    case OFF_LOAD_VAN_INVENTORY:
      return {
				...state,
				reverted_inventory: action.reverted_inventory
			};
    case UPDATED_QUANTITY: {
      return {
        ...state,
        transfer_quantities: action.transfer_quantities,
        driverId: action.driverId,
        distCode: action.distCode,
      };
    }
    case UNSAVED_VAN_CHANGES: {
      return { ...state, transferVanChange: action.transfer_change };
    }
    case DISCARD_CHANGES: {
      return { ...state, transferChange: false };
    }
    case GET_SINGLE_VAN_INVENTORY: {
      return {
        ...state,
        all_single_van_inventory: action.all_single_inventory,
      };
    }
    case DRIVER_DETAILS: {
      return {
        ...state,
        driverDetails: action.driverDetails.data,
      };
    }
    case SET_DRIVER_ID: {
      return {
        ...state,
        driverId: action.driverId
      };
    }
    default:
      return state;
  }
};

export default VanReducer;
