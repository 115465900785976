export const routes = {
  Homepage: "/",
  CountrySelector: "/admin-dashboard/countries",
  AdminDashboard: "/admin-dashboard",
  AdminNotifications: "/admin-dashboard/notifications",
  AdminReports: "/admin-dashboard/reports",
  AdminTotalSalesReport: "/admin-dashboard/reports/totalsales",
  AdminTotalDelivery: "/admin-dashboard/reports/delivery",
  AdminTotalVolumeSold: "/admin-dashboard/reports/volumesold",
  AdminTotalVolumeReceived: "/admin-dashboard/reports/volumereceived",
  AdminStockReceived: "/admin-dashboard/reports/stockreceived",
  AdminSingleStockReceived:
    "/admin-dashboard/reports/singlestockreceived/:Dist_Code/:docNo",
  AdminTotalWalkInSales: "/admin-dashboard/reports/walk-in-sales",
  AdminTotalVanSales: "/admin-dashboard/reports/van-sales",
  AdminDailyStockCount: "/admin-dashboard/reports/daily-stock-count",
  AdminAllCustomers: "/admin-dashboard/customers",
  Users: "/admin-dashboard/users",
  Products: "/admin-dashboard/products",
  AddAbiProduct: "/admin-dashboard/products/add-abi-product",
  AddMpProduct: "/admin-dashboard/products/add-marketplace-product",
  EditProduct: "/admin-dashboard/products/edit-product/:productID",
  EditDraftProduct: "/admin-dashboard/products/edit-draft-product/:productID",
  ManageUser: "/admin-dashboard/manage-user/:userType/:id",
  AdminDistDetails: "/admin-dashboard/distributor/:code",
  AdminPricing: "/admin-dashboard/pricings",
  AdminClosingStockReport: "/admin-dashboard/reports/closing-stock",
  AdminStockLevel: "/admin-dashboard/reports/stock-level",
  DistributorCustomers: "/admin-dashboard/distributor/:distCode/customers",
  DistributorUsers: "/admin-dashboard/distributor/:distCode/users",
  DistributorOrders: "/admin-dashboard/distributor/:distCode/orders",
  DistributorTotalSales: "/admin-dashboard/dist/:Dist_Code/reports/totalsales",
  DistributorStockReceived:
    "/admin-dashboard/dist/:Dist_Code/reports/stockreceived",
  DistributorStockLevel: "/admin-dashboard/dist/:Dist_Code/reports/stock-level",
  DistributorClosingStock:
    "/admin-dashboard/dist/:Dist_Code/reports/closing-stock",
  DistributorVolumeSold: "/admin-dashboard/dist/:Dist_Code/reports/volumesold",
  DistributorVolumeReceived:
    "/admin-dashboard/dist/:Dist_Code/reports/volumereceived",
  DistributorTotalDelivery:
    "/admin-dashboard/dist:Dist_Code/reports/sales-by-delivery",
  DistributorWalkInSales:
    "/admin-dashboard/dis:Dist_Code/reports/walk-in-sales",
  DistributorVanSales: "/admin-dashboard/dist/:Dist_Code/reports/van-sales",
  DistributorReports: "/admin-dashboard/distributor/:Dist_Code/reports",
  DistributorOrderSummary:
    "/admin-dashboard/dist/:distCode/order-summary/:orderId/:buyerCode",
  CustomerOrderSummary:
    "/admin-dashboard/dist/:sysproCode/customer/:buyerCode/order-summary/:orderId/:distCode",
  OrderSummary: "/admin-dashboard/dist/order-summary/:orderId/:buyerCode",
  ManageCustomer:
    "/admin-dashboard/dist/:sysproCode/manage-customer/:id/:distCode",
  PageNotFound: "*",
};
