export const financeHeaders = [
  {
    tabLabel: "business_to_customer",
    tabIndex: 1,
  },
  {
    tabLabel: "business_to_abi",
    tabIndex: 2,
  },
  {
    tabLabel: "invoicing",
    tabIndex: 3,
  },
  {
    tabLabel: "reminders",
    tabIndex: 4,
  },
];

export const financeAccountReceivableHeaders = [
  {
    tabLabel: "outstanding_invoices",
    tabIndex: 1,
  },
  {
    tabLabel: "payment_history",
    tabIndex: 2,
  },
];

export const notificationTabHeaderData = [
  {
    tabLabel: "All Notifications",
    tabIndex: 1,
    link: "#link1",
    img: "",
  },
  {
    tabLabel: "New Order Alerts",
    tabIndex: 2,
    link: "#link2",
    img: "",
  },
  {
    tabLabel: "Low-volume Alerts",
    tabIndex: 3,
    link: "#link3",
    img: "",
  },
  {
    tabLabel: "Out-of-Stock Alerts",
    tabIndex: 4,
    link: "#link4",
    img: "",
  },
];

export const notificationTabHeaderDataNigeria = [
  {
    tabLabel: "All Notifications",
    tabIndex: 1,
    link: "#link1",
    img: "",
  },
  {
    tabLabel: "New Order Alerts",
    tabIndex: 2,
    link: "#link2",
    img: "",
  },
  {
    tabLabel: "Low-volume Alerts",
    tabIndex: 3,
    link: "#link3",
    img: "",
  },
  {
    tabLabel: "Out-of-Stock Alerts",
    tabIndex: 4,
    link: "#link4",
    img: "",
  },
  {
    tabLabel: "New Delivery Alert (Logistics)",
    tabIndex: 5,
    link: "#link5",
    img: "",
  },
];

export const adminNotificationTabHeaderData = [
  {
    tabLabel: "All Notifications",
    tabIndex: 1,
    link: "#link1",
    img: "",
  },
];

export const replenishmentTabHeaderData = [
  {
    tabLabel: "Warehouse to driver",
    tabIndex: 1,
    link: "#link1",
    img: "",
  },
  {
    tabLabel: "Driver to warehouse",
    tabIndex: 2,
    link: "#link2",
    img: "",
  },
];

export const replenishmentTabHeaderContent = [
  {
    tabIndex: 1,
    id: "link1",
  },
  {
    tabIndex: 2,
    id: "link2",
  },
];

export const dashboardTabHeaderData = [
  {
    tabLabel: "Dashboard",
    tabIndex: 1,
    link: "#link1",
    img: "",
  },
  {
    tabLabel: "Report",
    tabIndex: 2,
    link: "#link2",
    img: "",
  },
];

export const dashboardTableContent = [
  {
    tabIndex: 1,
    id: "link1",
  },
  {
    tabIndex: 2,
    id: "link2",
  },
];

export const notificationSubTabHeaderData = [
  {
    tabLabel: "All Alerts",
    tabIndex: 1,
    link: "#link1",
  },
  //   {
  //     tabLabel: "Unread",
  //     tabIndex: 2,
  //     link: "#link2",
  //   },
  //   {
  //     tabLabel: "Read",
  //     tabIndex: 3,
  //     link: "#link3",
  //   },
];

export const adminNotificationSubTabHeaderData = [
  {
    tabLabel: "All Alerts",
    tabIndex: 1,
    link: "#link1",
  },
  // {
  // 	tabLabel: 'Awaiting action',
  // 	tabIndex: 2,
  // 	link: '#link2'
  // },
  // {
  // 	tabLabel: 'Unread',
  // 	tabIndex: 3,
  // 	link: '#link3'
  // },
  // {
  // 	tabLabel: 'Read',
  // 	tabIndex: 4,
  // 	link: '#link4'
  // },
  // {
  // 	tabLabel: 'Newest to Oldest',
  // 	tabIndex: 5,
  // 	link: '#link5'
  // }
];

export const notificationTableContent = [
  {
    tabIndex: 1,
    id: "link1",
  },
  {
    tabIndex: 2,
    id: "link2",
  },
  {
    tabIndex: 3,
    id: "link3",
  },
  {
    tabIndex: 4,
    id: "link4",
  },
];

export const notificationTableContentNigeria = [
  {
    tabIndex: 1,
    id: "link1",
  },
  {
    tabIndex: 2,
    id: "link2",
  },
  {
    tabIndex: 3,
    id: "link3",
  },
  {
    tabIndex: 4,
    id: "link4",
  },
  {
    tabIndex: 5,
    id: "link5",
  },
];

export const financeSummary = [
  {
    title: "Total Sales",
    type: "sales",
    reportLink: "/dashboard/finance/:distCode/totalsales",
    hotjarReport: "Total Sales",
  },
  {
    title: "Cash at hand",
    type: "cash at hand",
    reportLink: "/dashboard/finance/:distCode/cash-at-hand",
    hotjarReport: "Cash at hand",
  },
  {
    title: "Accounts Receivable",
    type: "accounts receivable",
    reportLink: "/dashboard/finance/:distCode/accounts-receivable",
    hotjarReport: "Accounts Receivable",
  },
  {
    title: "Current Stock Value",
    type: "current stock value",
    reportLink: "/dashboard/finance/:distCode/current-stock-value",
    hotjarReport: "Current Stock Value",
  },
];

export const infoModalText = {
  sales: {
    title: "total_sales",
    text: "total_sales_info_text",
  },
  "cash at hand": {
    title: "cash_at_hand",
    text: "cash_at_hand_info_text",
  },
  "accounts receivable": {
    title: "accounts_receivable",
    text: "accounts_receivable_info_text",
  },
  "current stock value": {
    title: "stock_value",
    text: "stock_value_info_text",
  },
  "estimated time of arrival": {
    title: "estimated_time_of_arrival",
    text: "eta_info_text",
  },
};

export const SortFilters = [
  "Product Name (A - Z)",
  "Product Name (Z - A)",
  "Quantity (High to Low)",
  "Quantity (Low to High)",
];

export const logisticsStatus = {
  planned: "This_order_has_been_planned_and_will_be_dispatched_soon",
  "in transit":
    "This_order_has_been_dispatched_and_is_on_its_way_to_your_warehouse",
  arrived: "This_order_has_arrived_at_your_warehouse",
};

export const ProductTypes = ["All Products", "ABI Products", "Other Products"];

export const productHeaders = [
  {
    tabLabel: "abi_products",
    tabIndex: 0,
  },
  {
    tabLabel: "marketplace_products",
    tabIndex: 1,
  },
];
export const labelData = {
  general_information: {
    brand: "brand",
    product_id: "product_id",
    product_type: "product_type",
    product_category: "product_category",
    custom_id: "custom_id",
  },
  product_container: {
    is_the_container_returnable: "is_the_container_returnable",
    container_unit_of_measurement: "container_unit_of_measurement",
    container_size: "container_size",
    container_name: "container_name",
    container_material: "container_material",
    pallet_quantity: "pallet_quantity",
  },
  more_product_information: {
    is_this_a_narcotic_product: "is_this_a_narcotic_product",
    is_this_an_alcoholic_product: "is_this_an_alcoholic_product",
    brand_id: "brand_id",
    product_classification: "product_classification",
    product_name: "product_name",
    sub_brand_name: "sub_brand_name",
  },
  pricing: {
    product_price: "product_price",
    empties_price: "empties_price",
    retailer_price: "retailer_price",
    reseller_price: "reseller_price",
    price_to_wholesaler: "price_to_wholesaler",
    price_to_retailer: "price_to_retailer",
    group_a_price: "group_a_price",
    group_b_price: "group_b_price",
    group_c_price: "group_c_price",
    group_d_price: "group_d_price",
    group_e_price: "group_e_price",
    group_f_price: "group_f_price",
    southern_region_price: "southern_region_price",
    central_region_price: "central_region_price",
    northern_region_price: "northern_region_price",
  },
  product_package: {
    package_id: "package_id",
    package_count: "package_count",
    package_name: "package_name",
    vendor_item_id: "vendor_item_id",
    package_item_count: "package_item_count",
    package_pack: "package_pack",
  },
};

export const toolTipData = {
  custom_id: "custom_id_tooltip",
  package_id: "package_id_tooltip",
  package_count: "package_count_tooltip",
  vendor_item: "vendor_item",
  package_item_count: "package_item_count_tooltip",
  package_pack: "package_pack_tooltip",
  brand_id: "brand_id_tooltip",
  product_name: "product_name_tooltip",
  sub_brand_name: "sub_brand_name_tooltip",
};

export const Countries = [
  {
    name: "Ghana",
    flag: "/assets/svg/countries/ghana.svg",
  },
  {
    name: "Mozambique",
    flag: "/assets/svg/countries/mozambique.svg",
  },
  {
    name: "Nigeria",
    flag: "/assets/svg/countries/nigeria.svg",
  },
  {
    name: "South Africa",
    flag: "/assets/svg/countries/southafrica.svg",
  },
  {
    name: "Tanzania",
    flag: "/assets/svg/countries/tanzania.svg",
  },
  {
    name: "Uganda",
    flag: "/assets/svg/countries/uganda.svg",
  },
  {
    name: "Zambia",
    flag: "/assets/svg/countries/zambia.svg",
  },
];
