/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { hotjar } from 'react-hotjar';
import "react-toastify/dist/ReactToastify.css";
import CallBackPage from "./callBack";
import AuthRoute from "./components/common/AuthRoute";
import { ConnectivityListener } from "./components/common/ConnectivityListener";
import AuthEmail from "./modules/Auth/pages/AuthEmail";
import { PrivateRoutes as AdminRoutes } from "./routes/Admin/AdminPrivateRoutes";
import AdminProtectedRoute from "./routes/AdminProtectedRoute";
import { PrivateRoutes as KPORoutes } from "./routes/KPO/KPOPrivateRoutes";
import { PrivateRoutes as MiniAdminRoutes } from "./routes/MiniAdmin/MiniAdminPrivateRoutes";
import ProtectedRoute from "./routes/ProtectedRoute";

import { ToastNotifications } from "./components/common/ToastNotifications";

import { datadogRum } from "@datadog/browser-rum";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import { FallbackProvider } from "./context";
import Blocked from "./modules/Auth/Layout/Blocked";
import UnAssigned from "./modules/Auth/Layout/UnAssigned";
import PageNotFound from "./modules/Auth/pages/PageNotFound";

const { REACT_APP_HOTJAR, REACT_APP_GOOGLE_TRACKING_ID } = process.env;

const App = () => {
  const AuthData = useSelector((state) => state.Auth.sessionUserData);

  const TRACKING_ID = REACT_APP_GOOGLE_TRACKING_ID; // OUR_TRACKING_ID

  ReactGA.initialize(TRACKING_ID);
  datadogRum.setUser({
    id: AuthData?.id,
    name: `${AuthData.firstname} ${AuthData.lastname}`,
    email: AuthData.email,
  });

  useEffect(() => {
    hotjar.initialize({
      id: REACT_APP_HOTJAR,
      sv: 6,
      debug: false
    });
    datadogRum.getUser();
  }, []);

  useEffect(() => {
    hotjar.stateChange(window.location.pathname);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [hotjar.stateChange]);

  return (
    <>
      <ConnectivityListener />
      <ToastContainer />
      <ToastNotifications />
      <BrowserRouter>
        <FallbackProvider>
          <Switch>
            <Route path="/" component={AuthEmail} exact />
            <AuthRoute path="/callback" component={CallBackPage} />

            {AdminRoutes.map((adminRoute) => {
              return (
								<AdminProtectedRoute
									exact={adminRoute.exact || false}
									key={adminRoute.link}
									path={adminRoute.link}
									component={adminRoute.component}
									roles={[adminRoute.role, "Admin"]}
								/>
							);
            })}

            {[...MiniAdminRoutes, ...KPORoutes].map((route, i) => {
              return (
								<ProtectedRoute
									exact={route.exact || false}
									key={route.link + i}
									path={route.link}
									component={route.component}
								/>
							);
            })}

            <Route path="/auth/unassigned" component={UnAssigned} />
            <Route path="/auth/blocked" component={Blocked} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </FallbackProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
