import jwtDecode from "jwt-decode";
import setAuthorization from "../../../utils/authorization";
import axiosInstance from "../../../utils/axios";
import urls from "../../../utils/urls";
import authActionTypes from "./auth.type";

const { userNet } = urls;

const { REACT_APP_BASE_URL } = process.env;

console.log("====================================");
console.log(REACT_APP_BASE_URL);
console.log("====================================");

const loadUserStart = (payload) => {
  return {
    type: authActionTypes.LOAD_USER_START,
    payload,
  };
};

const loadUserFailed = (payload) => {
  return {
    type: authActionTypes.LOAD_USER_FAILURE,
    payload,
  };
};

const loadUserSuccess = (payload) => {
  return {
    type: authActionTypes.LOAD_USER_SUCCESS,
    payload,
  };
};
export const loadUserDataSuccess = (payload) => {
  return {
    type: authActionTypes.LOAD_USER_DATA_SUCCESS,
    payload,
    authenticated: true,
  };
};

export const setDistNavigationTitle = (action) => (dispatch) => {
  dispatch({
    type: authActionTypes.SET_DIST_NAVIGATION_TITLE,
    payload: action,
  });
};

export const setAdminNavigationTitle = (action) => (dispatch) => {
  dispatch({
    type: authActionTypes.SET_ADMIN_NAVIGATION_TITLE,
    payload: action,
  });
};

export const loadUser = () => async (dispatch) => {
  dispatch(loadUserStart());
  const profile = await sessionStorage.getItem(
    "oidc.user:https://dms20prod.b2clogin.com/dms20prod.onmicrosoft.com/B2C_1_dms_signup_signin:572c6a9e-48d3-4382-9452-6e58ca198f92"
  );
  const list = JSON.parse(profile);
  try {
    if (!list.profile.emails[0]) {
      dispatch(loadUserFailed("User not found!!!"));
    } else {
      const res = axiosInstance.get(`${userNet}user/login`, {
        headers: { Authorization: `Bearer ${list.id_token}` },
      });
      let token = res?.data?.result;
      if (token) {
        const currentUser = jwtDecode(token);
        if (currentUser) {
          setAuthorization(token);
          dispatch(loadUserSuccess(currentUser));
        }
      }
    }
  } catch (err) {
    return err;
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("userData");
    await axiosInstance.get(`${userNet}user/logout`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    return err;
  }
};
