import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NavbarRightSection from "../../components/common/NavbarRightSection";
import { CountryDropdown } from "../../components/countryanddistselector/CountryDropdown";
import { isModeratorAdmin } from "../../utils/helperFunction";
import KJGoBack from "../components/KJGoBack";

const Navbar = ({ toggleNavigation, subTitle, pageTitle, back, total }) => {
  const { t } = useTranslation();
  const Auth = useSelector((state) => state.Auth.sessionUserData);
  const { country, moderator, roles } = Auth;

  return (
    <div className="flex items-center w-full h-16 border-b border-gray-21">
      <div className="flex items-center w-full px-4 lg:pl-10 lg:pr-8 lg:justify-between">
        <div className="hamburger lg:hidden">
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleNavigation}
          >
            <MenuIcon color="inherit" className="!text-5xl" />
          </IconButton>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex mt-1">
            <div className="flex">
              {back && <KJGoBack />}
              <h2 className="text-xl font-bold text-black font-customRoboto">
                {t(pageTitle)}{" "}
                {total && (
                  <span className="font-customGilroy text-gray-400 ml-0.5 font-normal">
                    ({total})
                  </span>
                )}
                {subTitle && (
                  <span className="font-customGilroy text-grey-3 ml-0.5 font-normal text-xl leading-6">
                    [{subTitle}]
                  </span>
                )}
              </h2>
            </div>
          </div>
          <div className="flex items-center">
            {isModeratorAdmin(roles, moderator) && (
              <CountryDropdown
                position="left-0 top-[34px]"
                selectedCountry={country}
                Authdata={Auth}
              />
            )}
            <NavbarRightSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
