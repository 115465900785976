import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useCustomNavbarTitle } from "../../../utils/hooks";
import DistributorModal from "../components/DistributorModal";
import AdminDistributorLayout from "../Layout";
import {
  addDistributor,
  getAllDistributor,
} from "./actions/adminDistributorAction";

const Home = ({
  location,
  addDistributor: addDis,
  getAllDistributor,
  allDistributors,
}) => {
  const { t } = useTranslation();
  const AuthData = useSelector((state) => state.Auth.sessionUserData);
  const country = AuthData.country;
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);
  const [errorModal, setErrorModal] = useState("");

  useCustomNavbarTitle(
    "admin",
    "all_distributors",
    false,
    allDistributors.length
  );

  const defaultDistributor = {
    company_name: "",
    country: country,
    email: "",
    type: "Distributor",
    district: "",
    DD_Email: "",
    region: "",
    address: "",
    DD_Name: "",
    DD_Phone: "",
    SF_Code: "",
    Owner_Name: "",
    Owner_Phone: "",
    SYS_Code: "",
    lat: "",
    state: "",
    long: "",
  };

  const [distributor, setDistributor] = useState(defaultDistributor);

  useEffect(() => {
    getAllDistributor(country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    const {
      company_name,
      DD_Name,
      email,
      DD_Email,
      state,
      district,
      address,
      DD_Phone,
      Owner_Name,
      Owner_Phone,
      region,
      country,
      SYS_Code,
      lat,
      long,
    } = distributor;

    if (
      company_name !== "" &&
      DD_Name !== "" &&
      email !== "" &&
      DD_Email !== "" &&
      state !== "" &&
      district !== "" &&
      address !== "" &&
      DD_Phone !== "" &&
      Owner_Name !== "" &&
      Owner_Phone !== "" &&
      region !== "" &&
      country !== "" &&
      SYS_Code !== "" &&
      lat !== "" &&
      long !== ""
    ) {
      setFormCompleted(true);
    } else {
      setFormCompleted(false);
    }
  }, [distributor]);

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    await addDis({
      compName: distributor.company_name,
      country: distributor.country,
      email: distributor.email,
      type: "Distributor",
      district: distributor.district,
      DD_Email: distributor.DD_Email,
      region: distributor.region,
      address: distributor.address,
      DD_Name: distributor.DD_Name,
      DD_Phone: distributor.DD_Phone,
      salesforceCode: distributor.SF_Code,
      Owner_Name: distributor.Owner_Name,
      Owner_Phone: distributor.Owner_Phone,
      sysproCode: distributor.SYS_Code,
      lat: distributor.lat,
      state: distributor.state,
      long: distributor.long,
    })
      .then(() => {
        getAllDistributor(country);
        setDistributor(defaultDistributor);
        setLoader(false);
        setWarningModal(false);
        setOpen(false);
        setApprovalModal(true);
      })
      .catch((error) => {
        setErrorModal(error);
        setApprovalModal(true);
      });
  };

  const sortItems = (value) => {
    const sorted = value.sort((a, b) => b.id - a.id);
    return sorted;
  };

  const clearNewDistributor = () => {
    setOpen(false);
    setWarningModal(false);
    setDistributor(defaultDistributor);
  };

  return (
    <div className="pt-10">
      <div className="flex flex-col justify-between lg:items-center">
        <button
          className="w-64 px-6 py-3 mt-8 text-base rounded bg-gold text-grey-100 font-customGilroy lg:mt-0 max-w-fit lg:self-end"
          onClick={() => setOpen(true)}
        >
          {t("create_new_distributor")}
        </button>
      </div>
      <AdminDistributorLayout
        top="mt-8"
        DistributorList={sortItems(allDistributors)}
      />

      {/* Distributor Modal */}
      <DistributorModal
        modalTitle={t("new_distributor")}
        showModal={open}
        handleClose={clearNewDistributor}
        distributor={distributor}
        setDistributor={setDistributor}
        handleSubmit={onSubmit}
        country={country}
        t={t}
        disabled={!formCompleted}
        isLoading={loader}
        approvalModal={approvalModal}
        setApprovalModal={setApprovalModal}
        handleApprovalClose={() => setApprovalModal(false)}
        error={errorModal}
        warningModal={warningModal}
        setWarningModal={setWarningModal}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allDistributors: state.AllDistributorReducer.all_distributors,
  };
};

export default connect(mapStateToProps, {
  addDistributor,
  getAllDistributor,
})(Home);
