import axios from "axios";
import {
  ADD_DISTRIBUTOR,
  DELETE_DISTRIBUTOR,
  EDIT_DISTRIBUTOR,
  VIEW_ALL_DISTRIBUTOR,
  VIEW_SINGLE_DISTRIBUTOR,
  VIEW_SINGLE_KPO,
  VIEW_MY_DISTRIBUTORS,
  UPDATE_DISTRIBUTOR
} from "./types";
import urls from "../../../../utils/urls";
import axiosInstance from "../../../../utils/axios";
const { distributorNet } = urls;

export const addDistributor = (details) => (dispatch) =>
  axios
    .post(`${process.env.REACT_APP_BASE_URL}/company/register`, details)
    .then((response) => {
      return dispatch({
        type: ADD_DISTRIBUTOR,
        payload: response,
      });
    })
    .catch((error) => Promise.reject(error.response.data.msg));

export const uploadCSV = (details) => {
  let formData = new FormData();
  formData.append("csv", details);
  axios
    .post("/upload-company", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};

export const editDistributor = (details) => (dispatch) => {
  axiosInstance
    .patch(distributorNet, details)
    .then((response) => {
      return dispatch({
        type: EDIT_DISTRIBUTOR,
        payload: response,
      });
    })
    .catch((error) => {
      return;
    });
};

export const deleteDistributor = (details) => (dispatch) => {
  axiosInstance
    .delete(distributorNet, details)
    .then((response) => {
      return dispatch({
        type: DELETE_DISTRIBUTOR,
        payload: response,
      });
    })
    .catch((error) => {
      return;
    });
};

export const getAllDistributor = (country) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`${distributorNet}company/companies/${country}`);
    const { data } = response;
    return dispatch({
      type: VIEW_ALL_DISTRIBUTOR,
      all_distributors: data.result
    });
  } catch (error) {
    return;
  }
};

export const getSingleDistributor = (code) => async (dispatch) => {
  axiosInstance
    .get(`${distributorNet}company/code/${code}`)
    .then((response) => {
      return dispatch({
        type: VIEW_SINGLE_DISTRIBUTOR,
        payload: response.data.result,
      });
    })
    .catch((error) => {
      return;
    });
};

export const getSingleDistributorBySyspro = (code) => async (dispatch) => {
  axiosInstance
    .get(`${distributorNet}company/syspro/${code}`)
    .then((response) => {
      return dispatch({
        type: VIEW_SINGLE_DISTRIBUTOR,
        payload: response.data.result,
      });
    })
    .catch((error) => {
      return;
    });
};

export const updateDistributorByDistCode = (code, data) => async (dispatch) => {
  axiosInstance
    .patch(`${distributorNet}company/update-distributor/${code}`, data)
    .then((response) => {
      return dispatch({
        type: UPDATE_DISTRIBUTOR,
        error: false,
        update: true
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_DISTRIBUTOR,
        error: error.response?.statusText || error.response?.data?.message,
        update: true
      });
    });
};

export const setUpdateDistributorToDefault = () => async (dispatch) => {
  return dispatch({
    type: UPDATE_DISTRIBUTOR,
    error: null,
    update: false
  });
};

export const getSingleKPO = (data) => async (dispatch) => {
  return dispatch({
    type: VIEW_SINGLE_KPO,
    payload: data,
  });
};

export const setMiniAdminDistributors = (data) => async (dispatch) => {
  return dispatch({
    type: VIEW_MY_DISTRIBUTORS,
    payload: data,
  });
};
