import React, { useState } from "react";
import AuthSidebar from "../../../components/common/AuthSidebar";
import { useSelector, connect } from "react-redux";
import userManager from "../../../utils/userManager";

import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UnAssigned = () => {
  const { t } = useTranslation();
  const [click, setClick] = useState(false);
  const AuthData = useSelector((state) => state.Auth.sessionUserData);

  const checkRole = () => {
    userManager.signinRedirect();
    setClick(true);
    const superVid = AuthData?.id;
    const kpoType = AuthData?.DIST_Code ? JSON.parse(AuthData?.DIST_Code) : null;
    const kpoStatus = AuthData?.status;

    switch (AuthData?.roles) {
      case "KPO":
        if (kpoType === null) {
          return <Redirect to="/auth/unassigned" />;
        } else if (kpoType.length > 1 && kpoType.length !== 1) {
          return <Redirect to={`/kpo-supervisor/overview/${superVid}`} />;
        } else {
          const kpoDistCode = JSON.parse(AuthData?.DIST_Code);
          return <Redirect to={`/dashboard/overview/${kpoDistCode}`} />;
        }
      case "Mini-Admin":
        if (kpoType === null) {
          return <Redirect to={"/auth/unassigned"} />;
        } else if (kpoStatus === "Blocked") {
          return <Redirect to={"/auth/blocked"} />;
        } else {
          return <Redirect to={"/min-admin-dashboard"} />;
        }
      case "Supervisor":
       return <Redirect to={`/kpo-supervisor/overview/${superVid}`} />;
      case "Admin":
        if (AuthData?.moderator === 1) {
          return <Redirect to="/admin-dashboard/countries" />;
        } else {
          return <Redirect to="/admin-dashboard" />;
        }
      default:
        return <Redirect to="/auth/unassigned" />;
    }
  };
  return (
    <>
      <div className="h-screen w-screen flex overflow-hidden">
        <div className="hidden md:block h-full w-[30rem]">
          <AuthSidebar />
        </div>
        <div className="h-100 w-[78rem]">
          <div
            className="mt-80 ml-72 px-0 flex flex-col h-full"
          >
            <p
              className="text-black-400 font-normal font-customGilroy pt-3 pb-2 text-[25px] text-active"
            >
              {AuthData?.roles === null && click
                ? t("still_no_role")
                : t("please_wait_while_admin_assigns")}
            </p>
            <button
              className={`w-full bg-${click ? "#D3D3D3" : "text-gold"} md:w-56 lg:w-56 my-5 py-3 text-${click ? "#808080" : "text-grey-100"} focus:outline-none rounded text-center text-base font-bold font-customRoboto`}
              type="button"
              onClick={checkRole}
            >
              {click ? "Loading" : t("continue")}
            </button>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect()(UnAssigned);
