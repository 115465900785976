export const TOTAL_NO_KPO = "TOTAL_NO_KPO";
export const VIEW_ALL_USERS = "VIEW_ALL_USERS";
export const GET_NEW_USERS_START = "GET_NEW_USERS_START";
export const GET_NEW_USERS_SUCCESS = "GET_NEW_USERS_SUCCESS";
export const GET_NEW_USERS_FAILURE = "GET_NEW_USERS_FAILURE";
export const EDIT_ACTION = "EDIT_ACTION";
export const ADD_ADMIN_ACTION = "ADD_ADMIN_ACTION";
export const UPDATE_USER = "UPDATE_USER"
export const VIEW_ALL_DISTRIBUTORS = "VIEW_ALL_DISTRIBUTORS";
export const FILTERED_DISTRIBUTORS = "FILTERED_DISTRIBUTORS";
export const SELECTED_DISTRIBUTORS = "SELECTED_DISTRIBUTORS";
export const SAVE_DISTRIBUTORS = "SAVE_DISTRIBUTORS";
export const NEW_USER_DETAILS = "NEW_USER_DETAILS ";
export const SUSPEND_ACTION = "SUSPEND_ACTION";
export const UNSUSPEND_ACTION = "UNSUSPEND_ACTION";
export const CHANGEROLE_ACTION = "CHANGEROLE_ACTION";
export const CHANGEDIST_ACTION = "CHANGEDIST_ACTION";
export const LOADING_STATE = "LOADING_STATE";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
