import { lazy } from 'react';
import { routes } from './MiniAdminRoutes';
const MiniAdminDashboard = lazy(() =>  import("../../Layout/Mini-Admin/Dashboard"));

const MiniAdminDistroDetails = lazy(() =>  import("../../modules/Mini-Admin/pages/MiniAdminDistroDetails"));
const MiniAdminReports = lazy(() =>  import("../../modules/Mini-Admin/Reports/"));
const MiniAdminTotalSalesReport = lazy(() =>  import("../../modules/Mini-Admin/Reports/TotalSalesReport"));
const MiniAdminTotalDelivery = lazy(() =>  import("../../modules/Mini-Admin/Reports/TotalSalesByDelivery"));
const MiniAdminTotalVolumeSold = lazy(() =>  import("../../modules/Mini-Admin/Reports/TotalSalesByVolumeSold"));
const MiniAdminTotalVolumeReceived = lazy(() =>  import("../../modules/Mini-Admin/Reports/TotalSalesByVolumeReceived"));
const MiniAdminStockReceived = lazy(() =>  import("../../modules/Mini-Admin/Reports/StockReceived"));
const MiniAdminSingleStockReceived = lazy(() =>  import("../../modules/Mini-Admin/Reports/SingleStock"));
const MiniAdminTotalWalkInSales = lazy(() =>  import("../../modules/Mini-Admin/Reports/TotalSalesByWalkin"));
const MiniAdminSummary = lazy(() =>  import("../../modules/Mini-Admin/pages/Summary"));
const MiniAdminTotalVanSales = lazy(() =>  import("../../modules/Mini-Admin/Reports/TotalSalesByVan"));
const MiniAdminDailyStockCount = lazy(() =>  import("../../modules/Mini-Admin/Reports/DailyStockCount"));
const MiniAdminStockLevel = lazy(() =>  import("../../modules/Mini-Admin/Reports/StockLevel"));
const MiniAdminClosingStock = lazy(() =>  import("../../modules/Mini-Admin/Reports/ClosingStock"));
const MiniAdminDistributorStockLevel = lazy(() =>  import("../../modules/Mini-Admin/Reports/Distributors/StockLevel"));
const MiniAdminDistributorClosingStock = lazy(() =>  import("../../modules/Mini-Admin/Reports/Distributors/ClosingStock"));
const MiniAdminCustomers = lazy(() =>  import("../../modules/Mini-Admin/customer/list"));
const DistributorUsers = lazy(() =>  import("../../modules/Admin/user"));
const DistributorOrders = lazy(() =>  import("../../modules/Admin/order/list"));
const DistributorCustomers = lazy(() =>  import("../../modules/Admin/customer/list"));
const DistributorReports = lazy(() =>  import("../../modules/Admin/Reports/Distributors"));
const DistributorOrderSummary = lazy(() =>  import("../../modules/Admin/order/AdminDistOrders"));
const DistributorTotalSales = lazy(() =>  import("../../modules/Admin/Reports/Distributors/TotalSales"));
const DistributorTotalDelivery = lazy(() =>  import("../../modules/Admin/Reports/Distributors/SalesByDelivery"));
const DistributorWalkInSales = lazy(() =>  import("../../modules/Admin/Reports/Distributors/WalkInSales"));
const DistributorVanSales = lazy(() =>  import("../../modules/Admin/Reports/Distributors/VanSales"));
const DistributorVolumeSold = lazy(() =>  import("../../modules/Admin/Reports/Distributors/VolumeSold"));
const DistributorVolumeReceived = lazy(() =>  import("../../modules/Admin/Reports/Distributors/VolumeReceived"));
const DistributorStockReceived = lazy(() =>  import("../../modules/Admin/Reports/Distributors/StockReceived"));
const DistributorStockLevel = lazy(() =>  import("../../modules/Admin/Reports/Distributors/StockLevel"));
const DistributorClosingStock = lazy(() =>  import("../../modules/Admin/Reports/Distributors/ClosingStock"));
const CustomerOrderSummary = lazy(() =>  import("../../modules/Admin/order/AdminCustomerOrders"));
const ManageCustomer = lazy(() =>  import("../../modules/Admin/customer/manage"));
const PageNotFoundMain = lazy(() => import("../../modules/Auth/pages/PageNotFound/404"));

export const MiniAdminLayoutRoutes = [
  { 'component': MiniAdminSummary, 'link': routes.MiniAdminSummary, exact: true },
  { 'component': MiniAdminCustomers, 'link': routes.MiniAdminCustomers, exact: true },
  { 'component': ManageCustomer, 'link': routes.ManageCustomer, exact: true },
  { 'component': CustomerOrderSummary, 'link': routes.CustomerOrderSummary, role: 'Mini-Admin', exact: true },
  { 'component': DistributorOrderSummary, 'link': routes.DistributorOrderSummary, role: 'Mini-Admin', exact: true },
  { 'component': DistributorTotalSales, 'link': routes.DistributorTotalSales, role: 'Mini-Admin', exact: true },
  { 'component': DistributorWalkInSales, 'link': routes.DistributorWalkInSales, role: 'Mini-Admin', exact: true },
  { 'component': DistributorStockReceived, 'link': routes.DistributorStockReceived, role: 'Mini-Admin', exact: true },
  { 'component': DistributorStockLevel, 'link': routes.DistributorStockLevel, role: 'Mini-Admin', exact: true },
  { 'component': DistributorClosingStock, 'link': routes.DistributorClosingStock, role: 'Mini-Admin', exact: true },
  { 'component': DistributorVolumeSold, 'link': routes.DistributorVolumeSold, role: 'Mini-Admin', exact: true },
  { 'component': DistributorVolumeReceived, 'link': routes.DistributorVolumeReceived, role: 'Mini-Admin', exact: true },
  { 'component': DistributorTotalDelivery, 'link': routes.DistributorTotalDelivery, role: 'Mini-Admin', exact: true },
  { 'component': DistributorVanSales, link: routes.DistributorVanSales, role: 'Mini-Admin', exact: true },
  { 'component': MiniAdminDistroDetails, 'link': routes.MiniAdminDistroDetails },
  { 'component': MiniAdminTotalSalesReport, 'link': routes.MiniAdminTotalSalesReport, exact: true },
  { 'component': MiniAdminTotalDelivery, 'link': routes.MiniAdminTotalDelivery, exact: true },
  { 'component': MiniAdminTotalVolumeSold, 'link': routes.MiniAdminTotalVolumeSold, exact: true },
  { 'component': MiniAdminTotalVolumeReceived, link: routes.MiniAdminTotalVolumeReceived, exact: true },
  { 'component': MiniAdminStockReceived, 'link': routes.MiniAdminStockReceived, exact: true },
  { 'component': MiniAdminSingleStockReceived, 'link': routes.MiniAdminSingleStockReceived, exact: true },
  { 'component': MiniAdminTotalWalkInSales, 'link': routes.MiniAdminTotalWalkInSales, exact: true },
  { 'component': MiniAdminTotalVanSales, 'link': routes.MiniAdminTotalVanSales, exact: true },
  { 'component': MiniAdminDailyStockCount, 'link': routes.MiniAdminDailyStockCount, exact: true },
  { 'component': MiniAdminDistributorStockLevel, 'link': routes.MiniAdminDistributorStockLevel, exact: true },
  { 'component': MiniAdminStockLevel, 'link': routes.MiniAdminStockLevel },
  { 'component': MiniAdminDistributorClosingStock, 'link': routes.MiniAdminDistributorClosingStock, exact: true },
  { 'component': MiniAdminClosingStock, 'link': routes.MiniAdminClosingStock },
  { 'component': MiniAdminReports, 'link': routes.MiniAdminReports, exact: true },
  { 'component': PageNotFoundMain, 'link': routes.PageNotFound }
]

export const MiniAdminDistRoutes = [
  { 'component': DistributorCustomers, 'link': routes.DistributorCustomers, exact: true },
  { 'component': DistributorUsers, 'link': routes.DistributorUsers, exact: true },
  { 'component': DistributorReports, 'link': routes.DistributorReports, exact: true },
  { 'component': DistributorOrders, 'link': routes.DistributorOrders, exact: true },
]

export const PrivateRoutes = [
  { 'component': MiniAdminDashboard, 'link': routes.MiniAdminDashboard }
]
