import { formatNumber } from "./formatNumber";

export const stripSkuFromBrandName = (product) => {
  if (
    product?.toLowerCase().includes("mls") ||
    product?.toLowerCase().includes("ml")
  ) {
    const newProduct = product.substring(0, product.lastIndexOf(" "));
    return newProduct;
  }
  return product;
};

export const stripProductTypeFromSku = (sku) => {
  if (sku?.toLowerCase().includes("rb") || sku?.toLowerCase().includes("nrb")) {
    const newSku = sku.substring(0, sku.lastIndexOf(" "));
    return newSku;
  }
  return sku;
};

export const formatEmptiesQuantity = (type, empties) => {
  const productType = type?.toLowerCase();
  return productType === "can" || productType === "pet" || productType === "nrb"
    ? "Nil"
    : formatNumber(empties);
};

export const invalidComboIds = [
  0,
  "0",
  null,
  "null",
  undefined,
  "undefined",
  "-",
  "",
];

export const getMozambiquePriceGroup = (priceGroup) => {
  let pG = priceGroup.toLowerCase();
  if (pG === "centre" || pG === "north" || pG === "south") {
    return pG;
  } else {
    return "centre";
  }
};

export const fetchAllCustomersInCountryForDistributor = (country) => {
  if (
    country === "Nigeria" ||
    country === "Mozambique" ||
    country === "Zambia"
  ) {
    return true;
  }
  return false;
};

export const isNonAbiCountry = (country) => {
  if (
    country === "Uganda" ||
    country === "Tanzania" ||
    country === "Zambia" ||
    country === "South Africa"
  ) {
    return true;
  }
  return false;
};

export const isContainerReturnable = (type) => {
  const productType = type?.toLowerCase();
  return (
    productType !== "can" && productType !== "pet" && productType !== "nrb"
  );
};

export const handleEInput = (evt) =>
  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();

export const isModeratorAdmin = (role, moderator) => {
  return role.toLowerCase() === "admin" && moderator === 1;
};

export const isSuperAdmin = (role, superAdmin) => {
  return role.toLowerCase() === "admin" && superAdmin === 1;
};

export const isSameDay = (dateStr1, dateStr2) => {
  if (!dateStr1 || !dateStr2) {
    return false;
  }
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const StructureProductData = (allValues, country) => {
  return {
    imageUrl: allValues.imageUrl ?? "",
    brand: allValues.brand ?? "",
    productId: allValues.productId,
    customId: allValues.customId || "",
    productType: allValues.productType.toLowerCase(),
    country:
      country === "South Africa" ? "SA" : country === "Uganda" ? "UG" : country,
    category: allValues.category,
    isAbi: allValues.is_abi ?? false,
    packageItemCount: allValues.package_item_count,
    packagePack: allValues.package_pack || 0,
    packageName: allValues.package_name ?? "",
    packageCount: allValues.package_count,
    containerSize: +allValues.container_size,
    containerUnitOfMeasurement: allValues.container_unit_of_measurement ?? "",
    resellerPrice:
      country === "Ghana" ? allValues.ptw_price ?? 0 : allValues.reseller_price ?? 0,
    subBrandName: allValues.sub_brand_name ?? "",
    containerReturnable: allValues.container_returnable,
    containerName: allValues.container_name ?? "",
    containerMaterial: allValues.container_material,
    palletQuantity: allValues.pallet_quantity || 0,
    isNarcotic: allValues.is_narcotic ?? false,
    isAlcoholic: allValues.is_alcoholic ?? false,
    brandId: allValues.brand_id,
    classification: allValues.classification,
    name: allValues.name ?? "",
    price: allValues.price,
    emptyPrice: allValues.empty_price || 0,
    retailerPrice:
      country === "Ghana"
        ? allValues.ptr_price ?? 0
        : country === "Uganda"
        ? allValues.high_end_price ?? 0
        : allValues.poc_price || 0,
    vendorItemId: allValues.vendor_item_id,
    highEndPrice: allValues.high_end_price || 0,
    highEndPriceOhc: allValues.high_end_price_ohc || 0,
    lowEndPrice: allValues.low_end_price || 0,
    mainStremPrice: allValues.main_stream_price || 0,
    mainStremPriceOoh: allValues.main_stream_ooh_price || 0,
    pocPrice: allValues.poc_price || 0,
    ptrPrice: allValues.ptr_price || 0,
    ptwPrice: allValues.ptw_price || 0,
    south: allValues.south || 0,
    centre: allValues.centre || 0,
    north: allValues.north || 0,
    A: allValues.A || 0,
    B: allValues.B || 0,
    C: allValues.C || 0,
    D: allValues.D || 0,
    E: allValues.E || 0,
    F: allValues.F || 0,
    status: allValues.status,
  };
};
