import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import FallbackPageWrapper from "../FallbackPageWrapper";
import { FallbackProvider } from "../context";
import { loadUserDataSuccess } from "../modules/Auth/actions/auth.action";
import DailyStockCountModal from "../modules/Distributors/components/DailyStockCountModal";
import {
  checkInventoryCheckinModal,
  setDailyStockModal,
} from "../modules/Inventory/actions/inventoryProductAction";
import { DistLayoutRoutes } from "../routes/KPO/KPOPrivateRoutes";
import axiosInstance from "../utils/axios";
import countryConfig from "../utils/changesConfig.json";
import { KpoRoutes } from "../utils/routes";
import urls from "../utils/urls";
import Navbar from "./Navbar";
import { getSingleDistributor } from "../modules/Admin/pages/actions/adminDistributorAction";
import PushNotification from "../modules/PushNotification";
import { hotjar } from "react-hotjar";

const { inventoryNet } = urls;

const Dashboard = ({
  location,
  children,
  sessionUser,
  pageTitle,
  back,
  total,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let code = location.pathname.split("/")[3];
  if (code && code !== undefined) {
    localStorage.setItem("distCode", code);
  } else {
    code = localStorage.getItem("distCode");
  }
  const navigationRef = useRef();
  const [navigationToggled, toggleNavigation] = useState(false);
  const { sessionUserData: AuthData, distPageTitle } = useSelector(
    (state) => state.Auth
  );
  const token = localStorage.getItem("userData");
  const country = AuthData?.country;
  const routes = KpoRoutes(code, country);
  const role = AuthData.roles;
  const distributor = useSelector(
    (state) => state.AllDistributorReducer.distributor
  );

  // prevent mini-admin from viewing distributor data that are not assigned to them.
  if (role === "Mini-Admin") {
    const distributorCodes = JSON.parse(AuthData?.DIST_Code);
    const miniAdminHasAccessToDistributor = distributorCodes.includes(code);
    if (!miniAdminHasAccessToDistributor) {
      history.push("/forbidden");
    }
  }

  // prevent admin/mini-admin from viewing distributor data of other countries.
  // Exception is given to moderator admin
  if (
    distributor.country &&
    distributor.country !== country &&
    !AuthData.moderator
  ) {
    history.push("/forbidden");
  }

  useEffect(() => {
    if (role === "Admin") {
      const country = localStorage.getItem("countryCode");
      dispatch(loadUserDataSuccess({ ...AuthData, country: country }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, role]);

  const route = location.pathname.split("/")[2];
  const countryConfigObject = countryConfig[country];

  useEffect(() => {
    if (token) {
      axiosInstance.get(`${inventoryNet}${code}`).then((response) => {
        const { data } = response.data;
        if (data.length > 0) {
          if (route !== "daily-stock") {
            axiosInstance
              .get(`${inventoryNet}daily-inventory-check/${code}`)
              .then((response) => {
                const { data } = response.data;
                if (data.status === false) {
                  dispatch(setDailyStockModal(true));
                } else {
                  dispatch(checkInventoryCheckinModal(code));
                }
              });
          }
        }
      });
    }
  }, [AuthData, token, code, dispatch, route]);

  const clickOutHandler = (event) => {
    if (navigationRef.current?.contains(event.target)) return;
    toggleNavigation(false);
  };

  useEffect(() => {
    if (navigationToggled) {
      setTimeout(() => window.addEventListener("click", clickOutHandler));
    }
    return () => window.removeEventListener("click", clickOutHandler);
  }, [navigationToggled]);

  useEffect(() => {
    getSingleDistributor(code);
  }, [code]);

  const selected = localStorage.getItem("i18nextLng") || "en-US";
  const forMatLang = selected.split("-")[0] || "en";

  const translation = {
    Home: {
      en: "Home",
      pg: "Casa",
    },
    Inventory: {
      en: "Inventory",
      pg: "Inventário",
    },
    Stock: {
      en: "Stock",
      pg: "Inventário",
    },
    Transactions: {
      en: "Transactions",
      pg: "Transações",
    },
    Finance: {
      en: "Finance",
      pg: "Transações",
    },
    "Van Warehouse": {
      en: "Van Warehouse",
      pg: "Armazém de vans",
    },
    Reports: {
      en: "Reports",
      pg: "Relatórios",
    },
    Customers: {
      en: "Customers",
      pg: "Clientes",
    },
    Analytics: {
      en: "Analytics",
      pg: "Análise",
    },
    Logistics: {
      en: "Logistics",
      pg: "logística",
    },
  };

  useEffect(() => {
    if (distributor.SYS_Code) {
      const { id, session_id, iat, exp, ...restUserInfo } = AuthData;
      hotjar.identify(id, {
        ...restUserInfo,
        SYS_Code: distributor.SYS_Code,
        Company_Name: distributor.company_name,
      });
    }
  }, [distributor, AuthData]);

  return (
    <FallbackPageWrapper>
      <div className="flex flex-col h-screen max-h-screen overflow-y-hidden bg-gray-30">
        <div className="flex h-full max-h-full">
          <div
            ref={navigationRef}
            className={`${
              navigationToggled ? "active" : ""
            } bg-dark navigation-bar h-screen overflow-y-scroll`}
          >
            <img
              className="pt-3 pb-5 mx-auto"
              src="/assets/svg/kuja-logo.svg"
              alt="logo"
              width={67}
              height={40}
            />
            <span className="absolute top-0 right-0 block text-white lg:hidden">
              <IconButton
                size="medium"
                edge={false}
                color="inherit"
                aria-label="menu"
                onClick={() => toggleNavigation(false)}
              >
                <CloseIcon color="inherit" className="!text-4xl" />
              </IconButton>
            </span>
            <ul className="sidebar-content">
              {routes.map((route, index) => {
                if (
                  route.label !== "Logistics" ||
                  (route.label === "Logistics" && country === "Nigeria")
                ) {
                  if (location.pathname === route.link) {
                    return (
                      <li
                        key={route.link}
                        className="py-4 mb-2 text-center bg-transparent border-l-2 border-white bg-opacity-20"
                      >
                        <Link to={route.link}>
                          <div className="items-center flex p-0.5 my-[0.02rem] mx-auto text-gold justify-center">
                            {route.label === "Finance"
                              ? route.icon2
                              : route.icon}
                          </div>

                          <span className="items-center space-x-6">
                            <span className="text-sm text-gold font-customRoboto">
                              {translation[route?.label][forMatLang]}
                            </span>
                          </span>
                        </Link>
                      </li>
                    );
                  } else {
                    return (
                      <li key={route.link} className="py-4 mb-2 text-center">
                        <Link to={route.link}>
                          <div className="flex p-0.5 my-[0.02rem] mx-auto justify-center text-grey-70">
                            {route.icon}
                          </div>
                          <span className="items-center space-x-6">
                            <span className="text-sm font-customRoboto text-grey-70">
                              {translation[route?.label][forMatLang]}
                            </span>
                          </span>
                        </Link>
                      </li>
                    );
                  }
                }
                return null;
              })}
            </ul>
          </div>
          <div className="w-full h-full max-h-full col-span-9 overflow-hidden">
            <Navbar
              location={location}
              pageTitle={distPageTitle.pageTitle}
              back={distPageTitle.back}
              sessionUser={sessionUser}
              toggleNavigation={toggleNavigation}
            />
            <PushNotification distCode={code} country={country}/>
            <div className="overflow-y-scroll max-h-full h-[calc(100vh-64px)] px-4 lg:pl-10 lg:pr-8 pb-16">
              <FallbackProvider>
                <Switch>
                  {DistLayoutRoutes.map((distRoute) => {
                    return (
                      <Route
                        exact={distRoute.exact}
                        key={distRoute.link}
                        path={distRoute.link}
                        component={distRoute.component}
                        roles={[distRoute.role]}
                      />
                    );
                  })}
                </Switch>
              </FallbackProvider>
            </div>
          </div>
        </div>

        <DailyStockCountModal
          code={code}
          countryConfigObject={countryConfigObject}
          country={country}
        />
      </div>
    </FallbackPageWrapper>
  );
};

export default Dashboard;
