import React from "react";
import { Return } from "../../assets/svg/adminIcons";
import { useGoBack } from "../../utils";

const KJGoBack = () => {
  const goBack = useGoBack();
  return (
    <div>
      <div className="mr-4 cursor-pointer" onClick={goBack}>
        <Return />
      </div>
    </div>
  );
};

export default KJGoBack;
