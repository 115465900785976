import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { CloseModal, Checked } from "../../assets/svg/modalIcons";
import Loading from "./Loading";
import { useHistory } from "react-router-dom";
import urls from "../../utils/urls";
import axiosInstance from "../../utils/axios";
import {
  getAllInventory,
  otherProductsToAdd,
  setSelectedOtherProducts,
  updateOtherProducts,
} from "../../modules/Inventory/actions/inventoryProductAction";

const { inventory } = urls;

const UnsavedOtherProducts = ({ Dist_Code: DistCode }) => {
  const country = useSelector((state) => state.Auth.sessionUserData).country;
  const history = useHistory();
  const [approvalModal, setApprovalModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [save, setSave] = useState("Yes, add");
  const [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();

  let dist_code = useSelector((state) => state.DistReducer.dist_code);
  if (!dist_code) {
    dist_code = DistCode;
  }

  const handleReset = () => {
    setDiscard(false);
    setApprovalModal(false);
  };

  const productsToSave = useSelector(
    (state) => state.InventoryReducer.otherProductsToSave
  );

  const discardChanges = () => {
    dispatch(otherProductsToAdd([]));
    setDiscard(false);
    dispatch(setSelectedOtherProducts([]));
    dispatch(updateOtherProducts(false));
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  const updateDB = () => {
    setSave("Updating ");
    setLoading(true);
    let toDB = {};
    toDB["companyCode"] = dist_code;
    toDB["country"] = country === "South Africa" ? "SA" : country;
    toDB["stocks"] = productsToSave;


    axiosInstance
      .post(`${inventory}non-abi/inventory/add-stock`, toDB)
      .then(() => {
        setApprovalModal(false);
        setSuccessModal(true);
        setSave("Yes, add");
        setLoading(false);
        dispatch(getAllInventory(dist_code));
        dispatch(setSelectedOtherProducts([]));
        dispatch(updateOtherProducts(false));
        history.push("/dashboard/inventory/" + dist_code);

      });

  };

  return (
    <>
      <Fade>
        <div className="bg-gold shadow-xl lg:h-24 flex justify-between py-3 pl-3 lg:pr-8 w-full shadow-[0px_4px_16px_rgba(89, 85, 84, 0.15)]">
          <div className="w-10 md:w-24"></div>
          <div className="w-full flex items-center flex-col lg:flex-row justify-between">
            <div className="font-customGilroy lg:w-8/12 italic font-bold text-xl py-3">
              Click “Done” to complete this process
            </div>
            <div className="inline-flex justify-between">
              <div
                className="bg-transparent text-white text-center text-base mr-6 border font-semibold border-white rounded p-3 cursor-pointer"
                onClick={() => {
                  setDiscard(true);
                }}
                onKeyDown={() => setDiscard(true)}
                role="button"
              >
                Discard changes
              </div>
              <div
                className="bg-dark text-gold text-base text-center w-[5.5rem] font-semibold rounded p-3 cursor-pointer"
                onClick={() => setApprovalModal(true)}
                onKeyDown={() => setApprovalModal(true)}
                role="button"
              >
                Done
              </div>
            </div>
          </div>
        </div>
      </Fade>

      <Transition.Root show={discard} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setDiscard}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-mini-modal">
                <CloseModal
                  className="ml-auto m-4 mb-0"
                  onClick={handleReset}
                />
                <div className="h-mini-modal flex justify-center items-center">
                  <p className="font-customGilroy not-italic text-base font-medium px-4 mx-20">
                    All the products you’ve added will not be saved. Are you
                    sure you want to discard the products?
                  </p>
                </div>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className="bg-gold flex rounded font-customGilroy text-gray-100 text-center text-sm font-bold not-italic px-14 py-2"
                    onClick={() => discardChanges()}
                  >
                    Yes, discard products
                  </button>

                  <button
                    className="bg-white border border-solid rounded font-customGilroy text-grey-70 text-center text-sm font-bold not-italic px-7 py-2"
                    onClick={handleReset}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={approvalModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setApprovalModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-mini-modal">
                <CloseModal
                  className="ml-auto m-4 mb-0"
                  onClick={handleReset}
                />
                <div className="h-mini-modal flex justify-center items-center">
                  <p className="font-customGilroy not-italic text-base font-medium px-4">
                    Add new products to your inventory?
                  </p>
                </div>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className="bg-gold rounded flex font-customGilroy text-gray-100 text-center text-sm font-bold not-italic px-14 py-2"
                    onClick={() => updateDB()}
                  >
                    {save}
                    {loading ? <Loading /> : ""}
                  </button>

                  <button
                    className="bg-white border border-solid rounded font-customGilroy text-grey-70 text-center text-sm font-bold not-italic px-7 py-2"
                    onClick={handleReset}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={successModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setSuccessModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                <button
                  className="flex justify-center ml-auto m-4 mb-0"
                  onClick={() => setSuccessModal(false)}
                >
                  <CloseModal />
                </button>
                <div className="flex flex-col justify-center items-center w-modal h-sub-modal -mt-12">
                  <Checked />
                  <p className="font-customGilroy not-italic font-medium text-xl text-center text-grey-85 mt-4">
                    New products added to your inventory
                  </p>
                </div>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className="bg-gold rounded font-customGilroy text-gray-100 text-center text-sm font-bold not-italic px-14 py-2"
                    onClick={() => setSuccessModal(false)}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default UnsavedOtherProducts;
