import { ONGOING_STOPS_DATA, ONGOING_STOPS_DATA_FAILED } from "../actions/types";

const initial_state = {
	ongoingStopsData: [],
	pageCount: 0,
	ongoingStopsError: false,
	loading: true
};

const LogisticsReducer = (state = initial_state, action) => {
	switch (action.type) {
		case ONGOING_STOPS_DATA:
			return {
				...state,
				ongoingStopsData: action.ongoingStopsData,
				pageCount: action.pageCount,
				loading: action.loading
			};
		case ONGOING_STOPS_DATA_FAILED:
			return {
				...state,
				ongoingStopsError: action.ongoingStopsError 
			}
		default:
			return state;
	}
};
export default LogisticsReducer;
