import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { CloseModal, Checked } from "../../assets/svg/modalIcons";
import Loading from "./Loading";
import {
  getAllSingleVanInventory,
  updateTransferQuantity,
  discardChanges,
} from "../../modules/VanInventory/actions/vanAction";
import urls from "../../utils/urls";
import axiosInstance from "../../utils/axios";
import { t } from "i18next";

const { inventory } = urls;

const UnsavedVanTransferChanges = () => {
  const [approvalModal, setApprovalModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [save, setSave] = useState("Replenish Van");
  const [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();

  const transferQuantity = useSelector(
    (state) => state.VanInventoryReducer.transfer_quantities
  );
  const driverId = useSelector((state) => state.VanInventoryReducer.driverId);

  const dist_code = useSelector((state) => state.VanInventoryReducer.distCode);

  const handleReset = () => {
    setApprovalModal(false);
  };

  const updateDB = () => {
    let processedArray = transferQuantity?.map((item) => ({
      productId: parseInt(item.productId),
      quantity: parseInt(item.quantity),
    }));
    const values = {
      companyCode: dist_code,
      vehicleId: parseInt(driverId, 10),
      stocks: processedArray,
    };

    setSave("Replenishing ");
    setLoading(true);

    axiosInstance
      .post(`${inventory}van/replenish?replenishmentType=Manual`, values)
      .then((response) => {
        setApprovalModal(false);
        setSuccessModal(true);
        setSave("Van Replenish");
        setLoading(false);
        dispatch(updateTransferQuantity(false));
        dispatch(getAllSingleVanInventory(driverId));
        setTimeout(() => {
          window.location.href = `/dashboard/van-replenishment/${dist_code}`;
        }, 1000);
      })
      .catch((error) => {});
  };

  return (
    <>
      <Fade>
        <div className="bg-gold justify-between w-full pl-3 pr-20 py-3 flex shadow-[0px _4px_16px_rgba(89, 85, 84, 0.15)] h-20">
          <div className="w-[6.25rem]"></div>
          <div className="font-customGilroy italic font-bold text-gray-100 text-xl py-3">
            {t("you_have_unsaved_changes_Do_you_want_to_save_your_changes?")}
          </div>
          <div className="inline-flex justify-between">
            <div
              className="bg-transparent text-white text-base font-semibold text-center mr-6 cursor-pointer border border-white rounded p-3"
              onClick={() => {
                dispatch(discardChanges());
                window.location.reload();
              }}
              onKeyDown={() => {
                dispatch(discardChanges());
                window.location.reload();
              }}
              role="button"
            >
              {t("discard_changes")}
            </div>
            <div
              className="bg-dark text-gold text-base font-semibold text-center w-[5.5rem] cursor-pointer rounded p-3"
              onClick={() => {
                setApprovalModal(true);
              }}
              onKeyDown={() => {
                setApprovalModal(true);
              }}
              role="button"
            >
              {t("save")}
            </div>
          </div>
        </div>
      </Fade>

      <Transition.Root show={approvalModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setApprovalModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-mini-modal">
                <CloseModal
                  className="ml-auto m-4 mb-0"
                  onClick={handleReset}
                />
                <div className="h-mini-modal flex justify-center items-center">
                  <p className="font-customGilroy not-italic text-base font-medium px-4">
                    {t("Are you sure you want to Replenish this Van")}
                  </p>
                </div>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className="bg-gold rounded flex font-customGilroy text-gray-100 text-center text-sm font-bold not-italic px-14 py-2"
                    onClick={() => updateDB()}
                  >
                    {save}
                    {loading ? <Loading /> : ""}
                  </button>

                  <button
                    className="bg-white border border-solid rounded font-customGilroy text-grey-70 text-center text-sm font-bold not-italic px-7 py-2"
                    onClick={handleReset}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={successModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setSuccessModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                <button
                  className="flex justify-center ml-auto m-4 mb-0"
                  onClick={() => setSuccessModal(false)}
                >
                  <CloseModal />
                </button>
                <div className="flex flex-col justify-center items-center w-modal h-sub-modal -mt-12">
                  <Checked />
                  <p className="font-customGilroy not-italic font-medium text-xl text-center text-grey-85 mt-4">
                    {t("Your van has been replenished")}
                  </p>
                </div>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className="bg-gold rounded font-customGilroy text-gray-100 text-center text-sm font-bold not-italic px-14 py-2"
                    onClick={() => setSuccessModal(false)}
                  >
                    {t("okay")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default UnsavedVanTransferChanges;
