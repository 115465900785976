import { lazy } from 'react';
import { routes } from './KPORoutes';
const DistributorList = lazy(() =>  import("../../modules/Distributors/pages/DistributorList"));
const DistributorDashboard = lazy(() =>  import("../../Layout/Dashboard"));
const Forbidden = lazy(() => import("../../modules/Auth/pages/Forbidden"));
const PageNotFoundMain = lazy(() => import("../../modules/Auth/pages/PageNotFound/404"));

const Overview = lazy(() =>  import("../../modules/Distributors/pages/DistributorDashboard"));
const Inventory = lazy(() =>  import("../../modules/Inventory/pages/InventoryHome"));
const InventoryAdjustment = lazy(() =>  import("../../modules/InventoryAdjusment/pages/InventoryHome"));
const Transactions = lazy(() =>  import("../../modules/Transaction/pages/TransactionPage"));
const VanInventory = lazy(() =>  import("../../modules/VanInventory/pages/VanInventoryHome"));
const Logistics = lazy(() =>  import("../../modules/Logistics/pages"));
const ReplenishmentHistory = lazy(() =>  import("../../modules/VanInventory/pages/ReplenishmentHistory/pages"));
const DashboardTotalSales = lazy(() =>  import("../../modules/Reports/TotalSales"));
const DashboardTotalDelivery = lazy(() =>  import("../../modules/Reports/SalesByDelivery"));
const DashboardWalkInSales = lazy(() =>  import("../../modules/Reports/WalkInSales"));
const DashboardVanSales = lazy(() =>  import("../../modules/Reports/VanSales"));
const DashboardVolumeSold = lazy(() =>  import("../../modules/Reports/VolumeSold"));
const DashboardVolumeReceived = lazy(() =>  import("../../modules/Reports/VolumeReceived"));
const DashboardStockReceived = lazy(() =>  import("../../modules/Reports/StockReceived"));
const DashboardSingleStockReceived = lazy(() =>  import("../../modules/Reports/SingleStockReceived"));
const VolumeSoldPerProduct = lazy(() =>  import("../../modules/Reports/VolumeSoldPerProduct"));
const FinanceHome = lazy(() =>  import("../../modules/Finance/pages/index"));
const FinanceReportTotalSales = lazy(() =>  import("../../modules/Finance/pages/Report/TotalSales"));
const FinanceReportCashAtHand = lazy(() =>  import("../../modules/Finance/pages/Report/CashAtHand"));
const FinanceReportAccountReceivable = lazy(() =>  import("../../modules/Finance/pages/Report/AccountReceivable/index"));
const FinanceReportCurrentStockValue = lazy(() =>  import("../../modules/Finance/pages/Report/CurrentStockValue"));
const FinanceAccountReceivableCustomer = lazy(() =>  import("../../modules/Finance/pages/Report/AccountReceivable/Customers"));
const KpoCustomers = lazy(() =>  import("../../modules/customer/list"));
const ManageCustomer = lazy(() =>  import("../../modules/Admin/customer/manage"));
const KpoManageCustomer = lazy(() =>  import("../../modules/customer/manage"));
const ReturnSummary = lazy(() =>  import("../../modules/order/ReturnSummary"));
const VanTransactions = lazy(() =>  import("../../modules/VanInventory/pages/VanTransactions"));
const VanReplenishment = lazy(() =>  import("../../modules/VanInventory/pages/VanReplenishment/pages"));
const VanSalesMen = lazy(() =>  import("../../modules/VanInventory/pages/VanSalesMen"));
const KpoOrderSummary = lazy(() =>  import("../../modules/order/summary"));
const KpoOrderList = lazy(() =>  import("../../modules/order/lists"));
const Reports = lazy(() =>  import("../../modules/Reports/"));
const Other_Products = lazy(() =>  import("../../modules/Inventory/pages/OtherProducts"));
const Analytics = lazy(() =>  import("../../modules/Analytics/pages"));
const Notifications = lazy(() =>  import("../../modules/Notification/pages/NotificationList"));
const ReceiveEmpties = lazy(() =>  import("../../modules/Inventory/pages/ReceiveEmpties"));
const ReturnEmpties = lazy(() =>  import("../../modules/Inventory/pages/ReturnEmpties"));
const InventorySetup = lazy(() =>  import("../../modules/Inventory/pages/InventorySetup"));
const ReturnProducts = lazy(() =>  import("../../modules/Inventory/pages/ReturnProducts"));
const CaptureSales = lazy(() =>  import("../../modules/Transaction/pages/CaptureSales"));
const AddDetails = lazy(() =>  import("../../modules/Stock/components/step2"));
const AddProduct = lazy(() =>  import("../../modules/Stock/components/step3"));
const SalesReturn = lazy(() =>  import("../../modules/order/SalesReturn"));
const DailyStockCount = lazy(() =>  import("../../modules/Inventory/pages/DailyStockCount"));
const ManageVsm = lazy(() =>  import("../../modules/VanInventory/pages/VanSalesMen/ManageVsms"));
const InsightsList = lazy(() =>  import("../../modules/Insights/InsightsList"));

export const DistLayoutRoutes = [
  { 'component': Overview, 'link': routes.Overview, exact: true },
  { 'component': Inventory, 'link': routes.Inventory, exact: true },
  { 'component': InventoryAdjustment, 'link': routes.InventoryAdjustment, exact: true },
  { 'component': Transactions, 'link': routes.Transactions, exact: true },
  { 'component': CaptureSales, 'link': routes.CaptureSales, exact: true },
  { 'component': InventorySetup, 'link': routes.InventorySetup, exact: true },
  { 'component': ReturnProducts, 'link': routes.ReturnProducts, exact: true },
  { 'component': AddDetails, 'link': routes.AddDetails, exact: true },
  { 'component': SalesReturn, 'link': routes.SalesReturn, exact: true },
  { 'component': AddProduct, 'link': routes.AddProduct, exact: true },
  { 'component': ReceiveEmpties, 'link': routes.ReceiveEmpties, exact: true },
  { 'component': ReturnEmpties, 'link': routes.ReturnEmpties, exact: true },
  { 'component': Logistics, 'link': routes.Logistics, exact: true },
  { 'component': DashboardTotalSales, 'link': routes.DashboardTotalSales, exact: true },
  { 'component': DashboardVolumeSold, 'link': routes.DashboardVolumeSold, exact: true },
  { 'component': DashboardVolumeReceived, 'link': routes.DashboardVolumeReceived, exact: true },
  { 'component': DashboardTotalDelivery, 'link': routes.DashboardTotalDelivery, exact: true },
  { 'component': DashboardWalkInSales, 'link': routes.DashboardWalkInSales, exact: true },
  { 'component': DashboardStockReceived, 'link': routes.DashboardStockReceived, exact: true },
  { 'component': DashboardSingleStockReceived, 'link': routes.DashboardSingleStockReceived, exact: true },
  { 'component': VolumeSoldPerProduct, 'link': routes.VolumeSoldPerProduct, exact: true },
  { 'component': DashboardVanSales, 'link': routes.DashboardVanSales, exact: true },
  { 'component': FinanceReportTotalSales, 'link': routes.FinanceReportTotalSales, exact: true },
  { 'component': FinanceReportCashAtHand, 'link': routes.FinanceReportCashAtHand, exact: true },
  { 'component': FinanceAccountReceivableCustomer, 'link': routes.FinanceAccountReceivableCustomer, exact: true },
  { 'component': FinanceReportAccountReceivable, 'link': routes.FinanceReportAccountReceivable, exact: true },
  { 'component': FinanceReportCurrentStockValue, 'link': routes.FinanceReportCurrentStockValue, exact: true },
  { 'component': FinanceHome, 'link': routes.FinanceHome, exact: true },
  { 'component': Other_Products, 'link': routes.Other_Products, exact: true },
  { 'component': KpoCustomers, 'link': routes.KpoCustomers, exact: true },
  { 'component': Analytics, 'link': routes.Analytics, exact: true },
  { 'component': VanInventory, 'link': routes.VanInventory, exact: true },
  { 'component': ReplenishmentHistory, 'link': routes.ReplenishmentHistory, exact: true },
  { 'component': VanTransactions, 'link': routes.VanTransactions, exact: true },
  { 'component': VanSalesMen, 'link': routes.VanSalesMen, exact: true },
  { 'component': VanReplenishment, 'link': routes.VanReplenishment, exact: true },
  { 'component': ManageCustomer, 'link': routes.ManageCustomer, exact: true },
  { 'component': KpoManageCustomer, 'link': routes.KpoManageCustomer, exact: true },
  { 'component': KpoOrderList, 'link': routes.KpoOrderList, exact: true },
  { 'component': KpoOrderSummary, 'link': routes.KpoOrderSummary, exact: true },
  { 'component': ReturnSummary, 'link': routes.ReturnSummary, exact: true },
  { 'component': Reports, 'link': routes.Reports, exact: true },
  { 'component': Notifications, 'link': routes.Notifications, exact: true },
  { 'component': DailyStockCount, 'link': routes.DailyStockCount, exact: true },
  { 'component': ManageVsm, 'link': routes.ManageVsm, exact: true },
  { 'component': InsightsList, 'link': routes.Insights },
  { 'component': PageNotFoundMain, 'link': routes.PageNotFound }
]

export const PrivateRoutes = [
  { 'component': DistributorList, 'link': routes.DistributorList, exact: true },
  { 'component': DistributorDashboard, 'link': routes.Dashboard },
  { 'component': Forbidden, 'link': routes.Forbidden }
]
