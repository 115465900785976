import {
  VIEW_ALL_USERS,
  EDIT_ACTION,
  UPDATE_USER,
  VIEW_ALL_DISTRIBUTORS,
  FILTERED_DISTRIBUTORS,
  SELECTED_DISTRIBUTORS,
  SAVE_DISTRIBUTORS,
  ADD_ADMIN_ACTION,
  NEW_USER_DETAILS,
  SUSPEND_ACTION,
  UNSUSPEND_ACTION,
  CHANGEROLE_ACTION,
  CHANGEDIST_ACTION,
  LOADING_STATE,
  UPDATE_USER_DATA,
  GET_NEW_USERS_SUCCESS,
  GET_NEW_USERS_START,
  GET_NEW_USERS_FAILURE,
} from "../types/UserTypes";

const initial_state = {
	allUsers: [],
	edit_action: false,
	suspend_action: false,
	unsuspend_action: false,
	changerole_action: false,
	changedist_action: false,
	add_admin_action: false,
	kpo_id: 0,
	customer_id: 0,
	allDistibutors: [],
	filteredDistributor: [],
	selectedDistributors: [],
	kpoDistributors: [],
	userDetails: {},
	loading: false,
	userData: "Admin",
	userPhoneNumber: "",
	assignedUsersLength: 0,
	newUsers: [],
	isNewUserLoading: false,
	newUsersCount: 0,
	newUsersFailure: null
};

const UsersReducer = (state = initial_state, action) => {
  const {
		type,
		all_users,
		edit_action,
		kpo_id,
		all_distributors,
		filtered_distributors,
		selected_distributors,
		new_distributors,
		add_admin_action,
		user_details,
		suspend_action,
		unsuspend_action,
		allUsers,
		changerole_action,
		changedist_action,
		customer_id,
		loading,
		userData,
		userPhoneNumber,
		assignedUsersLength,
		newUsers,
		newUsersCount,
		newUsersFailure,
	} = action;
  switch (type) {
    case VIEW_ALL_USERS: {
      return { ...state, allUsers: all_users, assignedUsersLength: assignedUsersLength };
    }
    case GET_NEW_USERS_SUCCESS: {
      return {
				...state,
				newUsers: newUsers,
				isNewUserLoading: false,
				newUsersCount: newUsersCount,
				newUsersFailure: null,
			};
    }
    case GET_NEW_USERS_START: {
      return {
				...state,
				newUsers: [],
				isNewUserLoading: true,
				newUsersCount: 0,
				newUsersFailure: null,
			};
    }
    case GET_NEW_USERS_FAILURE: {
      return {
				...state,
				newUsers: [],
				isNewUserLoading: false,
				newUsersCount: 0,
				newUsersFailure: newUsersFailure,
			};
    }
    case EDIT_ACTION: {
      return { ...state, edit_action: edit_action, kpo_id: kpo_id };
    }
    case SUSPEND_ACTION: {
      return { 
        ...state, 
        suspend_action: suspend_action, 
        kpo_id: kpo_id, 
        userPhoneNumber: userPhoneNumber 
      };
    }
    case UNSUSPEND_ACTION: {
      return { 
        ...state, 
        unsuspend_action: unsuspend_action, 
        kpo_id: kpo_id, 
        userPhoneNumber: userPhoneNumber 
      };
    }
    case CHANGEROLE_ACTION: {
      return { ...state, changerole_action: changerole_action, kpo_id: kpo_id };
    }
    case CHANGEDIST_ACTION: {
      return {
        ...state,
        changedist_action: changedist_action,
        customer_id: customer_id,
      };
    }
    case ADD_ADMIN_ACTION: {
      return { ...state, add_admin_action: add_admin_action, kpo_id: kpo_id };
    }
    case UPDATE_USER: {
      return { ...state, allUsers: allUsers };
    }
    case LOADING_STATE: {
      return{...state, loading: loading}
    }
    case VIEW_ALL_DISTRIBUTORS: {
      return { ...state, allDistributors: all_distributors };
    }
    case FILTERED_DISTRIBUTORS: {
      return { ...state, filteredDistributors: filtered_distributors };
    }
    case SELECTED_DISTRIBUTORS: {
      return { ...state, selectedDistributors: selected_distributors };
    }
    case NEW_USER_DETAILS: {
      return { ...state, userDetails: user_details };
    }
    case SAVE_DISTRIBUTORS: {
      return {
        ...state,
        kpoDistributors: [...state.kpoDistributors, new_distributors],
      };
    }
    case UPDATE_USER_DATA: {
      return { ...state, userData: userData };
    }
    default:
      return state;
  }
};

export default UsersReducer;