import urls from '../../../utils/urls';
import axiosInstance from '../../../utils/axios';
import {
  CREATECATALOGUE,
  DELETECATALOGUE,
  UPDATECATALOGUEPRICE,
  GETALLCATALOGUE,
  GETCATALOGUEBYCATALOGUEID,
  GETCATALOGUEBYSELLERID,
  DISCARD_CHANGES,
  UNSAVED_CATALOGUE_CHANGES,
  UPDATED_QUANTITY
} from './types'

const { productCatolgueNet } = urls;

export const createCatalogue = (values) => (dispatch) => {
  axiosInstance.post(`${productCatolgueNet}CreateProductCatalogue`, values).then((response) => {
    const { result } = response.data
    return dispatch({
      type: CREATECATALOGUE,
      addCatalogue: result
    })
  })
  .catch((error) => {
    return;
  });
}

export const deleteCatalogue = (catalogueId) => (dispatch) => {
  axiosInstance.delete(`${productCatolgueNet}DeleteProductCatalogue/${catalogueId}`).then((response) => {
    const { result } = response.data
    return dispatch({
      type: DELETECATALOGUE,
      deleteCatalogue: result
    })
  })
  .catch((error) => {
    return;
  });
}

export const updatePriceCatalogue = (values) => (dispatch) => {
  axiosInstance.patch(`${productCatolgueNet}EditProductCatalogue/UpdatePrice`, values).then((response) => {
    const { result } = response.data
    return dispatch({
      type: UPDATECATALOGUEPRICE,
      updatePriceCatalogue: result
    })
  })
  .catch((error) => {
    return;
  });
}

export const cataloguePriceChange =
  (itemsToSave) => (dispatch) => {
    dispatch({
      type: UPDATED_QUANTITY,
      catalogue_quantities: itemsToSave,
    });
  };

export const discardChanges = () => (dispatch) => {
  dispatch({
    type: DISCARD_CHANGES,
  });
};

export const updateCataloguePrice = (action) => (dispatch) => {
  dispatch({
    type: UNSAVED_CATALOGUE_CHANGES,
    catalogue_change: action,
  });
};

export const getAllCatalogue = () => (dispatch) => {
  axiosInstance.get(`${productCatolgueNet}GetProductCatalogue/GetAll`).then((response) => {
    return dispatch({
      type: GETALLCATALOGUE,
      getAllCatalogue: response
    })
  })
  .catch((error) => {
    return;
  });
}

export const getCatalogueByCatalogueId = (catalogueId) => (dispatch) => {
  axiosInstance.get(`${productCatolgueNet}GetProductCatalogue/GetByProductCatalogueId/${catalogueId}`).then((response) => {
    return dispatch({
      type: GETCATALOGUEBYCATALOGUEID,
      getCatalogueByCatalogueId: response
    })
  })
  .catch((error) => {
    return;
  });
}

export const getCatalogueBySellerId = (sellerCompanyId) => (dispatch) => {
  axiosInstance.get(`${productCatolgueNet}GetProductCatalogue/GetByCompanyId/${sellerCompanyId}`).then((response) => {
    return dispatch({
      type: GETCATALOGUEBYSELLERID,
      getCatalogueBySellerId: response
    })
  })
  .catch((error) => {
    return;
  });
}