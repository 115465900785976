import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import React from "react";

const KJHamburger = ({ toggleNavigation }) => {
  return (
    <div className="hamburger lg:hidden">
      <IconButton
        size="medium"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleNavigation}
      >
        <MenuIcon color="inherit" className="!text-5xl" />
      </IconButton>
    </div>
  );
};

export default KJHamburger;
