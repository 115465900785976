import React, { useEffect, useState} from "react";
import { getLocation } from "../../../utils/getUserLocation";
import AuthLayout from '../Layout/Auth';
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { checkRole } from '../../../callBack'

const Login = () => {
  const [country, setCountry] = useState('Ghana');
	const history = useHistory();
	const [user, setUser] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false)

	useEffect(() => {
		(async() => {
			const loc = await getLocation();
			setCountry(loc);
		})()
	}, [country]);

	const getLanguage = (country) => {
		let text;
		switch (country) {
			case "Mozambique":
				text = `Bem-vindo ao KUJA`
				break;
			default:
				text = `Welcome to KUJA`
				break;
		}
		return text
	}

  useEffect(() => {
		const token = localStorage.getItem('userData');
    if (token) {
			let AuthData = jwtDecode(token);
      if (AuthData &&
        (AuthData?.DIST_Code === null || AuthData?.DIST_Code === "" || AuthData.roles === "Admin" || AuthData.roles === "Mini-Admin" || JSON.parse(AuthData?.DIST_Code))) {
					setUser(AuthData)
					setIsAuthenticated(true)
      }
    }
    else {
      setIsAuthenticated(false)
    }
  }, [])

	if (isAuthenticated) {
		checkRole(user, history);
		return null;
	}

	return (
		<div>
			<AuthLayout
				title={getLanguage(country)}
				buttonText='Continue'
			/>
		</div>
	)
}

export default Login;