import { useEffect } from "react";

export function useComponentVisible(ref, setIsVisible) {
  const handleClickOutside = (e) => {
    return ref && !ref?.current?.contains(e.target)
      ? setIsVisible(false)
      : null;
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref };
}
