import { useEffect, useMemo } from "react";
import { usePageRoute } from "./utils/hooks/usePageRoute";

const FallbackPageWrapper = ({ children }) => {
  const { onLoad } = usePageRoute();
  const render = useMemo(() => children, [children]);

  useEffect(() => {
    onLoad(render);
  }, [onLoad, render]);

  return render;
};

export default FallbackPageWrapper;
