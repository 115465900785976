import AdminDistributorLayout from "../../Admin/Layout";
import { GetDistributors } from "../GetDistributors";
import { useCustomNavbarTitle } from "../../../utils/hooks";
import FallbackPageWrapper from "../../../FallbackPageWrapper";

const Home = () => {
  useCustomNavbarTitle('admin', `my_distributors`, false, GetDistributors()?.length);

  return (
    <FallbackPageWrapper>
      <div className="pt-10">
        <AdminDistributorLayout
          DistributorList={GetDistributors()}
        />
      </div>
    </FallbackPageWrapper>
  );
};

export default Home;
