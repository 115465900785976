const actions = {
  CHECK_EMAIL_START: "CHECK_EMAIL_START",
  CHECK_EMAIL_FAILURE: "CHECK_EMAIL_FAILURE",
  CHECK_EMAIL_SUCCESS: "CHECK_EMAIL_SUCCESS",
  SET_CURRENT_USER: "SET_CURRENT_USER",

  FORGOT_PASSWORD_START: "FORGOT_PASSWORD_START",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",

  // Login actions
  LOGIN_START: "LOGIN_START",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",

  // Signup actions
  SIGNUP_START: "SIGNUP_START",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",

  // Load user actions
  LOAD_USER_START: "LOAD_USER_START",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_DATA_SUCCESS: "LOAD_USER_DATA_SUCCESS",

  // Logout action
  LOGOUT: "LOGOUT",

  // Clear Check Email
  CLEAR_CHECK_EMAIL: "CLEAR_CHECK_EMAIL",

  // set navbar title
  SET_DIST_NAVIGATION_TITLE: "SET_DIST_NAVIGATION_TITLE",
  SET_ADMIN_NAVIGATION_TITLE: "SET_ADMIN_NAVIGATION_TITLE",
};

export default actions;
