export const VIEW_ALL_ORDERS = "VIEW_ALL_ORDERS";
export const VIEW_SINGLE_ORDER = "VIEW_SINGLE_ORDER";
export const VIEW_ORDERS_BY_BUYER_CODE = "VIEW_ORDERS_BY_BUYER_CODE";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const ASSIGN_ORDER_TO_DRIVER = "ASSIGN_ORDER_TO_DRIVER";
export const GET_ALL_DRIVERS = "GET_ALL_DRIVERS";
export const GET_ALL_DRIVERS_BY_OWNER_ID = "GET_ALL_DRIVERS_BY_OWNER_ID"
export const DIST_COMPLETED_ORDERS = "DIST_COMPLETED_ORDERS";
export const CAPTURE_SALES_START = "CAPTURE_SALES_START"
export const CAPTURE_SALES = "CAPTURE_SALES"
export const GET_ALL_ORDERS_BY_DRIVER = "GET_ALL_ORDERS_BY_DRIVER";
export const CAPTURE_SALES_FAILED = "CAPTURE_SALES_FAILED"
export const CAPTURE_SALES_CLEAR = "CAPTURE_SALES_CLEAR"
export const RELOAD_INVENTORY = "RELOAD_INVENTORY";
export const RELOAD_INVENTORY_END = "RELOAD_INVENTORY_END";
export const GET_ALL_ORDERS_BY_DATE = "GET_ALL_ORDERS_BY_DATE";
export const GET_ALL_ORDERS_SUMMARY_BY_DATE = "GET_ALL_ORDERS_SUMMARY_BY_DATE";
export const GET_PAGINATED_ORDERS_BY_DATE = "GET_PAGINATED_ORDERS_BY_DATE";
export const GET_ALL_ORDERS_BY_DATE_FOR_REPORT = "GET_ALL_ORDERS_BY_DATE_FOR_REPORT";
export const GET_ORDERS_BY_DATE_AND_STATUS_FOR_REPORT = "GET_ORDERS_BY_DATE_AND_STATUS_FOR_REPORT";
export const GET_ALL_VSM = "GET_ALL_VSM"
export const RESET = "RESET";
export const REJECT_ORDER = "REJECT_ORDER";
export const PICKUP = "PICKUP";
export const UPDATE_MULTIPLE_ORDERS = "UPDATE_MULTIPLE_ORDERS";
export const GET_MINI_ADMIN_SUMMARY = "GET_MINI_ADMIN_SUMMARY";
export const ORDERS_DATA = "ORDERS_DATA"
export const SET_VEHICLE_ID = "SET_VEHICLE_ID"
export const SUSPEND_VEHICLE = "SUSPEND_VEHICLE"
export const UNSUSPEND_VEHICLE = "UNSUSPEND_VEHICLE"
export const UPDATE_ORDER = "UPDATE_ORDER"
export const SET_ORDER_DATA = "SET_ORDER_DATA"
export const SET_SINGLE_ORDER = "SET_SINGLE_ORDER"
export const SET_LOADING = "SET_LOADING"
export const GET_TOTAL_CUSTOMERS_BREAKDOWN = "GET_TOTAL_CUSTOMERS_BREAKDOWN"
export const SET_SELECTED_ORDERS = "SET_SELECTED_ORDERS"
export const ASSIGN_MODAL = "ASSIGN_MODAL"
export const REVERT_ORDER = "REVERT_ORDER"
export const SELECTED_DATE_RANGE = "SELECTED_DATE_RANGE"