export const ADD_INVENTORY_PRODUCT = "ADD_INVENTORY_PRODUCT";
export const DELETE_INVENTORY_PRODUCT = "DELETE_INVENTORY_PRODUCT";
export const EDIT_INVENTORY_PRODUCT = "EDIT_INVENTORY_PRODUCT";
export const VIEW_ALL_INVENTORY_PRODUCT = "VIEW_ALL_INVENTORY_PRODUCT";
export const VIEW_SINGLE_INVENTORY_PRODUCT = "VIEW_SINGLE_INVENTORY_PRODUCT";
export const VIEW_ALL_INVENTORY_COMPANY = "VIEW_ALL_INVENTORY_COMPANY";
export const GET_DISTRIBUTORS_CLOSING_STOCK = "GET_DISTRIBUTORS_CLOSING_STOCK";
export const GET_CLOSING_STOCK = "GET_CLOSING_STOCK";
export const VIEW_ALL_LVAV_COMPANY = "VIEW_ALL_LVAV_COMPANY";
export const ALL_OUTOFSTOCK_PRODUCTS = "ALL_OUTOFSTOCK_PRODUCTS";
export const VIEW_ALL_DEST_PRODUCT = "VIEW_ALL_DEST_PRODUCT";
export const VIEW_ALL_ORIGIN_PRODUCT = "VIEW_ALL_ORIGIN_PRODUCT";
export const UPDATED_QUANTITY = "UPDATED_QUANTITY";
export const UPDATED_QUANTITY_DAILY_STOCK = "UPDATED_QUANTITY_DAILY_STOCK"
export const UPDATED_QUANTITY_DAILY_EMPTIES = "UPDATED_QUANTITY_DAILY_EMPTIES";
export const UNSAVED_CHANGES = "UNSAVED_CHANGES";
export const UNSAVED_PRODUCT_CHANGES = "UNSAVED_PRODUCT_CHANGES";
export const UNSAVED_CHANGES_DAILY_STOCK = "UNSAVED_CHANGES_DAILY_STOCK"
export const DISCARD_CHANGES = "DISCARD_CHANGES";
export const PRESENT_DIST = "PRESENT_DIST";
export const SAVE_TRANSFER = "SAVE_TRANSFER";
export const ADD_INVENTORY = "ADD_INVENTORY";
export const UNSAVED_EMPTIES_CHANGES = "UNSAVED_EMPTIES_CHANGES";
export const DISCARD_EMPTIES_CHANGES = "DISCARD_EMPTIES_CHANGES";
export const RECEIVE_EMPTIES = "RECEIVE_EMPTIES";
export const VIEW_ALL_EMPTIES = "VIEW_ALL_EMPTIES";
export const VIEW_ALL_ORDER_EMPTIES = "VIEW_ALL_ORDER_EMPTIES";
export const RETURN_QUANTITY = "RETURN_QUANTITY";
export const ADD_EMPTIES = "ADD_EMPTIES";
export const TOTAL_EMPTIES = "TOTAL_EMPTIES";
export const RETURN_EMPTIES_BUTTON_CLICKED = "RETURN_EMPTIES_BUTTON_CLICKED";
export const RECEIVE_EMPTIES_BUTTON_CLICKED = "RECEIVE_EMPTIES_BUTTON_CLICKED";
export const EMPTIES_MODAL_BUTTON_CLICKED = "EMPTIES_MODAL_BUTTON_CLICKED";
export const RETURN_EXPIRED_QUANTITY = "RETURN_EXPIRED_QUANTITY";
export const UNSAVED_EXPIRED_CHANGES = "UNSAVED_EXPIRED_CHANGES";
export const UPDATED_QUANTITY_SALES_START = "UPDATED_QUANTITY_SALES_START";
export const UPDATED_QUANTITY_SALES = "UPDATED_QUANTITY_SALES";
export const UPDATED_QUANTITY_SALES_FAILED = "UPDATED_QUANTITY_SALES_FAILED";
export const RELOAD_INVENTORY = "RELOAD_INVENTORY";
export const DISCARD_EXPIRED_CHANGES = "DISCARD_EXPIRED_CHANGES";
export const DISCARD_INVENTORY_DOCUMENT_DETAILS = "DISCARD_NVENTORY_DOCUMENT_DETAILS";
export const INVENTORY_DOCUMENT_DETAILS = "INVENTORY_DOCUMENT_DETAILS";
export const INVENTORY_CLEAR = "INVENTORY_CLEAR";
export const WALK_IN_SALES_EMPTIES_START = "WALK_IN_SALES_EMPTIES_START";
export const WALK_IN_SALES_EMPTIES = "WALK_IN_SALES_EMPTIES";
export const WALK_IN_SALES_EMPTIES_FAILED = "WALK_IN_SALES_EMPTIES_FAILED";
export const RETURN_SALES_START = "RETURN_SALES_START";
export const RETURN_SALES = "RETURN_SALES";
export const RETURN_SALES_FAILED = "RETURN_SALES_FAILED";
export const SELECTED_OTHERPRODUCTS = "SELECTED_OTHERPRODUCTS";
export const UNSAVED_OTHERPRODUCTS_CHANGES = "UNSAVED_OTHERPRODUCTS_CHANGES";
export const OTHER_PRODUCTS_TO_SAVE = "OTHER_PRODUCTS_TO_SAVE";
export const OTHER_PRODUCTS_TO_SAVE_START = "OTHER_PRODUCTS_TO_SAVE_START";
export const VIEW_ABI_INVENTORY_COMPANY = "VIEW_ABI_INVENTORY_COMPANY";
 export const SHOW_DAILY_STOCK_MODAL = " SHOW_DAILY_STOCK_MODAL";
export const SHOW_INVENTORY_CHECKIN_MODAL = " SHOW_INVENTORY_CHECKIN_MODAL";
export const SET_LOADING = "SET_LOADING";
export const ACCURATE_DAILY_STOCK_COUNT = "ACCURATE_DAILY_STOCK_COUNT";
export const UPDATE_DAILY_STOCK_COUNT_FAILED = "UPDATE_DAILY_STOCK_COUNT_FAILED";
export const SET_APPROVAL_MODAL = "SET_APPROVAL_MODAL";
export const ALL_OUTOFSTOCK_PRODUCTS_FAILED = "ALL_OUTOFSTOCK_PRODUCTS_FAILED";
export const VIEW_ALL_LVAV_COMPANY_FAILED = "VIEW_ALL_LVAV_COMPANY_FAILED";
export const VIEW_ALL_LVAV_COMPANY_START = "VIEW_ALL_LVAV_COMPANY_START";
export const SET_RECOMMENDED_PRODUCTS = "SET_RECOMMENDED_PRODUCTS";
