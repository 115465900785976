import { debounce } from "lodash";
import searchIcon from "../../assets/svg/Search.svg";

const Search = ({
  setSearchTerm,
  styleClass,
  placeholder,
  smallSearchBar,
  setCurrentPage,
  forwardedRef,
  product,
}) => {
  const handleSearch = debounce((inputVal) => {
    setSearchTerm(inputVal);
    setCurrentPage(1);
  }, 1000);

  return (
    <div
      className={`flex rounded ${product ? "h-12" : "lg:mr-6 h-10"} bg-gray-4 ${
        smallSearchBar ? "xl:w-[400px]" : `w-full ${styleClass}`
      }`}
    >
      <div className="flex items-center w-10">
        <img src={searchIcon} alt="search" className="ml-4" />
      </div>

      <input
        className={`DEFAULT:border-default appearance-none focus:outline-none px-3 mx-2 text-black-400 bg-gray-4 w-full ${
          smallSearchBar ? "xl:w-[400px]" : styleClass
        }`}
        placeholder={placeholder}
        id="searchProduct"
        type="text"
        name="search"
        onChange={
          setCurrentPage
            ? (e) => handleSearch(e.target.value)
            : (e) => setSearchTerm(e.target.value)
        }
        ref={forwardedRef}
      />
    </div>
  );
};

export default Search;
