import jwtDecode from "jwt-decode";
import VanSalesmanIcon from "../assets/svg/deliveryman1.svg";
import VanReplenishmentIcon from "../assets/svg/LoadVan.svg";
import ReplenishmentHistory from "../assets/svg/ReplenishmentHistory.svg";
import SalesReturnIcon from "../assets/svg/SalesReturn.svg";
import OrderIcon from "../assets/svg/Telesales.svg";
import VanTransactionsIcon from "../assets/svg/VanWarehouse.svg";
import WalkinSalesIcon from "../assets/svg/WalkInSales.svg";
import countryConfig from "./changesConfig.json";

const countryCode = localStorage.getItem("countryCode") || "Nigeria";
const token = localStorage.getItem("userData");
let country = null;

if (token) {
  const AuthData = jwtDecode(token);
  country = AuthData?.country;
}

export const quickActionsData = [
  {
    label: "Receive Stock",
    icon: countryConfig[countryCode].quickActionRSIcon,
    link: "/dashboard/add-details/",
  },
  {
    label: "Orders",
    icon: OrderIcon,
    link: "/dashboard/orders-list/",
  },
  {
    label: "Empties",
    icon: countryConfig[countryCode].quickActionREIcon,
    link: "/dashboard/return-empties/",
  },
  {
    label: "Analytics",
    icon: countryConfig[countryCode].quickActionRIcon,
    link: "/dashboard/analytics/",
  },
];

export const quickActionsInventory =
  country === "Zambia" || country === "Tanzania" || country === "Uganda"
    ? [
        {
          label: "Receive Stock",
          icon: countryConfig[countryCode].quickActionRSIcon,
          borderColor: "transparent",
          link: "/dashboard/add-details/",
        },
        {
          label: "Return/Receive Empties",
          icon: countryConfig[countryCode].quickActionREIcon,
          borderColor: "transparent",
          link: "/dashboard/return-empties/",
          onclick: true,
        },
        {
          label: "Return Products",
          icon: countryConfig[countryCode].quickActionRPIcon,
          borderColor: "transparent",
          link: "/dashboard/return-products/",
        },
        {
          label: "Non-ABI Products",
          icon: countryConfig[countryCode].quickActionNAPIcon,
          borderColor: "transparent",
          link: "/dashboard/other-products/",
        },
      ]
    : [
        {
          label: "Receive Stock",
          icon: countryConfig[countryCode].quickActionRSIcon,
          borderColor: "transparent",
          link: "/dashboard/add-details/",
        },
        {
          label: "Return/Receive Empties",
          icon: countryConfig[countryCode].quickActionREIcon,
          borderColor: "transparent",
          link: "/dashboard/return-empties/",
          onclick: true,
        },
        {
          label: "Return Products",
          icon: countryConfig[countryCode].quickActionRPIcon,
          borderColor: "transparent",
          link: "/dashboard/return-products/",
        },
      ];

export const TransactionQuickActions = (code) => {
  const quickActionsTransaction = [
    {
      label: "Orders",
      icon: OrderIcon,
      borderColor: "transparent",
      link: `/dashboard/orders-list/${code}`,
    },
    {
      label: "Walk-in-Sales",
      icon: WalkinSalesIcon,
      borderColor: "transparent",
      link: `/dashboard/walk-in-sales/${code}`,
    },
    {
      label: "Sales Return",
      icon: SalesReturnIcon,
      borderColor: "transparent",
      link: `/dashboard/sales-return/${code}`,
    },
  ];
  return quickActionsTransaction;
};

export const VanInventoryQuickActions = (code) => {
  const quickActionsVan = [
    {
      label: "Van Replenishment",
      icon: VanReplenishmentIcon,
      borderColor: "transparent",
      link: `/dashboard/van-replenishment/${code}`,
    },
    {
      label: "Van Transactions",
      icon: VanTransactionsIcon,
      borderColor: "transparent",
      link: `/dashboard/van-transactions/${code}`,
    },
    {
      label: "Van Salesmen",
      icon: VanSalesmanIcon,
      borderColor: "transparent",
      link: `/dashboard/van-sales-men/${code}`,
    },
    {
      label: "Replenishment History",
      icon: ReplenishmentHistory,
      borderColor: "transparent",
      link: `/dashboard/replenishment-history/${code}`,
    },
  ];
  return quickActionsVan;
};
