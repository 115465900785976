export const routes = {
  Dashboard: '/dashboard',
  DashboardTotalSales: '/dashboard/totalsales/:Dist_Code',
  DashboardVolumeSold: '/dashboard/volumesold/:Dist_Code',
  DashboardVolumeReceived: '/dashboard/volumereceived/:Dist_Code',
  DashboardTotalDelivery: '/dashboard/sales-by-delivery/:Dist_Code',
  DashboardWalkInSales: '/dashboard/walkinsales/:Dist_Code',
  DashboardStockReceived: '/dashboard/stockreceived/:Dist_Code',
  DashboardSingleStockReceived: '/dashboard/singlestockreceived/:Dist_Code/:docNo',
  VolumeSoldPerProduct: '/dashboard/productvolumesold/:Dist_Code/:productCode/:startRange/:stopRange',
  DashboardVanSales: '/dashboard/van-sales/:Dist_Code',
  FinanceReportTotalSales: '/dashboard/finance/:Dist_Code/totalsales',
  FinanceReportCashAtHand: '/dashboard/finance/:Dist_Code/cash-at-hand',
  FinanceAccountReceivableCustomer: '/dashboard/finance/:Dist_Code/accounts-receivable/customer/:buyerCode',
  FinanceReportAccountReceivable: '/dashboard/finance/:Dist_Code/accounts-receivable',
  FinanceReportCurrentStockValue: '/dashboard/finance/:Dist_Code/current-stock-value',
  FinanceHome: '/dashboard/finance/:Dist_Code',
  Other_Products: '/dashboard/other-products/:DistCode',
  KpoCustomers: '/dashboard/customers/:Dist_Code',
  Analytics: '/dashboard/analytics/:Dist_Code',
  VanInventory: '/dashboard/van-inventory/:distCode',
  VanTransactions: '/dashboard/van-transactions/:distCode',
  VanReplenishment: '/dashboard/van-replenishment/:distCode',
  ReplenishmentHistory: '/dashboard/replenishment-history/:distCode',
  VanSalesMen: '/dashboard/van-sales-men/:distCode',
  ManageCustomer: '/distributor/manage-customer/:sysproCode/:id/:distCode',
  KpoManageCustomer: '/dashboard/manage-customer/:distCode/:id',
  KpoOrderList: '/dashboard/orders-list/:distCode',
  KpoOrderSummary: '/dashboard/order-summary/:sellerId/:orderId/:buyerId',
  ReturnSummary: '/dashboard/sales-return-summary/:sellerId/:orderId/:buyerId',
  Reports: '/dashboard/reports/:Dist_Code',
  Notifications: '/dashboard/notifications/:distCode',
  Overview: '/dashboard/overview/:Dist_Code',
  Inventory: '/dashboard/inventory/:Dist_Code',
  InventoryAdjustment: '/dashboard/inventory-adjustment/:Dist_Code',
  Transactions: '/dashboard/transactions/:distCode',
  CaptureSales: '/dashboard/walk-in-sales/:distCode',
  InventorySetup: '/dashboard/inventory-setup/:Dist_Code',
  ReturnProducts: '/dashboard/return-products/:Dist_Code',
  AddDetails: '/dashboard/add-details/:Dist_Code',
  SalesReturn: '/dashboard/sales-return/:Dist_Code',
  AddProduct: '/dashboard/add-product/:Dist_Code',
  ReceiveEmpties: '/dashboard/receive-empties/:Dist_Code',
  ReturnEmpties: '/dashboard/return-empties/:Dist_Code',
  Logistics: '/dashboard/logistics/:distCode',
  DailyStockCount: '/dashboard/daily-stock/:Dist_Code',
  DistributorList: '/kpo-supervisor/overview/:Dist_Code/:id',
  ManageVsm: "/dashboard/vsm-summary/:distCode/:vehicleId",
  Insights: "/dashboard/insights",
  Forbidden: "/forbidden",
  PageNotFound: "*"
}
