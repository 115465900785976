export const GET_CURRENT_STOCK_BREAKDOWN = "GET_CURRENT_STOCK_VALUE_BREAKDOWN";
export const GET_SALES_BREAKDOWN = "GET_SALES_BREAKDOWN";
export const GET_SUMMARY = "GET_SUMMARY";
export const GET_SUMMARY_ALL = "GET_SUMMARY_ALL";
export const GET_STOCK_VALUE = "GET_STOCK_VALUE";
export const GET_ACCOUNT_RECEIVABLE = "GET_ACCOUNT_RECEIVABLE";
export const GET_ACCOUNT_RECEIVABLE_CUSTOMER =
  "GET_ACCOUNT_RECEIVABLE_CUSTOMER";
export const GET_PAYMENT_HISTORY_DETAILS = "GET_PAYMENT_HISTORY_DETAILS ";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const GET_PAYMENT_DETAILS = "GET_PAYMENT_DETAILS";
export const SET_PAYMENT_HISTORY_DATE_RANGE = "SET_PAYMENT_HISTORY_DATE_RANGE";
export const SET_OUTSTANDING_INVOICE_DATE_RANGE =
  "SET_OUTSTANDING_INVOICE_DATE_RANGE";
export const SET_ACCOUNT_RECEIVABLE_DATE_RANGE =
  "SET_ACCOUNT_RECEIVABLE_DATE_RANGE";
export const SET_TOTAL_SALES_DATE_RANGE = "SET_TOTAL_SALES_DATE_RANGE";
export const SET_CASH_AT_HAND_DATE_RANGE = "SET_CASH_AT_HAND_DATE_RANGE";
export const SET_FINANCE_HOME_DATE_RANGE = "SET_FINANCE_HOME_DATE_RANGE";
