import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fade } from "react-awesome-reveal";
import { connect, useDispatch, useSelector } from "react-redux";
import { CloseModal, Checked } from "../../assets/svg/modalIcons";
import Loading from "./Loading";
import {
  getCatalogueBySellerId,
  updateCataloguePrice,
  discardChanges,
} from "../../modules/Transaction/actions/productCatalogue";
import urls from "../../utils/urls";
import axiosInstance from "../../utils/axios";
import { t } from "i18next";

const { productCatolgueNet } = urls;

const UnsavedVanTransferChanges = (sellersCatalogue) => {
  const [approvalModal, setApprovalModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [save, setSave] = useState("Update Price");
  const [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const country = useSelector((state) => state.Auth.sessionUserData).country;
  const catalogueChanges = useSelector(
    (state) => state.ProductCatalogueReducer.allCatalogue
  );

  const dist_code = useSelector((state) => state.VanInventoryReducer.distCode);

  useEffect(() => {
    if (country === "Zambia" && dist_code) {
      getCatalogueBySellerId(dist_code);
    }
  }, [dist_code, country]);

  const handleReset = () => {
    setApprovalModal(false);
  };

  const getProductCatalogueId = (productId) => {
    const catalogue = sellersCatalogue.sellersCatalogue.data.filter(
      (product) => product.productId === productId
    );
    if (catalogue.length === 0) {
      return false;
    }
    return catalogue[0].productCatalogueId;
  };

  const updateDB = async () => {
    let toBeCreatedArray = [];
    let toBeUpdatedArray = [];

    catalogueChanges?.forEach((item) => {
      const productCatalogueId = getProductCatalogueId(item.productId);

      if (!productCatalogueId) {
        const obj = {
          productId: item.productId,
          sellerCompanyId: item.sellerCompanyId,
        };
        if (item.price) {
          obj.price = item.price;
        }
        if (item.vat) {
          obj.vat = item.vat;
        }
        toBeCreatedArray.push(obj);
      } else {
        const obj2 = {
          productCatalogueId: productCatalogueId,
        };
        if (item.price) {
          obj2.price = item.price;
        }
        if (item.vat) {
          obj2.vat = item.vat;
        }
        toBeUpdatedArray.push(obj2);
      }
    });

    const toBeCreatedValues = {
      data: toBeCreatedArray,
    };

    const toBeUpdatedValues = {
      data: toBeUpdatedArray,
    };

    setSave("Updating...");
    setLoading(true);

    axiosInstance
      .post(`${productCatolgueNet}CreateProductCatalogue`, toBeCreatedValues)
      .then((response) => {
        if (toBeUpdatedArray.length === 0) {
          setApprovalModal(false);
          setSuccessModal(true);
          setSave("Change Price");
          setLoading(false);
          dispatch(updateCataloguePrice(false));
          if (country === "Zambia") {
            dispatch(getCatalogueBySellerId(dist_code));
          }
        } else {
          axiosInstance
            .patch(
              `${productCatolgueNet}EditProductCatalogue/UpdatePrice`,
              toBeUpdatedValues
            )
            .then((response) => {
              setApprovalModal(false);
              setSuccessModal(true);
              setSave("Change Price");
              setLoading(false);
              dispatch(updateCataloguePrice(false));
              if (country === "Zambia") {
                dispatch(getCatalogueBySellerId(dist_code));
              }
            });
        }

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {});
  };

  return (
    <>
      <Fade>
        <div className="bg-gold justify-between flex w-full px-4 lg:pr-8 py-3 shadow-[0px_4px_16px_rgba(89, 85, 84, 0.15)] h-20">
          <div className="w-[100px]"></div>
          <div className="font-customGilroy italic font-bold text-gray-100 text-xl py-3">
            {t("you_have_unsaved_changes_Do_you_want_to_save_your_changes?")}
          </div>
          <div className="inline-flex justify-between">
            <div
              className="bg-transparent text-white text-base text-center mr-6 cursor-pointer font-semibold border border-white rounded p-3"
              onClick={() => {
                dispatch(discardChanges());
                window.location.reload();
              }}
              onKeyDown={() => {
                dispatch(discardChanges());
                window.location.reload();
              }}
              role="button"
            >
              {t("discard_changes")}
            </div>
            <div
              className="bg-dark rounded p-3 text-gold text-base font-semibold text-center w-[5.5rem] cursor-pointer"
              onClick={() => {
                setApprovalModal(true);
              }}
              onKeyDown={() => {
                setApprovalModal(true);
              }}
              role="button"
            >
              {t("save")}
            </div>
          </div>
        </div>
      </Fade>

      <Transition.Root show={approvalModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setApprovalModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-mini-modal">
                <CloseModal
                  className="ml-auto m-4 mb-0"
                  onClick={handleReset}
                />
                <div className="h-mini-modal flex justify-center items-center">
                  <p className="font-customGilroy not-italic text-base font-medium px-4">
                    {t("Are you sure you want to update these prices")}
                  </p>
                </div>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className="bg-gold flex rounded font-customGilroy text-grey-100 text-center text-sm font-bold not-italic px-14 py-2"
                    onClick={() => updateDB()}
                  >
                    {save}
                    {loading ? <Loading /> : ""}
                  </button>

                  <button
                    className="bg-white border border-solid rounded font-customGilroy text-grey-70 text-center text-sm font-bold not-italic px-7 py-2"
                    onClick={handleReset}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={successModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setSuccessModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                <button
                  className="flex justify-center ml-auto m-4 mb-0"
                  onClick={() => setSuccessModal(false)}
                >
                  <CloseModal />
                </button>
                <div className="flex flex-col justify-center items-center w-modal h-sub-modal -mt-12">
                  <Checked />
                  <p className="font-customGilroy not-italic font-medium text-xl text-center text-grey-85 mt-4">
                    {t("Your changes have been saved, and the prices updated")}
                  </p>
                </div>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className="bg-gold rounded font-customGilroy text-grey-100 text-center text-sm font-bold not-italic px-14 py-2"
                    onClick={() => setSuccessModal(false)}
                  >
                    {t("okay")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sellersCatalogue: state.ProductCatalogueReducer.allSellersCatalogue,
  };
};

export default connect(mapStateToProps, {
  getCatalogueBySellerId,
})(UnsavedVanTransferChanges);
