
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageNotFoundImg from "../../../../assets/images/404.png";
import { checkRole } from "../../../../callBack";
import Button from '../../../../components/common/Button';

const Main = () => {
  const { t } = useTranslation();
  const history = useHistory();
	const AuthData = useSelector((state) => state.Auth.sessionUserData);

  return (
    <div className="w-full h-full px-3 flex flex-col justify-center items-center pb-14">
      <img src={PageNotFoundImg} alt='page not found' className='mb-10' />
      <p
        className="text-black-400 font-normal font-customGilroy text-active pt-3 pb-2 text-center text-lg"
      >
        {t('page_does_not_exist')}
      </p>
      <Button
        btnText={t('go_to_homepage')}
        onClick={() => checkRole(AuthData, history)}
      />
    </div>
  )
}

export default Main;