import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { CloseModal, Checked, Error } from "../../assets/svg/modalIcons";
import Loading from "./Loading";
import {
  discardChanges,
  getAllInventory,
  updateDailysTOCKTransferQuantity,
  updateAccurateDailyStockCount,
} from "../../modules/Inventory/actions/inventoryProductAction";
import { useHistory } from "react-router-dom";

const UnsavedDailyStock = ({ Dist_Code }) => {
  const [approvalModal, setApprovalModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [save, setSave] = useState("Yes, save");
  const [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  let AuthData = useSelector((state) => state.Auth.sessionUserData);
  const [prevStock, setPrevStock] = useState([]);
  const country = useSelector((state) => state.Auth.sessionUserData).country;

  const roles = AuthData.roles;
  const transferQuantity = useSelector(
    (state) => state.InventoryReducer.transfer_quantities_daily_stock
  );

  const allInventory = useSelector(
    (state) => state.InventoryReducer.all_inventory
  );

  const stock_count_updated = useSelector(
    (state) => state.InventoryReducer.stock_count_updated
  );

  const error = useSelector((state) => state.InventoryReducer.error);
  const errorMessage = useSelector(
    (state) => state.InventoryReducer.error_message
  );

  let dist_code = useSelector((state) => state.DistReducer.dist_code);
  if (!dist_code) {
    dist_code = Dist_Code;
  }

  useEffect(() => {
    allInventory.forEach((value) => {
      setPrevStock((prevStock) => [
        ...prevStock,
        {
          productId: value.productId,
          quantity: value.quantity,
        },
      ]);
    });
  }, [allInventory]);

  useEffect(() => {
    if (stock_count_updated) {
      setApprovalModal(false);
      setLoading(false);
      if (error) {
        setErrorModal(true);
      } else {
        setSuccessModal(true);
        setSave("Yes, save");
        dispatch(updateDailysTOCKTransferQuantity(false));
        dispatch(getAllInventory(dist_code));
        setTimeout(() => {
          history.push("/dashboard/inventory/" + dist_code);
        }, 2000);
      }
    }
  }, [stock_count_updated, dispatch, dist_code, error, history]);

  const handleReset = () => {
    setApprovalModal(false);
  };

  const updateDB = () => {
    setLoading(true);
    setSave("Saving");

    let processedArray = [];
    prevStock?.forEach((item) => {
      const value = transferQuantity.stockCounted?.filter(
        (val) => val.productId === item.productId
      )[0];
      value
        ? processedArray.push({
            productId: parseInt(value.productId),
            quantity: parseInt(value.quantity),
            empties: parseInt(value.empties),
            reason: !value.reason ? [] : value.reason,
          })
        : processedArray.push({
            productId: parseInt(item.productId),
            quantity: parseInt(item.quantity),
            empties: !item.empties ? 0 : parseInt(item.empties),
            reason: [],
          });
    });

    const toDB = {
      companyCode: dist_code,
      country: country === "South Africa" ? "SA" : country,
      stocks: processedArray,
      accurate: false,
    };

    dispatch(updateAccurateDailyStockCount(toDB));
  };

  const clearError = () => {
    setErrorModal(false);
    dispatch(updateDailysTOCKTransferQuantity(false));
    dispatch(getAllInventory(dist_code));
    setTimeout(() => {
      history.push("/dashboard/inventory/" + dist_code);
    }, 1000);
  };

  return (
    <>
      <Fade>
        <div className="bg-gold shadow-xl lg:min-h-[20px] py-3 px-4 lg:pr-8 shadow-[0px_4px_16px_rgba(89, 85, 84, 0.15)] flex flex-col lg:flex-row items-end lg:items-center justify-between w-full">
          <div className="lg:w-24"></div>
          <div className="font-customGilroy italic text-xl font-bold text-gray-100 py-3">
            You have unsaved changes. Do you want to save your changes?
          </div>
          <div className="inline-flex justify-between">
            <div
              className="bg-transparent border border-white mr-6 text-base	text-white text-center rounded p-3 font-semibold cursor-pointer"
              onClick={() => {
                dispatch(discardChanges());
                window.location.reload();
              }}
              onKeyDown={() => {
                dispatch(discardChanges());
                window.location.reload();
              }}
              role="button"
            >
              Discard changes
            </div>
            {roles === "Mini-Admin" ? (
              <div className="bg-[#BEBEBE] rounded p-3 text-black text-base w-[5.5rem] text-center font-semibold cursor-pointer">
                Save
              </div>
            ) : (
              <div
                className="bg-dark rounded p-3 font-semibold text-gold text-base text-center w-[5.5rem] cursor-pointer"
                onClick={() => {
                  setApprovalModal(true);
                }}
                onKeyDown={() => {
                  setApprovalModal(true);
                }}
                role="button"
              >
                Save
              </div>
            )}
          </div>
        </div>
      </Fade>

      <Transition.Root show={approvalModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setApprovalModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-mini-modal">
                <CloseModal
                  className="ml-auto m-4 mb-0"
                  onClick={handleReset}
                />
                <div className="h-mini-modal flex justify-center items-center">
                  <p className="font-customGilroy not-italic text-base font-medium px-4">
                    Save today's Stock count?
                  </p>
                </div>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className="bg-gold rounded font-customGilroy flex text-gray-100 text-center text-sm font-bold not-italic px-14 py-2"
                    onClick={() => updateDB()}
                  >
                    {save}
                    {loading ? <Loading /> : ""}
                  </button>

                  <button
                    className="bg-white border border-solid rounded font-customGilroy text-grey-70 text-center text-sm font-bold not-italic px-7 py-2"
                    onClick={handleReset}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={successModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setSuccessModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                <button
                  className="flex justify-center ml-auto m-4 mb-0"
                  onClick={() => setSuccessModal(false)}
                >
                  <CloseModal />
                </button>
                <div className="flex flex-col justify-center items-center w-modal h-sub-modal -mt-12">
                  <Checked />
                  <p className="font-customGilroy not-italic font-medium text-xl text-center text-grey-85 mt-4">
                    Your inventory has been updated
                  </p>
                </div>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className="bg-gold rounded font-customGilroy text-gray-100 text-center text-sm font-bold not-italic px-14 py-2"
                    onClick={() => {
                      setSuccessModal(false);
                      history.push("/dashboard/inventory/" + dist_code);
                    }}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={errorModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setErrorModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-mini-modal">
                <button
                  className="flex justify-center ml-auto m-4 mb-0"
                  onClick={clearError}
                >
                  <CloseModal />
                </button>
                <div className=" flex justify-center items-center flex-col	">
                  <Error width={50} height={50} />
                  <p className="font-customGilroy not-italic font-medium text-xl text-center text-grey-85 mt-4">
                    {error ? errorMessage : "Network error. Please try again."}
                  </p>
                </div>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className="bg-white border border-solid rounded font-customGilroy text-grey-70 text-center text-sm font-bold not-italic px-7 py-2"
                    onClick={clearError}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default UnsavedDailyStock;
