import axios from "axios";
const { REACT_APP_LOGISTICS_URL, REACT_APP_LOGISTICS_API_KEY } = process.env;

export const logisticsNet = () => {
  const token = localStorage.getItem("userData");
  return axios.create({
    baseURL: `${REACT_APP_LOGISTICS_URL}/v1/trips/ongoing?apiKey=${REACT_APP_LOGISTICS_API_KEY}&`,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const urls = {
  customerNet: "/customer/",
  customerNetBase: "/customer/customer",
  completedOrdersNet: "/order/GetOrder/GetCompletedOrderBySellerCompanyId/",
  createOrderNet: "/order/CreateOrder",
  distributorNet: "/company/",
  productCatolgueNet: "/productcatalogue/",
  productNet: "/product/api/v1/products/",
  orderNet: "/order/",
  inventory: "/inventory/api/v1/",
  inventoryNet: "/inventory/api/v1/inventory/",
  userNet: "/user/",
  overallInventoryNet: "/inventory/api/v1/inventory/overall/",
  vehicleNet: "/vehicle/",
  financeNet: "/finance/",
  logisticsNet,
  reportNet: "/report/",
};

export default urls;
