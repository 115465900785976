import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDistributor } from "../Admin/pages/actions/adminDistributorAction";
import { filter } from "lodash";

export const GetDistributors = () => {
  const AuthData = useSelector((state) => state.Auth.sessionUserData);
  const country = AuthData?.country;
  const [distCodes, setDistCodes] = useState([]);
  const dispatch = useDispatch();

  const allDistributors = useSelector(
    (state) => state.AllDistributorReducer.all_distributors
  );

  const getDataCallback = useCallback(() => {
    dispatch(getAllDistributor(country));
  }, [country, dispatch])
  
  useEffect(() => {
    getDataCallback()
  }, [getDataCallback]);

  useEffect(() => {
    Object.keys(AuthData).length > 0 &&
      setDistCodes(JSON.parse(AuthData?.DIST_Code));
  }, [AuthData]);

  let newArray = [];
  allDistributors.length > 0 &&
    distCodes &&
    distCodes.forEach((code) => {
      const thisDist = filter(allDistributors, { DIST_Code: code })[0];
      newArray.push(thisDist);
    });

  return newArray;
};
