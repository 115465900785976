import React from "react";

const AuthSidebar = () => {
  const date = new Date();
  let year = date.getFullYear();

  return (
    <div className="flex justify-between h-full p-3 bg-black md:p-0 md:flex-col">
      <div className="flex-shrink-0">
        <img className="p-[5%]" src="/assets/svg/kuja-logo2.svg" alt="" />
      </div>
      <div className="hidden md:block">
        <img src="/assets/svg/background-pic.svg" alt="" className="w-full" />
      </div>
      <div className="self-end mb-4 md:self-center">
        <div>
          <center>
            <span className="text-white">©{year}</span>
          </center>
          <img src="/assets/svg/ABI-Logo.svg" alt="logo" className="mx-auto" />
        </div>
      </div>
    </div>
  );
};

export default AuthSidebar;
