import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

export default function AuthRoute({ component: Component, ...rest }) {
  const { isAuthenticated, sessionUser } = useSelector((state) => state.Auth);
  let role = sessionUser?.user?.role; // or manager

  if (isAuthenticated && role) {
    if (role === "Admin") return <Redirect to="/admin-dashboard" />;
    else if (role === "manager") return <Redirect to="/manager/my-team" />;
    else if (role === "KPO") return <Redirect to="/kpo/overview" />;
    
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}
