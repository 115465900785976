import {
  ADD_DISTRIBUTOR,
  EDIT_DISTRIBUTOR,
  VIEW_ALL_DISTRIBUTOR,
  VIEW_SINGLE_DISTRIBUTOR,
  VIEW_SINGLE_KPO,
  VIEW_MY_DISTRIBUTORS,
  UPDATE_DISTRIBUTOR
} from "../actions/types";

const initial_state = {
  distributor: {},
  all_distributors: [],
  my_distributors: [],
  kpo: {},
  updateSuccess: {
    error: null,
    update: false
  }
};

const addDistributorReducer = (state = initial_state, action) => {
  switch (action.type) {
    case ADD_DISTRIBUTOR:
      return {
        ...state,
        all_distributors: [...state.all_distributors, action.payload],
      };
    case VIEW_ALL_DISTRIBUTOR: {
      return { ...state, all_distributors: action.all_distributors };
    }
    case EDIT_DISTRIBUTOR: {
      return { ...state, distributor: action.payload };
    }
    case VIEW_SINGLE_KPO: {
      return { ...state, kpo: action.payload };
    }
    case VIEW_MY_DISTRIBUTORS: {
      return { ...state, my_distributors: action.payload };
    }
    case VIEW_SINGLE_DISTRIBUTOR: {
      return { ...state, distributor: action.payload };
    }
    case UPDATE_DISTRIBUTOR: {
      return { ...state, updateSuccess: {error: action.error, update: action.update}};
    }
    default:
      return state;
  }
};

export default addDistributorReducer;
