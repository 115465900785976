import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeAlert } from "../../redux/actions/common/alert.action";

export function ToastNotifications() {
  const alerts = useSelector((state) => state.Alert);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (alerts && alerts.length) {
      alerts.forEach((alert) => {
        toast(
          <div>
            <strong>{alert.title}</strong>
            <div>{alert.body}</div>
          </div>,
          {
            type: alert.type,
            autoDismiss: true,
            autoDismissTimeout: alert.timeout,
            id: alert.id,
          }
        );
        setTimeout(() => {
          dispatch(removeAlert(alert.id));
        }, alert.timeout);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts]);
  return null;
}
