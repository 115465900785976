import { useEffect } from "react";
import { useDispatch } from "react-redux";

export function useCheckOutsideClick(ref, setIsVisible, type) {
  const dispatch = useDispatch();

  const checkIfClickedOutside = (e) => {
    if (type === "product") {
      return ref && !ref?.current?.contains(e.target)
        ? ref?.current?.children[1]?.classList?.add("hidden")
        : ref?.current?.children[1]?.classList?.remove("hidden");
    }
    if (type === "driver") {
      return ref && !ref?.current?.contains(e.target)
        ? ref?.current?.children[1]?.classList?.add("hidden")
        : ref?.current?.children[1]?.classList?.remove("hidden");
    }
    if (type === "calendar") {
      return ref && !ref?.current?.contains(e.target)
        ? dispatch(setIsVisible(false))
        : null;
    } else {
      return ref && !ref?.current?.contains(e.target)
        ? setIsVisible(false)
        : null;
    }
  };
  useEffect(() => {
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  });
}
