import { ThreeDots } from "react-loader-spinner";

export default function Loading() {
  return (
    <ThreeDots
      visible={true}
      height={50}
      width={50}
      color="#b11f24"
      radius="9"
      ariaLabel="three-dots-loading"
      wrapperClass="!block"
    />
  );
}
