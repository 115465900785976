import {
	SET_ACTIVE_TAB,
	UPLOAD_PRODUCT_IMAGE,
	GET_ALL_PRODUCTS,
	LOADING_PRODUCTS,
	GET_ALL_DRAFTS,
	DELETE_SUCCESSFUL,
	DELETE_DRAFT_SUCCESSFUL,
	PRODUCT_UPDATE_SUCCESSFUL,
	PRODUCT_UPDATE_SUCCESSFUL_RESET,
	GET_PRODUCT_BY_ID,
	SET_ACTIVE_FILTER,
	GET_DRAFT_BY_ID,
	SET_PRODUCT_SUCCESSFUL,
  SET_DRAFT_PRODUCT_SUCCESSFUL,
	SET_UPLOAD_BULK_PRODUCT,
} from "../actions/types";

const initial_state = {
	activeTab: 0,
	filterStatus: "published",
	imageUrl : null,
	products: [],
	pageCount: 0,
	loading:false,
	drafts:[],
	isDraftDeleteSuccessful: false,
	isDeleteSuccessful: false,
	isUpdateSuccessful: false,
	product: {},
	draft:{},
	isPublishSuccessful: false,
	isSaveSuccessful: false,
	uploadImage: false,
	uploadProductSuccess: false
};

const ProductReducer = (state = initial_state, action) => {
  switch (action.type) {
		case GET_ALL_PRODUCTS: {
			return {
				...state,
				products: action.products,
				pageCount: action.pageCount,
			};
		}
		case LOADING_PRODUCTS: {
			return {
				...state,
				loading: action.loading,
			};
		}
		case SET_ACTIVE_TAB: {
			return {
				...state,
				activeTab: action.activeTab,
			};
		}
		case UPLOAD_PRODUCT_IMAGE: {
			return {
				...state,
				imageUrl: action.imageUrl,
				uploadImage: action.uploadImage
			};
		}
		case GET_ALL_DRAFTS: {
			return {
				...state,
				drafts: action.drafts,
				pageCount: action.pageCount,
			};
		}
		case DELETE_DRAFT_SUCCESSFUL: {
			return {
				...state,
				isDraftDeleteSuccessful: action.isDraftDeleteSuccessful,
			};
		}
		case DELETE_SUCCESSFUL: {
			return {
				...state,
				isDeleteSuccessful: action.isDeleteSuccessful
			};
		}
		case PRODUCT_UPDATE_SUCCESSFUL: {
			return {
				...state,
				isUpdateSuccessful: true,
			};
		}
		case PRODUCT_UPDATE_SUCCESSFUL_RESET: {
			return {
				...state,
				isUpdateSuccessful: false,
			};
		}
		case GET_PRODUCT_BY_ID: {
			return {
				...state,
				product: action.product,
			};
		}
		case SET_ACTIVE_FILTER: {
			return {
				...state,
				filterStatus: action.filterStatus,
			};
		}
		case GET_DRAFT_BY_ID: {
			return {
				...state,
				draft: action.draft,
			}
		}
		case SET_PRODUCT_SUCCESSFUL: {
			return {
				...state,
				isPublishSuccessful: action.isPublishSuccessful,
			};
		}
		case SET_DRAFT_PRODUCT_SUCCESSFUL: {
			return {
				...state,
				isSaveSuccessful: action.isSaveSuccessful,
			};
		}
		case SET_UPLOAD_BULK_PRODUCT: {
			return {
				...state,
				uploadProductSuccess: action.uploadProductSuccess,
			};
		}
		default:
			return state;
	}
};
export default ProductReducer;
