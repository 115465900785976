export const ADD_CUSTOMERS = "ADD_CUSTOMERS";
export const DELETE_CUSTOMERS = "DELETE_CUSTOMERS";
export const EDIT_CUSTOMERS = "EDIT_CUSTOMERS";
export const VIEW_ALL_DISTRIBUTOR_CUSTOMERS = "VIEW_ALL_DISTRIBUTOR_CUSTOMERS";
export const VIEW_SINGLE_DISTRIBUTOR_CUSTOMER = "VIEW_SINGLE_DISTRIBUTOR_CUSTOMER";
export const VIEW_ALL_DISTRIBUTOR_CUSTOMER_ORDERS = "VIEW_ALL_DISTRIBUTOR_CUSTOMER_ORDERS";
export const VIEW_SINGLE_DISTRIBUTOR_CUSTOMER_ORDER = "VIEW_SINGLE_DISTRIBUTOR_CUSTOMER_ORDER";
export const VIEW_MINI_ADMIN_DISTRIBUTOR_CUSTOMERS = "VIEW_MINI_ADMIN_DISTRIBUTOR_CUSTOMERS";
export const ALL_ONE_OFF_CUSTOMERS_START = "ALL_ONE_OFF_CUSTOMERS_START";
export const ALL_ONE_OFF_CUSTOMERS = "ALL_ONE_OFF_CUSTOMERS";
export const ALL_ONE_OFF_CUSTOMERS_FAILED = "ALL_ONE_OFF_CUSTOMERS_FAILED";
export const ADD_ONE_OFF_CUSTOMERS_START = "ADD_ONE_OFF_CUSTOMERS_START";
export const ADD_ONE_OFF_CUSTOMERS = "ADD_ONE_OFF_CUSTOMERS";
export const ADD_ONE_OFF_CUSTOMERS_FAILED = "ADD_ONE_OFF_CUSTOMERS_FAILED";
export const CLEAR_ONE_OFF_CUSTOMERS = "CLEAR_ONE_OFF_CUSTOMERS";
export const UPDATE_CUSTOMER_STATUS = "UPDATE_CUSTOMER_STATUS"
export const CURRENT_CUSTOMER = "CURRENT_CUSTOMER";
export const COUNTRY_CUSTOMERS = "COUNTRY_CUSTOMERS";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const SET_LOADING = "SET_LOADING";
