import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { CloseModal, Checked } from "../../assets/svg/modalIcons";
import Loading from "./Loading";
import {
  discardExpiredChanges,
  getAllInventory,
  updateExpiredQuantity,
} from "../../modules/Inventory/actions/inventoryProductAction";
import { useHistory } from "react-router-dom";
import urls from "../../utils/urls";
import axiosInstance from "../../utils/axios";
import { useTranslation } from "react-i18next";

const { inventoryNet } = urls;

const UnsavedExpiryChanges = ({ Dist_Code }) => {
  const { t } = useTranslation();
  const [truckNo, setTruckNo] = useState("");
  const [approvalModal, setApprovalModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [save, setSave] = useState(t("yes_return"));
  const [loading, setLoading] = useState(false);
  const [truckNoModal, setTruckNoModal] = useState(false);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const returnExpiredQuantity = useSelector(
    (state) => state.InventoryReducer.transfer_quantities
  );

  let dist_code = useSelector((state) => state.DistReducer.dist_code);
  if (!dist_code) {
    dist_code = Dist_Code;
  }

  const handleReset = () => {
    setTruckNoModal(false);
    setApprovalModal(false);
  };
  const updateDB = () => {
    let processedArray = returnExpiredQuantity?.map((item) => ({
      productId: parseInt(item.productId),
      quantityToReturn: parseInt(item.quantity),
      reason: item.reason,
    }));

    let toDB = {};
    toDB["truckNo"] = truckNo;
    toDB["companyCode"] = dist_code;

    toDB["data"] = processedArray;

    setSave("Updating ");
    setLoading(true);

    axiosInstance
      .post(`${inventoryNet}return-product/`, toDB)
      .then((response) => {
        setApprovalModal(false);
        setSuccessModal(true);
        setSave("Yes, return");
        setLoading(false);
        dispatch(updateExpiredQuantity(false));
        dispatch(getAllInventory(dist_code));
        setTimeout(() => {
          history.push("/dashboard/inventory/" + dist_code);
        }, 2000);
      });

  };

  return (
    <>
      <Fade>
        <div className="bg-gold shadow-xl lg:h-24 pl-3 lg:pr-8 py-3 w-full flex justify-between shadow-[0px_4px_16px_rgba(89, 85, 84, 0.15)]">
          <div className="w-10 md:w-24"></div>
          <div className="w-full flex justify-between items-center flex-col lg:flex-row">
            <div className="font-customGilroy lg:w-8/12 italic font-bold text-xl text-gray-100 py-3">
              {t("you_have_unsaved_changes_Do_you_want_to_save_your_changes?")}
            </div>
            <div className="inline-flex justify-between">
              <div
                className="bg-transparent text-white border mr-6 border-white rounded p-3 text-center text-base font-semibold cursor-pointer"
                onClick={() => {
                  dispatch(discardExpiredChanges(false));
                }}
                onKeyDown={() => dispatch(discardExpiredChanges(false))}
                role="button"
              >
                {t("discard_changes")}
              </div>
              <div
                className="bg-dark text-gold text-center text-base font-semibold rounded w-[5.5rem] p-3 cursor-pointer"
                onClick={() => setTruckNoModal(true)}
                onKeyDown={() => setTruckNoModal(true)}
                role="button"
              >
                {t("save")}
              </div>
            </div>
          </div>
        </div>
      </Fade>

      <Transition.Root show={truckNoModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setTruckNoModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:mt-8 sm:align-middle w-modal">
                <CloseModal
                  className="ml-auto m-4 mb-0"
                  onClick={handleReset}
                />
                <p className="font-customGilroy not-italic text-base text-center font-medium my-6">
                  {t("enter_the_plate_number_of_truck")}
                </p>
                <div className="text-center">
                  <input
                    className="bg-[#F2F2F2] rounded border mt-4 border-solid border-grey-25 font-customGilroy text-base font-medium not-italic py-3 mb-10 w-52 mx-auto text-center"
                    placeholder={t("enter_here")}
                    value={truckNo}
                    onChange={(e) => setTruckNo(e.target.value)}
                  />
                </div>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className={`bg-gold rounded font-customGilroy flex opacity-${
                      truckNo === "" ? "50" : "100"
                    } text-gray-100 text-center text-sm font-bold not-italic px-14 py-2`}
                    onClick={() => {
                      setTruckNoModal(false);
                      setApprovalModal(true);
                    }}
                    disabled={truckNo === "" ? true : false}
                  >
                    {t("continue")}
                  </button>

                  <button
                    className="bg-white border border-solid rounded font-customGilroy text-grey-70 text-center text-sm font-bold not-italic px-7 py-2"
                    onClick={handleReset}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={approvalModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setApprovalModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:mt-8 sm:align-middle w-modal">
                <CloseModal
                  className="ml-auto m-4 mb-0"
                  onClick={handleReset}
                />
                <p className="font-customGilroy not-italic text-base font-medium px-4 mt-7 mb-16 text-center">
                  {t("are_you_sure_you_want_to_return_your_selection(s)?")}
                </p>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className={`bg-gold flex rounded font-customGilroy opacity-${
                      truckNo === "" ? "50" : "100"
                    } text-gray-100 text-center text-sm font-bold not-italic px-14 py-2`}
                    onClick={() => updateDB()}
                    disabled={truckNo === "" ? true : false}
                  >
                    {save}
                    {loading ? <Loading /> : ""}
                  </button>

                  <button
                    className="bg-white border border-solid rounded font-customGilroy text-grey-70 text-center text-sm font-bold not-italic px-7 py-2"
                    onClick={handleReset}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={successModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setSuccessModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                <button
                  className="flex justify-center ml-auto m-4 mb-0"
                  onClick={() => setSuccessModal(false)}
                >
                  <CloseModal />
                </button>
                <div className="flex flex-col justify-center items-center w-modal h-sub-modal -mt-12">
                  <Checked />
                  <p className="font-customGilroy not-italic font-medium text-xl text-center text-grey-85 mt-4">
                    {t("your_inventory_has_been_updated")}
                  </p>
                </div>
                <div className="border border-solid bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse gap-4 flex-wrap">
                  <button
                    className="bg-gold rounded font-customGilroy text-gray-100 text-center text-sm font-bold not-italic px-14 py-2"
                    onClick={() => setSuccessModal(false)}
                  >
                    {t("okay")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default UnsavedExpiryChanges;
