/** Define an object countryCodes
 * where each key is a country name
 * and the corresponding value is its code.
 * */
const countryCodes = {
  Nigeria: "NG",
  Uganda: "UG",
  Tanzania: "TZ",
  Ghana: "GH",
  Mozambique: "MZ",
  Zambia: "ZM",
  "South Africa": "ZA",
};

/**
 * Description: This approach replaces the switch statement with an object lookup for better performance,
 * especially when dealing with many countries.
 * @param {any} country
 * @returns {any}
 */
export const countryCode = (country) => {
  return countryCodes[country] || ""; // Return empty string for missing countries
};
