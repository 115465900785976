import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { fetchToken, onMessageListener } from './Firebase';
import { addPushNotification } from './actions/PushNotificationAction';
import { useDispatch, useSelector } from 'react-redux';

function PushNotification({distCode,country}) {
  const dispatch = useDispatch();
  const notificationStore = useSelector(state => state.PnReducer.notification);
  const [notification, setNotification] = useState(notificationStore);
  const [show, setShow] = useState(Object.keys(notification).length ? true : false);
  const [notificationTime, setNotificationTime] = useState(null);

  useEffect(() => {
    fetchToken(distCode, country);
  }, [distCode, country]);

  onMessageListener().then(payload => {
    dispatch(addPushNotification({
      body: payload.notification.body,
      image: payload.data.landscapeImageUrl,
      title: payload.notification.title,
    }));
    setNotification({
      body: payload.notification.body,
      image: payload.data.landscapeImageUrl,
      title: payload.notification.title,
    });
    setNotificationTime(new Date());
    setShow(true);
  }).catch(err => console.log('failed: ', err));

  const resetNotification = () => {
    dispatch(addPushNotification({}));
    setShow(false);
  }

  return (
    <div>
      {
        show &&
        <Link onClick={resetNotification} className='absolute top-3 right-4 lg:right-10 min-w-[200px] max-w-[280px] bg-white z-20 push-notification rounded-lg' to={`/dashboard/insights`}>
          <div className='toastHeader bg-gold text-black px-3 py-2'>
            <strong className="mr-auto">{notification.title}</strong>
            <div>
              <small>{notificationTime && formatTimeAgo(notificationTime)}</small>
            </div>
          </div>
            <div className='px-3 pt-4 pb-5'>
            <img src={notification.image} className='w-auto toastImage mx-auto mt-1 object-contain mb-2' alt='' />
            <p className='font-medium text-sm text-center'>{notification.body}</p>
          </div>
        </Link>
      }
    </div>
  );
}

function formatTimeAgo(time) {
  const now = new Date();
  const diff = Math.floor((now - time) / 60000);
  if (diff < 1) {
    return 'just now';
  } else if (diff === 1) {
    return '1 minute ago';
  } else {
    return `${diff} minutes ago`;
  }
}

export default PushNotification;