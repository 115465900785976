import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import profileImg from "../../assets/images/profileIcon.png";
import signOut from "../../assets/images/signOut.png";
import { logoutUser } from "../../modules/Auth/actions/auth.action";
import { useComponentVisible } from "../../utils/hooks";
import userManager from "../../utils/userManager";

export const ProfileDropdown = ({ position, setIsVisible }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const dispatch = useDispatch();
  useComponentVisible(ref, setIsVisible);
  const handleLogout = () => {
    userManager.signoutRedirect();
    dispatch(logoutUser());
    localStorage.clear();
  };
  const AuthData = useSelector((state) => state.Auth.sessionUserData);

  return (
    <div
      className={`origin-top-right absolute ${position} rounded-md z-[1000000] w-80 h-40 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
      ref={ref}
    >
      <div className="py-1" role="none">
        <div className="flex flex-grow">
          <img className="w-10 h-10 m-4" alt="" src={profileImg} />
          <div className="relative">
            <p
              className="block px-2 py-2 text-base font-medium outline-none text-active"
              tabIndex="-1"
              id="menu-item-0"
            >
              {AuthData?.firstname} {AuthData?.lastname}
            </p>
            <p
              className="px-2 text-sm font-normal outline-none text-default"
              tabIndex="-1"
              id="menu-item-0"
            >
              {AuthData?.email}
            </p>
          </div>
        </div>
        <div className="mx-3 border-b-2 DEFAULT:border-default" />
        <form method="POST" action="#" role="none">
          <div className="flex py-4 cursor-pointer my-0 mx-[32%]">
            <img className="w-5 h-5" src={signOut} alt="" />
            <p
              onClick={handleLogout}
              className="block px-2 text-base font-medium outline-none text-active"
              tabIndex="-1"
              id="menu-item-3"
            >
              {t("sign_out")}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};
