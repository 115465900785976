export const SHOW_CALENDAR = "SHOW_CALENDAR";
export const SELECTED_DATE_RANGE = "SELECTED_DATE_RANGE";
export const GET_ALL_STOCKS_BY_DATE = "GET_ALL_STOCKS_BY_DATE";
export const SHOW_MINI_DISTRIBUTORS = "SHOW_MINI_DISTRIBUTORS";
export const SELECTED_DIST = "SELECTED_DIST";
export const GET_DAILY_STOCK_REPORT = "GET_DAILY_STOCK_REPORT";
export const GET_CLOSING_STOCK_REPORT = "GET_CLOSING_STOCK_REPORT";
export const GET_CLOSING_STOCK_REPORT_WITH_SKU = "GET_CLOSING_STOCK_REPORT_WITH_SKU";
export const GET_MINI_ADMIN_CLOSING_STOCK_REPORT = "GET_MINI_ADMIN_CLOSING_STOCK_REPORT";
export const GET_MINI_ADMIN_DAILY_STOCK_REPORT = "GET_MINI_ADMIN_DAILY_STOCK_REPORT";
export const DAILY_STOCK_AVERAGE_ACCURACY = "DAILY_STOCK_AVERAGE_ACCURACY";
export const GET_ALL_DISTRIBUTORS_STOCK_LEVEL = "GET_ALL_DISTRIBUTORS_STOCK_LEVEL";
export const GET_ASSIGNED_DISTRIBUTORS_STOCK_LEVEL = "GET_ASSIGNED_DISTRIBUTORS_STOCK_LEVEL";
export const SET_LOADING = "SET_LOADING";
export const GET_ALL_DISTRIBUTORS_STOCK_LEVEL_WITH_SKU = "GET_ALL_DISTRIBUTORS_STOCK_LEVEL_WITH_SKU";
export const GET_ASSIGNED_DISTRIBUTORS_STOCK_LEVEL_WITH_SKU = "GET_ASSIGNED_DISTRIBUTORS_STOCK_LEVEL_WITH_SKU";
