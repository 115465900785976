import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCustomersInCountryForDistributor } from '../helperFunction';
import {
  getAllDistributorCustomers,
  getCountryCustomers,
  getAllOneOffCustomers
} from "../../modules/Admin/customer/actions/customer";

const useSearchCustomers = (search, currentPage, type) => {
  const [customers, setCustomers] = useState([]);
  const dispatch = useDispatch();
  const { distributor } = useSelector((state) => state.AllDistributorReducer);
  const AuthData = useSelector((state) => state.Auth.sessionUserData);
  const country = AuthData?.country;
  const {
    paginated_customers: all_customers,
    all_dist_customers,
    allOneOffCustomers,
  } = useSelector((state) => state.CustomerReducer);
  const pageSize = 10;

  useEffect(() => {
    switch (type) {
      case 'registered':
        if (currentPage && pageSize && distributor?.SYS_Code) {
          if (fetchAllCustomersInCountryForDistributor(country)) {
            dispatch(getCountryCustomers(country, currentPage, search, "", ""));
          } else {
            dispatch(getAllDistributorCustomers(distributor?.SYS_Code, currentPage, pageSize, search));
          }
        }
        break;
      case 'one-off':
        if (currentPage && pageSize) {
          dispatch(getAllOneOffCustomers(country, currentPage, pageSize, search));
        }
        break;
      default:
        return;
    }
  }, [dispatch, search, country, currentPage, distributor?.SYS_Code, type]);

  useEffect(() => {
    if (!fetchAllCustomersInCountryForDistributor(country) && type === 'registered') {
      if (currentPage === 1) {
        setCustomers(all_dist_customers);
      } else {
        setCustomers(customers => [...customers, ...all_dist_customers]);
      }
    }
  }, [all_dist_customers, type, country, currentPage])

  useEffect(() => {
    if (fetchAllCustomersInCountryForDistributor(country) && type === 'registered') {
      if (currentPage === 1) {
        setCustomers(all_customers);
      } else { 
        setCustomers(customers => [...customers, ...all_customers]);
      }
    }
  }, [all_customers, type, country, currentPage])

  useEffect(() => {
    if (type === 'one-off') {
      if (currentPage === 1) {
        setCustomers(allOneOffCustomers);
      } else { 
        setCustomers(customers => [...customers, ...allOneOffCustomers]);
      }
    }
  }, [allOneOffCustomers, type, country, currentPage])

  return customers;
}

export default useSearchCustomers