import { PUSH_NOTIFICATION } from "../actions/types";

const initial_state = {
  notification: {},
}

const PnReducer = (state = initial_state, action) => {
  switch (action.type) {
    case PUSH_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    default:
      return state;
  }
};
export default PnReducer;
