import { routerMiddleware, routerReducer } from "react-router-redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { loadUser, reducer as oidcReducer } from "redux-oidc";
import thunk from "redux-thunk";
import { OrderReducer } from "../modules/Admin/order/reducers";
import { AllDistributorReducer } from "../modules/Admin/pages/reducers";
import {
  DistributorPricingReducer,
  PricingReducer,
} from "../modules/Admin/Pricing/reducers";
import Auth from "../modules/Auth/reducers/auth.reducer";
import userManager from "../utils/userManager";

import { CustomerReducer } from "../modules/Admin/customer/reducers";
import { DropPointReducer } from "../modules/Admin/drop-point/reducers";
import { AllUsersReducer } from "../modules/Admin/KPO/reducer/";
import { ProductReducer } from "../modules/Admin/Products/reducers";
import { ReportReducer } from "../modules/Admin/Reports/reducers";
import { AllAnalyticsReducer } from "../modules/Analytics/pages/reducers";
import DashboardTabReducer from "../modules/Analytics/pages/reducers/DashboardTabReducer";
import { DistReducer } from "../modules/Distributors/reducers";
import { FinanceReducer } from "../modules/Finance/reducers";
import { InsightsReducer } from "../modules/Insights/reducers";
import { InventoryReducer } from "../modules/Inventory/reducers";
import { LogisticsReducer } from "../modules/Logistics/reducers";
import { PnReducer } from "../modules/PushNotification/reducers";
import { InvoiceReducer } from "../modules/Reports/reducers";
import { ProductCatalogueReducer } from "../modules/Transaction/reducers";
import { VanInventoryReducer } from "../modules/VanInventory/reducer";
import Alert from "./reducers/common/alert.reducer";

export default function configureStore(history, initialState) {
  const reducers = {
    Auth,
    Alert,
    AllDistributorReducer,
    PricingReducer,
    DistributorPricingReducer,
    CustomerReducer,
    DropPointReducer,
    InventoryReducer,
    OrderReducer,
    VanInventoryReducer,
    AllUsersReducer,
    DistReducer,
    ReportReducer,
    FinanceReducer,
    InvoiceReducer,
    oidc: oidcReducer,
    AllAnalyticsReducer,
    DashboardTabReducer,
    ProductCatalogueReducer,
    LogisticsReducer,
    ProductReducer,
    PnReducer,
    InsightsReducer,
  };

  const middleware = [thunk, routerMiddleware(history)];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === "development";
  if (
    isDevelopment &&
    typeof window !== "undefined" &&
    window.devToolsExtension
  ) {
    enhancers.push(window.devToolsExtension());
  }

  const allReducer = combineReducers({
    ...reducers,
    routing: routerReducer,
  });

  const store = createStore(
    allReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
  loadUser(store, userManager);

  return store;
}

