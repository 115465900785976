import i18next from "i18next";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import arrowDown from "../../assets/svg/arrowDown.svg";
import { loadUserDataSuccess } from "../../modules/Auth/actions/auth.action";

import { Countries as countries } from "../../utils/data";
import { useComponentVisible } from "../../utils/hooks";

export const CountryDropdown = ({ position, selectedCountry, Authdata }) => {
  const [isCountryDropdownVisible, setIsCountryDropdownVisible] =
    useState(false);

  const ref = useRef(null);
  const dispatch = useDispatch();

  const countryImage = countries.find(
    (val) => val.name === selectedCountry
  )?.flag;
  useComponentVisible(ref, setIsCountryDropdownVisible);

  const showCountryDropdown = () => {
    setIsCountryDropdownVisible(!isCountryDropdownVisible);
  };

  const handleChange = (e) => {
    const country = e.target.value;
    localStorage.setItem("countryCode", country);
    if (country !== "Mozambique") {
      i18next.changeLanguage("en");
    }
    dispatch(loadUserDataSuccess({ ...Authdata, country: country }));
    setIsCountryDropdownVisible(false);
  };

  return (
    <div className="relative flex items-center mr-14">
      <div
        onClick={showCountryDropdown}
        className="flex items-center cursor-pointer"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        ref={ref}
        role="button"
      >
        <img src={countryImage} alt={`${selectedCountry} flag`} />
        <span className="ml-2 mr-3">{selectedCountry}</span>
        <img alt="p-icon" src={arrowDown} />
      </div>
      {isCountryDropdownVisible && (
        <div
          className={`origin-top-right absolute ${position} rounded-md z-[1000000] w-48 max-h-72 p-4 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
          ref={ref}
        >
          {countries.map((country, index) => (
            <div
              className="flex items-center justify-between mb-3 last:mb-0"
              key={index}
            >
              <div className="flex">
                <img className="w-5 mr-2" alt="" src={country.flag} />
                <span className={`font-medium text-larger`}>
                  {country.name}
                </span>
              </div>
              <input
                type="radio"
                checked={selectedCountry === country.name}
                name="radio"
                className="input"
                value={country.name}
                onChange={handleChange}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
