import { Dialog, Transition } from "@headlessui/react";
import { t } from "i18next";
import { Fragment } from "react";

import { CloseModal } from "../../assets/svg/modalIcons";
import Loader from "./Loading";

const Modal = ({
  show,
  handleClose,
  children,
  modalStyle,
  modalWidth,
  modalZindex,
  onSubmit,
  disabled,
  loading,
  modalFooter,
  closeButtonStyle,
  modalFooterMargin,
  modalHeader,
  type,
}) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 ${modalZindex}`}
        onClose={handleClose}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter={`${
              type === "insights"
                ? "transition-transform ease-out duration-500"
                : "ease-out duration-300"
            }`}
            enterFrom={`${
              type === "insights" ? "transform translate-y-full" : "opacity-0"
            }`}
            enterTo={`${
              type === "insights" ? "transform translate-y-0" : "opacity-100"
            }`}
            leave={`${
              type === "insights"
                ? "transition-transform ease-in duration-500"
                : "ease-in duration-200"
            }`}
            leaveFrom={`${
              type === "insights" ? "transform translate-y-0" : "opacity-100"
            }`}
            leaveTo={`${
              type === "insights" ? "transform translate-y-full" : "opacity-0"
            }`}
          >
            <div
              className={`inline-block bg-white rounded-lg shadow-xl transform transition-all sm:mt-1 sm:align-middle w-10/12 ${
                modalWidth || "lg:w-[800px]"
              } font-customGilroy`}
            >
              <div className={`flex flex-col pt-3 ${modalStyle}`}>
                {modalHeader || (
                  <div
                    onClick={handleClose}
                    className={`cursor-pointer ml-auto px-4 ${closeButtonStyle}`}
                    role="button"
                  >
                    <CloseModal />
                  </div>
                )}
                {children}
                {type !== "insights" && (
                  <div
                    className={`border border-solid border-gray-1 px-4 py-3 sm:px-6 sm:flex justify-end gap-4 rounded-b-lg ${
                      modalFooterMargin || "mt-10"
                    }`}
                  >
                    {modalFooter || (
                      <>
                        <button
                          className="py-2 text-sm not-italic font-bold text-center bg-white border border-solid rounded outline-none px-7"
                          onClick={handleClose}
                        >
                          {t("cancel")}
                        </button>
                        <button
                          className={`bg-gold outline-none rounded text-center text-sm font-bold not-italic
													px-14 py-2 ${disabled ? "opacity-60" : ""}`}
                          onClick={onSubmit}
                          disabled={disabled}
                        >
                          Upload {loading && <Loader />}
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
