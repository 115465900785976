import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import UnsavedDailyStock from "../components/common/UnsavedDailyStock";
import UnsavedEmptiesChanges from "../components/common/UnsavedEmptiesChanges";
import UnsavedExpiryChanges from "../components/common/UnsavedExpiryChange";
import UnsavedProductCatalogueChanges from "../components/common/UnsavedProductCatalogueChanges";
import UnsavedTransferChanges from "../components/common/UnsavedTransferChanges";
import UnsavedVanTransferChanges from "../components/common/UnsavedVanReplenishChange";
import { getAllUsers } from "../modules/Admin/KPO/actions/UsersAction";
import {
  getAllDistributor,
  getSingleDistributor,
} from "../modules/Admin/pages/actions/adminDistributorAction";
import { getLVAV } from "../modules/Inventory/actions/inventoryProductAction";

import UnsavedOtherProducts from "../components/common/UnsavedOtherProducts";
import { DefaultNav } from "./components";

const Navbar = ({
  location,
  distributor,
  getSingleDistributor,
  toggleNavigation,
  pageTitle,
  back,
  total,
}) => {
  const dispatch = useDispatch();
  let code = location.pathname.split("/")[3];

  const transferChange = useSelector(
    (state) => state.InventoryReducer.transferChange
  );
  const vantransferChange = useSelector(
    (state) => state.VanInventoryReducer.transferVanChange
  );
  const productCatalogueChange = useSelector(
    (state) => state.ProductCatalogueReducer.cataloguePriceChange
  );
  const emptiesChange = useSelector(
    (state) => state.InventoryReducer.emptiesChange
  );

  if (code && code !== undefined) {
    localStorage.setItem("distCode", code);
  } else {
    code = localStorage.getItem("distCode");
  }

  const AuthData = useSelector((state) => state.Auth.sessionUserData);
  const country = useSelector((state) => state.Auth.sessionUserData).country;
  const [loadingState, setLoadingState] = useState(true);
  const [result, setResult] = useState([]);
  const KPO_id = parseInt(AuthData?.id);
  const allUsers = useSelector((state) => state.AllUsersReducer.allUsers);

  useEffect(() => {
    dispatch(getAllUsers(country));
    dispatch(getAllDistributor(country));
    if (code) {
      dispatch(getLVAV(code));
    }
  }, [country, dispatch, code]);

  const thisKPO = allUsers?.filter((user) => {
    return user.id === KPO_id;
  });
  const dist_CODES =
    thisKPO?.length > 0 && thisKPO[0]?.roles !== "Admin"
      ? JSON.parse(thisKPO[0]?.DIST_Code)
      : [0];

  const distResult = dist_CODES?.filter((val) => val);

  useEffect(() => {
    if (allUsers?.length > 0) {
      setResult(distResult);
      getSingleDistributor(code);
      if (dist_CODES?.length > 0 && loadingState) {
        setLoadingState(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers?.length, AuthData?.length]);

  const expiredChange = useSelector(
    (state) => state.InventoryReducer.expiredChange
  );

  const otherProductsChange = useSelector(
    (state) => state.InventoryReducer.otherProductsChange
  );

  const dailyStockChange = useSelector(
    (state) => state.InventoryReducer.transferChangeDailyStock
  );

  const component = useMemo(() => {
    if (transferChange) return <UnsavedTransferChanges Dist_Code={code} />;
    if (emptiesChange) return <UnsavedEmptiesChanges Dist_Code={code} />;
    if (dailyStockChange) return <UnsavedDailyStock Dist_Code={code} />;
    if (expiredChange) return <UnsavedExpiryChanges Dist_Code={code} />;
    if (vantransferChange) return <UnsavedVanTransferChanges />;
    if (otherProductsChange) return <UnsavedOtherProducts Dist_Code={code} />;
    if (productCatalogueChange) return <UnsavedProductCatalogueChanges />;
    else
      return (
        <DefaultNav
          back={back}
          code={code}
          pageTitle={pageTitle}
          toggleNavigation={toggleNavigation}
          total={total}
          distributor={distributor}
          result={result}
        />
      );
  }, [
    back,
    code,
    dailyStockChange,
    distributor,
    emptiesChange,
    expiredChange,
    otherProductsChange,
    pageTitle,
    productCatalogueChange,
    result,
    toggleNavigation,
    total,
    transferChange,
    vantransferChange,
  ]);

  return component;
};

const mapStateToProps = (state) => {
  return {
    distributor: state.AllDistributorReducer.distributor,
  };
};

export default connect(mapStateToProps, {
  getSingleDistributor,
})(Navbar);
