import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CallbackComponent } from "redux-oidc";
import Loading from "./components/common/Loading2";
import FallbackPageWrapper from "./FallbackPageWrapper";
import { loadUserDataSuccess } from "./modules/Auth/actions/auth.action";
import AuthLayout from "./modules/Auth/Layout";
import setAuthorization from "./utils/authorization";
import axiosInstance from "./utils/axios";
import urls from "./utils/urls";
import userManager from "./utils/userManager";

const { userNet } = urls;

export const checkRole = (role, history) => {
  //change all occurences role?.data?.data? => role
  if (Object.keys(role).length === 0) {
    return history.push("/");
  }

  const superVid = role?.id;
  const kpoStatus = role?.status;
  const kpoType = role?.roles !== "Admin" && JSON.parse(role?.DIST_Code);

  switch (role && role?.roles) {
    case "KPO":
      if (kpoType === null) {
        return history.push("/auth/unassigned");
      } else if (kpoStatus === "Blocked") {
        return history.push("/auth/blocked");
      } else if (kpoType.length > 1 && kpoType.length !== 1) {
        return history.push(
          `/kpo-supervisor/overview/${kpoType[0]}/${superVid}`
        );
      } else {
        const kpoDistCode = JSON.parse(role?.DIST_Code);
        localStorage.setItem("distCode", role?.DIST_Code);
        return history.push(`/dashboard/overview/${kpoDistCode}`);
      }
    case "Mini-Admin":
      if (kpoType === null) {
        return history.push("/auth/unassigned");
      } else if (kpoStatus === "Blocked") {
        return history.push("/auth/blocked");
      } else {
        return history.push("/min-admin-dashboard");
      }
    case "Admin":
      if (kpoStatus === "Blocked") {
        return history.push("/auth/blocked");
      } else if (role?.moderator === 1) {
        return history.push("/admin-dashboard/countries");
      } else {
        return history.push("/admin-dashboard");
      }
    default:
      history.push("/auth/unassigned");
      break;
  }
};

export const CallbackPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = localStorage.getItem("userData");

  if (!userData) {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={async (user) => {
          try {
            const newUser = await axiosInstance.get(`${userNet}user/login`, {
              headers: { Authorization: `Bearer ${user.id_token}` },
            });
            let token = newUser?.data?.result;
            if (token) {
              const currentUser = jwtDecode(token);
              if (currentUser?.email) {
                setAuthorization(token);
                localStorage.setItem("userData", token);
                localStorage.setItem("countryCode", currentUser.country);
                dispatch(loadUserDataSuccess(currentUser));
                return checkRole(currentUser, history);
              }
            }
          } catch (error) {
            if (error) {
              return await axiosInstance
                .post(`${userNet}register`, { token: user.id_token })
                .then((response) => {
                  let token = response?.data?.result;
                  localStorage.setItem("userData", token);
                  const currentUser = jwtDecode(token);
                  dispatch(loadUserDataSuccess(currentUser));
                  checkRole(currentUser, history);
                });
            }

            localStorage.removeItem("userData");
          }
        }}
        errorCallback={(error) => {
          console.error(error, "Eror");
        }}
      >
        <FallbackPageWrapper>
          <AuthLayout
            main={
              <div className="flex flex-col items-center justify-center flex-none w-full px-3 pb-14 md:mb-0 lg:mt-36 welcome-div-text">
                <p className="px-3 pb-2 text-3xl font-normal text-center text-black-400 font-customGilroy lg:mt-14 lg:pt-8 text-active">
                  {t("please_hole_while_we_take_you")}!
                </p>
                <div>
                  <Loading />
                  <Loading />
                  <Loading />
                </div>
              </div>
            }
          />
        </FallbackPageWrapper>
      </CallbackComponent>
    );
  }

  checkRole(jwtDecode(userData), history);
  return null;
};

export default connect()(CallbackPage);
