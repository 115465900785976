import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setAdminNavigationTitle,
  setDistNavigationTitle,
} from "../../modules/Auth/actions/auth.action";

export function useCustomNavbarTitle(
  type,
  pageTitle,
  back = false,
  total,
  subTitle
) {
  const dispatch = useDispatch();

  const navigationTitleAction =
    type === "dist" ? setDistNavigationTitle : setAdminNavigationTitle;

  useEffect(() => {
    dispatch(navigationTitleAction({ pageTitle, back, total, subTitle }));
  }, [dispatch, navigationTitleAction, pageTitle, back, total, subTitle]);
}
