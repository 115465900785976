import {
  GET_ACCOUNT_RECEIVABLE,
  GET_ACCOUNT_RECEIVABLE_CUSTOMER,
  GET_CURRENT_STOCK_BREAKDOWN,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_HISTORY_DETAILS,
  GET_SALES_BREAKDOWN,
  GET_STOCK_VALUE,
  GET_SUMMARY,
  SET_ACCOUNT_RECEIVABLE_DATE_RANGE,
  SET_CASH_AT_HAND_DATE_RANGE,
  SET_FINANCE_HOME_DATE_RANGE,
  SET_OUTSTANDING_INVOICE_DATE_RANGE,
  SET_PAYMENT_HISTORY_DATE_RANGE,
  SET_TOTAL_SALES_DATE_RANGE,
  UPDATE_PAYMENT,
} from "../actions/types";

const initial_state = {
  totalSales: {
    sum: 0,
    orders: 0,
    report: [],
    count: 0,
    selected_day_range: {},
  },
  cashAtHand: {
    sum: 0,
    orders: 0,
    report: [],
    reportLink: "",
    count: 0,
    selected_day_range: {},
  },
  accountReceivable: {
    sum: 0,
    orders: 0,
    report: [],
    count: 0,
    selected_day_range: {},
  },
  currentStock: {
    sum: 0,
    cases: 0,
    stocks: 0,
  },
  accountReceivableCustomer: {
    sum: 0,
    orders: [],
    totalOrders: 0,
    selected_day_range: {},
  },
  accountReceivableCollectPayment: {
    orders: [],
  },
  paymentHistoryDetails: {
    sum: 0,
    orders: [],
    totalOrders: 0,
    selected_day_range: {},
  },
  paymentStatus: {},
  totalSalesBreakdown: {
    actual: [],
    compare: [],
    percentageDifference: "",
  },
  cashAtHandBreakdown: {
    actual: [],
    compare: [],
    percentageDifference: "",
  },
  accountReceivableBreakdown: {
    actual: [],
    compare: [],
    percentageDifference: "",
  },
  currentStockBreakdown: {
    actual: [],
    compare: [],
    percentageDifference: "",
  },
  paymentDetails: {},
  selected_day_range: {},
};
const FinanceReducer = (state = initial_state, action) => {
  switch (action.type) {
    case GET_SALES_BREAKDOWN:
      const { data } = action;
      if (action.breakdownType === "TS") {
        return {
          ...state,
          totalSalesBreakdown: {
            actual: data.actual,
            compare: data.compare,
            percentageDifference: data.percentageDiff,
          },
        };
      } else if (action.breakdownType === "CAH") {
        return {
          ...state,
          cashAtHandBreakdown: {
            actual: data.actual,
            compare: data.compare,
            percentageDifference: data.percentageDiff,
          },
        };
      } else {
        return {
          ...state,
          accountsReceivableBreakdown: {
            actual: data.actual,
            compare: data.compare,
            percentageDifference: data.percentageDiff,
          },
        };
      }
    case GET_CURRENT_STOCK_BREAKDOWN:
      const { dataVal } = action;
      return {
        ...state,
        currentStockBreakdown: {
          actual: dataVal.actual,
          compare: dataVal.compare,
          percentageDifference: dataVal.percentageDiff,
        },
      };
    case GET_SUMMARY:
      const { payload } = action;
      if (action.reportType === "TS") {
        return {
          ...state,
          totalSales: {
            ...state.totalSales,
            sum: payload.summary.sumTotalSales,
            orders: payload.summary.totalOrders,
            report: payload.totalSales,
            count: payload.summary.count,
          },
        };
      } else if (action.reportType === "CAH") {
        return {
          ...state,
          cashAtHand: {
            ...state.cashAtHand,
            sum: payload.summary.sumTotalCashAtHand,
            orders: payload.summary.totalOrders,
            report: payload.cashAtHand,
            count: payload.summary.count,
          },
        };
      } else {
        return {
          ...state,
          accountReceivable: {
            ...state.accountReceivable,
            sum: payload.summary.sumTotalAmountReceivable,
            orders: payload.summary.totalOrders,
            count: payload.summary.count,
          },
        };
      }
    case GET_STOCK_VALUE:
      return {
        ...state,
        currentStock: {
          sum: action.payload.totalStockAmount,
          cases: action.payload.totalCases,
          stocks: action.payload.stocks,
        },
      };
    case GET_ACCOUNT_RECEIVABLE:
      return {
        ...state,
        accountReceivable: {
          ...state.accountReceivable,
          sum: action.payload.summary.amount,
          count: action.payload.summary.totalCustomers,
          report: action.payload.customers,
        },
      };
    case GET_PAYMENT_HISTORY_DETAILS:
      return {
        ...state,
        paymentHistoryDetails: {
          ...state.paymentHistoryDetails,
          totalOrders: action.payload.summary.countOrders,
          orders: action.payload.orders,
        },
      };
    case GET_ACCOUNT_RECEIVABLE_CUSTOMER:
      if (action.page === 1) {
        return {
          ...state,
          accountReceivableCollectPayment: {
            ...state.accountReceivableCollectPayment,
            orders: action.payload.orders,
          },
          accountReceivableCustomer: {
            ...state.accountReceivableCustomer,
            sum: action.payload.summary.amount,
            totalOrders: action.payload.summary.countOrders,
            orders: action.payload.orders,
          },
        };
      } else {
        return {
          ...state,
          accountReceivableCustomer: {
            ...state.accountReceivableCustomer,
            sum: action.payload.summary.amount,
            totalOrders: action.payload.summary.countOrders,
            orders: action.payload.orders,
          },
        };
      }

    case UPDATE_PAYMENT:
      return {
        ...state,
        paymentStatus: action.payload,
      };
    case GET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: action.payload,
      };
    case SET_PAYMENT_HISTORY_DATE_RANGE:
      return {
        ...state,
        paymentHistoryDetails: {
          ...state.paymentHistoryDetails,
          selected_day_range: action.range,
        },
      };
    case SET_OUTSTANDING_INVOICE_DATE_RANGE:
      return {
        ...state,
        accountReceivableCustomer: {
          ...state.accountReceivableCustomer,
          selected_day_range: action.range,
        },
      };
    case SET_ACCOUNT_RECEIVABLE_DATE_RANGE:
      return {
        ...state,
        accountReceivable: {
          ...state.accountReceivable,
          selected_day_range: action.range,
        },
      };
    case SET_TOTAL_SALES_DATE_RANGE:
      return {
        ...state,
        totalSales: {
          ...state.totalSales,
          selected_day_range: action.range,
        },
      };
    case SET_CASH_AT_HAND_DATE_RANGE:
      return {
        ...state,
        cashAtHand: {
          ...state.cashAtHand,
          selected_day_range: action.range,
        },
      };
    case SET_FINANCE_HOME_DATE_RANGE:
      return {
        ...state,
        selected_day_range: action.range,
      };
    default:
      return state;
  }
};

export default FinanceReducer;
