import {
  ADD_CUSTOMERS,
  ADD_ONE_OFF_CUSTOMERS,
  ADD_ONE_OFF_CUSTOMERS_FAILED,
  ADD_ONE_OFF_CUSTOMERS_START,
  ALL_ONE_OFF_CUSTOMERS,
  ALL_ONE_OFF_CUSTOMERS_FAILED,
  ALL_ONE_OFF_CUSTOMERS_START,
  CLEAR_ONE_OFF_CUSTOMERS,
  COUNTRY_CUSTOMERS,
  CURRENT_CUSTOMER,
  EDIT_CUSTOMERS,
  SET_LOADING,
  SET_TOTAL_PAGES,
  VIEW_ALL_DISTRIBUTOR_CUSTOMER_ORDERS,
  VIEW_ALL_DISTRIBUTOR_CUSTOMERS,
  VIEW_MINI_ADMIN_DISTRIBUTOR_CUSTOMERS,
  VIEW_SINGLE_DISTRIBUTOR_CUSTOMER,
  VIEW_SINGLE_DISTRIBUTOR_CUSTOMER_ORDER,
} from "../actions/types";

const initial_state = {
  customer: [],
  all_dist_customers: [],
  all_dist_customers_order: [],
  customer_order: [],
  distributor_orders: [], //all orders of a particular distributor
  all_customers: [],
  loading: true,
  loadAllCustomers: true,
  allOneOffCustomers: [],
  loadingallOneOffCustomers: false,
  allOneOffCustomersError: {},
  addOneOffCustomers: {},
  loadingAddOneOffCustomers: false,
  addOneOffCustomersError: {},
  total_pages: 1,
  paginated_customers: [],
  customers_count: 0,
  customers_one_off_count: 0,
  editCustomer: false,
  editCustomerLoading: false,
};

const customerReducer = (state = initial_state, action) => {
  switch (action.type) {
    case ADD_CUSTOMERS:
      return {
        ...state,
        all_dist_customers: [
          ...state.all_dist_customers,
          action.customer.data.result,
        ],
      };
    case VIEW_ALL_DISTRIBUTOR_CUSTOMERS: {
      return {
        ...state,
        all_dist_customers: action.all_customers,
        customers_count: action.count,
        loading: false,
      };
    }
    case VIEW_MINI_ADMIN_DISTRIBUTOR_CUSTOMERS: {
      return {
        ...state,
        all_dist_customers: action.all_customers,
        customers_count: action.count,
        loading: false,
      };
    }
    case COUNTRY_CUSTOMERS: {
      return {
        ...state,
        paginated_customers: action.paginated_customers,
        customers_count: action.customers_count,
        loading: false,
      };
    }
    case VIEW_ALL_DISTRIBUTOR_CUSTOMER_ORDERS: {
      return {
        ...state,
        all_dist_customers_order: action.all_dist_customers_order,
      };
    }
    case VIEW_SINGLE_DISTRIBUTOR_CUSTOMER_ORDER: {
      return {
        ...state,
        customer_order: action.orders,
        total_pages: action.total_pages
          ? action.total_pages
          : state.total_pages,
      };
    }
    case EDIT_CUSTOMERS: {
      return {
        ...state,
        editCustomer: action.editCustomer,
        editCustomerLoading: action.loading,
      };
    }
    case CURRENT_CUSTOMER: {
      return { ...state, customer: action.customer };
    }
    case VIEW_SINGLE_DISTRIBUTOR_CUSTOMER: {
      return { ...state, customer: action.customer };
    }
    case ALL_ONE_OFF_CUSTOMERS_START: {
      return { ...state, loadingallOneOffCustomers: true };
    }

    case ALL_ONE_OFF_CUSTOMERS: {
      return {
        ...state,
        loadingallOneOffCustomers: false,
        allOneOffCustomers: action.payload.result,
        customers_one_off_count: action.payload.count,
      };
    }
    case ALL_ONE_OFF_CUSTOMERS_FAILED: {
      return {
        ...state,
        loadingallOneOffCustomers: false,
        allOneOffCustomers: null,
        allOneOffCustomersError: action.payload,
      };
    }
    case ADD_ONE_OFF_CUSTOMERS_START: {
      return { ...state, loadingAddOneOffCustomers: true };
    }

    case ADD_ONE_OFF_CUSTOMERS: {
      return {
        ...state,
        loadingAddOneOffCustomers: false,
        addOneOffCustomers: action.payload,
      };
    }
    case ADD_ONE_OFF_CUSTOMERS_FAILED: {
      return {
        ...state,
        loadingAddOneOffCustomers: false,
        addOneOffCustomersError: action.payload,
        addOneOffCustomers: null,
      };
    }
    case CLEAR_ONE_OFF_CUSTOMERS: {
      return {
        ...state,
        loadingAddOneOffCustomers: false,
        addOneOffCustomersError: {},
        addOneOffCustomers: {},
      };
    }
    case SET_TOTAL_PAGES: {
      return { ...state, total_pages: action.total_pages };
    }
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    default:
      return state;
  }
};

export default customerReducer;
