import { useCallback, useContext } from "react";
import { FallbackContext } from "../../context/FallbackProvider";

export const usePageRoute = () => {
  const { updateFallback } = useContext(FallbackContext);

  const onLoad = useCallback(
    (component) => {
      if (component === undefined) {
        component = null;
      }
      updateFallback(component);
    },
    [updateFallback]
  );

  return { onLoad };
};

export default usePageRoute;
