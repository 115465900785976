import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Redirect } from "../../../assets/svg/adminIcons";
import Loading from "../../../components/common/Loading";
import Pagination from "../components/pagination";

import Search from "../../../components/common/Search";

const AdminDistributorLayout = ({ top, DistributorList }) => {
  const { t } = useTranslation();
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [distData, setDistData] = useState("");
  const history = useHistory();
  const AuthData = useSelector((state) => state.Auth.sessionUserData);

  const filterByStatus = (status) => {
    const filterFn =
      status === "Active"
        ? (data) => data?.status === status
        : (data) => data?.status !== "Active";
    return DistributorList.filter(filterFn).length;
  };

  const navigate = (code) => {
    const dashboardPath =
      AuthData.roles === "Mini-Admin"
        ? "/min-admin-dashboard"
        : "/admin-dashboard";
    return history.push(`${dashboardPath}/distributor/${code}`);
  };

  const fetchDistributors = useCallback(() => {
    if (!DistributorList) {
      return [];
    }

    const searchQuery = distData ? distData.toLowerCase() : "";

    return DistributorList.filter((data) => {
      const { company_name, DIST_Code, SYS_Code, status, state } = data || {};

      const fieldsToSearch = [
        company_name,
        DIST_Code,
        SYS_Code,
        state,
        status,
      ].map((field) => (field ? String(field).toLowerCase() : ""));

      return fieldsToSearch.some((field) => field.includes(searchQuery));
    });
  }, [DistributorList, distData]);

  const currentTableData = useCallback(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return (
      DistributorList &&
      fetchDistributors().slice(firstPageIndex, lastPageIndex)
    );
  }, [currentPage, PageSize, fetchDistributors, DistributorList]);

  useEffect(() => {
    currentTableData();
  }, [currentTableData]);

  return (
    <>
      <div
        className={`relative flex flex-col min-w-0 break-words bg-white w-full ${top} shadow-lg rounded`}
      >
        <div className="flex flex-wrap">
          <div className="w-full">
            <div className="flex-auto py-5">
              <div className="tab-content tab-space">
                <div className="block">
                  <div className="flex flex-col items-start px-4 mt-3 lg:px-8 lg:flex-row lg:items-center lg:justify-between">
                    <Search
                      setSearchTerm={setDistData}
                      styleClass="xl:w-[560px]"
                      placeholder={t("search_for_distributor")}
                      setCurrentPage={setCurrentPage}
                    />
                    <div className="flex flex-wrap flex-shrink-0 pt-3 lg:pt-0 w-fit gap-x-4 gap-y-3">
                      <button
                        className={`text-center whitespace-nowrap font-normal px-2 py-1.5 rounded-md block bg-white border-default-b border-2 cursor-pointer set-tab
												bg-${distData === "" ? "gold" : "white"}
												`}
                        // product-cont-active
                        onClick={() => {
                          setDistData("");
                        }}
                      >
                        <p
                          className={`text-default font-normal whitespace-nowrap text-${
                            distData === "" && "grey-100"
                          }`}
                        >
                          {/* product-text-active */}
                          {t("all_distributors")}
                        </p>{" "}
                      </button>
                      <button
                        className={`text-center whitespace-nowrap font-normal lg:mt-0 px-2 py-1.5 rounded-md block bg-white border-default-b border-2 cursor-pointer set-tab
												bg-${distData === "active" ? "gold" : "white"}
												`}
                        onClick={() => {
                          setDistData("active");
                        }}
                      >
                        <p
                          className={`text-default font-normal whitespace-nowrap text-${
                            distData === "active" && "grey-100"
                          }`}
                        >
                          {t("active")} ({filterByStatus("Active")})
                        </p>{" "}
                      </button>
                      <button
                        className={`text-center font-normal px-2 whitespace-nowrap py-1.5 rounded-md block bg-white border-default-b border-2 cursor-pointer set-tab
												bg-${distData === null ? "gold" : "white"}
												`}
                        onClick={() => {
                          setDistData(null && "");
                        }}
                      >
                        <p
                          className={`text-default font-normal whitespace-nowrap text-${
                            distData === null && "grey-100"
                          }`}
                        >
                          {t("inactive")} ({filterByStatus("inactive")})
                        </p>{" "}
                      </button>
                    </div>
                  </div>
                  {!DistributorList.length ? (
                    <center className="my-8">
                      <Loading />
                      <Loading />
                      <Loading />
                    </center>
                  ) : (
                    <div className="w-full overflow-x-auto">
                      <table className="min-w-full mt-8 divide-y divide-gray-200">
                        <thead className="bg-transparent">
                          <tr>
                            <th className="px-10 py-3 text-xs font-medium text-center text-black align-middle">
                              S/N
                            </th>
                            <th className="px-10 py-3 text-xs font-medium text-center text-black align-middle">
                              {t("distributor_code")}
                            </th>
                            <th className="px-10 py-3 text-xs font-medium text-center text-black align-middle">
                              {t("distributor_name")}
                            </th>
                            <th className="px-10 py-3 text-xs font-medium text-center text-black align-middle">
                              {t("state/city")}
                            </th>
                            <th className="px-10 py-3 text-xs font-medium text-center text-black align-middle">
                              {t("country")}
                            </th>
                            <th className="px-10 py-3 text-xs font-medium text-center text-black align-middle">
                              {t("status")}
                            </th>
                            <th className="px-10 py-3">{"          "}</th>
                          </tr>
                        </thead>

                        <tbody
                          id="distributors"
                          className="px-6 bg-white divide-y divide-gray-200"
                        >
                          {DistributorList &&
                            currentTableData().map((distributor, index) => (
                              <tr key={distributor?.id}>
                                <td className="p-6 text-sm font-medium text-center align-middle font-customGilroy">
                                  {index + 1 + "."}
                                </td>
                                <td className="p-6 text-sm font-medium text-center align-middle font-customGilroy">
                                  {distributor?.SYS_Code}
                                </td>
                                <td
                                  onClick={() =>
                                    navigate(distributor?.DIST_Code)
                                  }
                                  className="p-6 text-sm font-medium text-center align-middle cursor-pointer font-customGilroy"
                                >
                                  <p className="text-sm font-medium font-customGilroy">
                                    {distributor?.company_name}
                                  </p>
                                </td>
                                <td className="p-6 text-sm font-medium text-center align-middle font-customGilroy">
                                  {distributor?.state}
                                </td>
                                <td className="p-6 text-sm font-medium text-center align-middle font-customGilroy">
                                  {distributor?.country}
                                </td>
                                <td className="p-6 text-sm font-medium text-center text-white align-middle font-customGilroy">
                                  <button
                                    className={`rounded-full ${
                                      distributor?.status === "Active"
                                        ? "bg-green-500"
                                        : "bg-red-500"
                                    } py-1 px-3`}
                                  >
                                    {distributor?.status || "Inactive"}
                                  </button>
                                </td>

                                <td className="flex gap-1 pb-6 text-sm font-medium text-center align-middle border-none font-customGilroy pt-7">
                                  <Redirect />
                                  <Link
                                    target="_blank"
                                    to={`/dashboard/overview/${distributor?.DIST_Code}`}
                                  >
                                    <u>{t("dashboard")}</u>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <hr />
                  <div className="flex items-center justify-end mt-6">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={fetchDistributors().length}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDistributorLayout;
