import { Dialog, Transition } from "@headlessui/react";
import { t } from "i18next";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Checked, CloseModal } from "../../../assets/svg/modalIcons";
import Loading from "../../../components/common/Loading";
import { formatNumber } from "../../../utils/formatNumber";
import {
  formatEmptiesQuantity,
  stripProductTypeFromSku,
  stripSkuFromBrandName,
} from "../../../utils/helperFunction";
import {
  getAllInventory,
  setDailyStockModal,
  updateAccurateDailyStockCount,
} from "../../Inventory/actions/inventoryProductAction";
import Tag from "../../Inventory/components/Tag";

const DailyStockCountModal = ({ code, countryConfigObject, country }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [save, setSave] = useState("Yes, I'm sure");
  const [loading, setLoading] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const dailyStockModal = useSelector(
    (state) => state.InventoryReducer.dailyStockModal
  );

  const allInventory = useSelector(
    (state) => state.InventoryReducer.all_inventory
  );

  const stock_count_updated = useSelector(
    (state) => state.InventoryReducer.stock_count_updated
  );

  useEffect(() => {
    dispatch(getAllInventory(code));
  }, [code, dispatch]);

  useEffect(() => {
    if (stock_count_updated) {
      setLoading(false);
      setSave("Yes, I'm sure");
      setApprovalModal(false);
      setSuccessModal(true);
    }
  }, [stock_count_updated]);

  const updateDB = () => {
    let stocks = [];
    setLoading(true);
    setSave("Please wait");
    allInventory.forEach((value) => {
      stocks.push({
        productId: value.productId,
        quantity: value.quantity,
      });
    });
    const toDB = {
      companyCode: code,
      country: country === "South Africa" ? "SA" : country,
      accurate: true,
      stocks,
    };
    dispatch(updateAccurateDailyStockCount(toDB));
  };

  const closeDailyStockSuccessModal = () => {
    setSuccessModal(false);
  };

  return (
    <>
      <Transition.Root show={dailyStockModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {
            "";
          }}
        >
          <div className="flex items-end justify-center w-screen min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={`font-customGilroy inline-block align-bottom bg-white pt-6 rounded-lg text-left overflow-hidden
                shadow-xl transform transition-all sm:my-8 lg:mt-12 lg:mb-10 sm:align-middle sm:w-11/12 max-h-screen lg:w-modal`}
              >
                <div className="px-8 mb-7">
                  <p className="text-[32px] text-grey-100">
                    {t("daily_stock_count")}
                  </p>
                  <p className="mt-4 font-normal font-base text-grey-85">
                    Is this your inventory as of today?
                  </p>
                </div>
                <div className="overflow-y-scroll daily-stock-modal scrollbar-hide">
                  <table className="w-full divide-y divide-gray-200 font-customGilroy">
                    <thead className="bg-transparent">
                      <tr className="font-semibold">
                        <th
                          scope="col"
                          className="px-8 pb-3 text-sm tracking-wider text-black"
                        >
                          Product
                        </th>
                        <th
                          scope="col"
                          className="w-48 px-6 pb-3 text-sm tracking-wider text-black"
                        >
                          {t("Quantity")}
                          <Tag className="bg--blue" tagName={t("fulls")} />
                        </th>
                        <th
                          scope="col"
                          className="w-48 px-6 pb-3 text-sm tracking-wider text-black"
                        >
                          {t("Quantity")}
                          <Tag className="bg--accent" tagName={t("empties")} />
                        </th>
                      </tr>
                    </thead>
                    <tbody className="px-6 bg-white divide-y divide-gray-200">
                      {allInventory.map((value, index) => (
                        <tr key={index}>
                          <td className="px-8 py-2">
                            <div className="flex items-center h-16">
                              <div className="flex-shrink-0 w-10">
                                <img
                                  className="w-10 rounded-full"
                                  src={value?.product?.imageUrl}
                                  alt={value?.product?.brand}
                                />
                              </div>
                              <div className="ml-2">
                                <div className="text-sm font-medium text-gray-900 whitespace-nowrap">
                                  {stripSkuFromBrandName(value?.product?.brand)}
                                </div>
                                <div className="inline-flex text-xs leading-5 font-customGilroy">
                                  {stripProductTypeFromSku(value?.product?.sku)}
                                  <span className="ml-1">
                                    ({value?.product?.productType})
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="w-48 px-6 text-sm">
                            {formatNumber(value?.quantity)}
                          </td>
                          <td
                            className={`text-sm w-48 px-6 ${
                              formatEmptiesQuantity(
                                value?.product?.productType,
                                value.empties
                              ) === "Nil" && "text--accent"
                            }`}
                          >
                            {formatEmptiesQuantity(
                              value?.product?.productType,
                              value.empties
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="flex flex-row-reverse gap-4 px-4 py-4 border border-solid bg-gray-50 sm:px-6 lg:px-8">
                  <button
                    className="py-3 text-sm not-italic font-bold text-center rounded bg-gold font-customGilroy text-grey-100 px-14"
                    onClick={() => {
                      dispatch(setDailyStockModal(false));
                      setApprovalModal(true);
                    }}
                  >
                    Yes
                  </button>

                  <button
                    className="py-3 text-sm not-italic font-bold text-center bg-white border border-solid rounded font-customGilroy text-grey-70 px-14"
                    onClick={() => {
                      dispatch(setDailyStockModal(false));
                      history.push("/dashboard/daily-stock/" + code);
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={approvalModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {
            "";
          }}
        >
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle w-mini-modal">
                <CloseModal
                  className="m-4 mb-0 ml-auto"
                  onClick={() => {
                    setApprovalModal(false);
                    dispatch(setDailyStockModal(true));
                  }}
                />
                <div className="flex items-center justify-center h-mini-modal">
                  <p className="px-4 text-base not-italic font-medium font-customGilroy">
                    Are you sure this is your correct inventory as of today?
                  </p>
                </div>
                <div className="flex flex-row-reverse flex-wrap gap-4 px-4 py-3 border border-solid bg-gray-50 sm:px-6">
                  <button
                    className="flex items-center py-2 text-sm not-italic font-bold text-center rounded bg-gold font-customGilroy text-grey-100 px-14"
                    disabled={loading}
                    onClick={() => updateDB()}
                  >
                    {save}
                    {loading ? <Loading /> : ""}
                  </button>

                  <button
                    className="py-2 text-sm not-italic font-bold text-center bg-white border border-solid rounded font-customGilroy text-grey-70 px-7"
                    onClick={() => {
                      setApprovalModal(false);
                      dispatch(setDailyStockModal(true));
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={successModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeDailyStockSuccessModal}
        >
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle w-mini-modal">
                <button
                  className="flex justify-center m-4 mb-0 ml-auto"
                  onClick={closeDailyStockSuccessModal}
                >
                  <CloseModal />
                </button>
                <div className="flex flex-col items-center justify-center h-mini-modal">
                  <Checked />
                  <p className="mt-4 text-xl not-italic font-medium text-center font-customGilroy text-grey-85">
                    Daily stock count completed
                  </p>
                </div>
                <div className="flex flex-row-reverse flex-wrap gap-4 px-4 py-3 border border-solid bg-gray-50 sm:px-6">
                  <button
                    className="py-2 text-sm not-italic font-bold text-center rounded bg-gold font-customGilroy text-grey-100 px-14"
                    onClick={closeDailyStockSuccessModal}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DailyStockCountModal;
