import { GET_KSM_INSIGHTS, GET_WHITE_SPACING_INSIGHTS } from "../actions/types";

const initial_state = {
	allInsights: [],
	whiteSpacing: {},
	isLoading: false,
};

const InsightsReducer = (state = initial_state, action) => {
  switch (action.type) {
    case GET_KSM_INSIGHTS:
      return {
        ...state,
        allInsights: action.all_insights.data,
      };
    case GET_WHITE_SPACING_INSIGHTS:
      return {
        ...state,
        whiteSpacing: action.white_spacing_insights,
        isLoading: action.isLoading
      };
    default:
      return state;
  }
};

export default InsightsReducer;
