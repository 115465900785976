import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

const PrivateRoute = ({ component: Component, oidc, ...rest }) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("userData");
  let AuthData = "";
  if (token) {
    AuthData = jwtDecode(token);
  }
  const code = location.pathname.split("/")[3];

  useEffect(() => {
    if (
      AuthData &&
      (AuthData?.DIST_Code === null ||
        AuthData?.DIST_Code === "" ||
        AuthData.roles === "Admin" ||
        AuthData.roles === "Mini-Admin" ||
        (JSON.parse(AuthData?.DIST_Code) &&
          JSON.parse(AuthData?.DIST_Code).includes(code)))
    ) {
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, [AuthData, code]);

  return (
    !loading && (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: `/`, state: { from: props.location } }} />
          )
        }
      />
    )
  );
};

export default PrivateRoute;
