import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ConnectivityListener = () => {
  useConnectivityListener();
  return null;
};

export function useConnectivityListener() {
  const [isOnline, setOnline] = useState(
    window ? window.navigator.onLine : false
  );
  const toastId = useRef(null);

  useEffect(() => {
    const onlineHandler = () => setOnline(true);
    const offlineHandler = () => setOnline(false);

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  useUpdateEffect(() => {
    const content = (
      <>
        <strong>{isOnline ? "Online" : "Offline"}</strong>
        <div>
          {isOnline
            ? "You can continue your work."
            : "Your device is currently offline."}
        </div>
      </>
    );

    // remove the existing offline notification if it exists, otherwise store
    // the added toast id for use later
    const callback = isOnline
      ? () => {
          toast.dismiss(toastId.current);
          toastId.current = null;
        }
      : (id) => {
          toastId.current = id;
        };

    // add the applicable toast
    toast(content, { type: "info", autoDismiss: isOnline }, callback);
  }, [isOnline]);
}

/**
 * A custom useEffect hook that only triggers on updates, not on initial mount
 * @param {Function} effect
 */
function useUpdateEffect(effect, deps = []) {
  const initialMount = useRef(true);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
    } else {
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  // figure out error
  <ToastContainer />;
}
