import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Checked, CloseModal } from "../../assets/svg/modalIcons";
import { getSingleDistributor } from "../../modules/Admin/pages/actions/adminDistributorAction";
import {
  discardChanges,
  getAllInventory,
  receiveNewStock,
  setInventoryDocument,
  updateTransferChange,
} from "../../modules/Inventory/actions/inventoryProductAction";
import axiosInstance from "../../utils/axios";
import urls from "../../utils/urls";
import Loading from "./Loading";

const { distributorNet, inventoryNet } = urls;

const UnsavedTransferChanges = ({ Dist_Code }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [approvalModal, setApprovalModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [discardModal, setDiscardModal] = useState(false);
  const [save, setSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const AuthData = useSelector((state) => state.Auth.sessionUserData);
  const country = useSelector((state) => state.Auth.sessionUserData).country;

  const transferQuantity = useSelector(
    (state) => state.InventoryReducer.transfer_quantities
  );

  const returnEmpties = useSelector(
    (state) => state.InventoryReducer.return_quantities
  );

  const receiveStock = useSelector(
    (state) => state.InventoryReducer.receive_new_stock
  );

  const inventoryDocumentDetails = useSelector(
    (state) => state.InventoryReducer.inventoryDocumentDetails
  );
  const approval_modal = useSelector(
    (state) => state.InventoryReducer.approval_modal
  );

  const docNo = inventoryDocumentDetails?.invoiceNumber;
  const orderNo = inventoryDocumentDetails?.orderNumber;
  const truckNo = inventoryDocumentDetails?.driver;
  const remarks = inventoryDocumentDetails?.remarks;
  let dist_code = useSelector((state) => state.DistReducer.dist_code);
  let dist_details = useSelector(
    (state) => state.AllDistributorReducer.distributor
  );
  const roles = AuthData.roles;
  if (!dist_code) {
    dist_code = Dist_Code || location.pathname.split("/")[3];
  }

  useEffect(() => {
    if (dist_code) {
      dispatch(getSingleDistributor(dist_code));
    }
  }, [dispatch, dist_code]);

  useEffect(() => {
    if (approval_modal) {
      setApprovalModal(true);
    } else {
      setApprovalModal(false);
    }
  }, [approval_modal]);

  const handleReset = () => {
    setApprovalModal(false);
  };

  const updateDB = async () => {
    setSave(t("updating"));
    setLoading(true);

    let toDB = {};
    if (
      transferQuantity.type === "setup" ||
      transferQuantity.type === "new-stock"
    ) {
      toDB["companyCode"] = dist_code;
      toDB["truckNo"] =
        truckNo === undefined || truckNo === "" ? "set-up" : truckNo;
      toDB["docNo"] = docNo === undefined || docNo === "" ? "set-up" : docNo;
      toDB["orderNo"] =
        orderNo === undefined || orderNo === "" ? "set-up" : orderNo;
      toDB["remarks"] =
        orderNo === undefined || remarks === "" ? "set-up" : remarks;
      toDB["country"] = country;
      toDB["stocks"] = transferQuantity.stockCounted;
      if (transferQuantity.type === "new-stock") {
        toDB["invoiceNo"] =
          docNo === undefined || docNo === "" ? "set-up" : docNo;
      }

      if (returnEmpties.length > 0) {
        let returnData = {
          companyCode: dist_code,
          stocks: returnEmpties?.map((item) => ({
            productId: parseInt(item.productId),
            quantity: parseInt(item.quantity),
          })),
        };

        try {
          const takeOutEmpties = await axiosInstance.post(
            `${inventoryNet}empties/take-out`,
            returnData
          );
          const addStock = await axiosInstance.post(
            `${inventoryNet}add-stock/`,
            toDB
          );
          const companyStatus = await axiosInstance.patch(
            `${distributorNet}company-status/status/${dist_details.SYS_Code}`,
            {
              status: "Active",
            }
          );

          console.log(takeOutEmpties, addStock, companyStatus);
        } catch (err) {}

        axiosInstance
          .post(`${inventoryNet}empties/take-out`, returnData)
          .then(() => {
            axiosInstance.post(`${inventoryNet}add-stock/`, toDB).then(() => {
              axiosInstance
                .patch(
                  `${distributorNet}company-status/status/${dist_details.SYS_Code}`,
                  {
                    status: "Active",
                  }
                )
                .then(() => {
                  setApprovalModal(false);
                  setSuccessModal(true);
                  dispatch(getAllInventory(dist_code));
                  dispatch(setInventoryDocument({}));
                  dispatch(updateTransferChange(false));
                  dispatch(receiveNewStock(false));
                  localStorage.removeItem(`inventory-check-${Dist_Code}`);
                  setTimeout(() => {
                    history.push("/dashboard/inventory/" + dist_code);
                  }, 3000);
                });
            });
          });
      } else {
        axiosInstance.post(`${inventoryNet}add-stock/`, toDB).then(() => {
          axiosInstance
            .patch(
              `${distributorNet}company-status/status/${dist_details.SYS_Code}`,
              {
                status: "Active",
              }
            )
            .then(() => {
              setApprovalModal(false);
              setSuccessModal(true);
              dispatch(getAllInventory(dist_code));
              dispatch(setInventoryDocument({}));
              dispatch(updateTransferChange(false));
              dispatch(receiveNewStock(false));
              localStorage.removeItem(`inventory-check-${Dist_Code}`);
              setTimeout(() => {
                history.push("/dashboard/inventory/" + dist_code);
              }, 3000);
            });
        });
      }
    }
    // add an else if block to pop out modal with reasons and confirm button
    else {
      toDB["companyCode"] = dist_code;
      toDB["data"] = transferQuantity.stockCounted;
      axiosInstance.put(`${inventoryNet}edit`, toDB).then(() => {
        setApprovalModal(false);
        setSuccessModal(true);
        setSave(t("update_inventory"));
        setLoading(false);
        dispatch(updateTransferChange(false));
        dispatch(getAllInventory(dist_code));
        setTimeout(() => {
          history.push("/dashboard/inventory/" + dist_code);
        }, 2000);
      });
    }
  };

  return (
    <>
      {!receiveStock && (
        <Fade>
          <div className="bg-gold pl-3 lg:pr-8 py-3 w-full justify-between flex shadow-[0px_4px_16px_rgba(89, 85, 84, 0.15)] h-20 p-3">
            <div className="w-[100px]"></div>
            {/* <img src={whiteLogo} width="178px" height="48px" alt="logo" /> */}
            <div className="py-3 text-xl italic font-bold text-gray-100 font-customGilroy">
              {t("you_have_unsaved_changes_Do_you_want_to_save_your_changes?")}
            </div>
            <div className="inline-flex justify-between">
              <div
                className="p-3 mr-6 text-base font-semibold text-center text-white bg-transparent border border-white rounded cursor-pointer "
                onClick={() => {
                  setDiscardModal(true);
                }}
                onKeyDown={() => {
                  setDiscardModal(true);
                }}
                role="button"
              >
                {t("discard_changes")}
              </div>
              {roles === "Mini-Admin" ? (
                <button className="bg-[#BEBEBE] text-dark text-base font-semibold text-center w-[5.5rem] cursor-pointer rounded p-3">
                  Save
                </button>
              ) : (
                <div
                  className=" bg-dark text-gold text-base font-semibold text-center w-[5.5rem] cursor-pointer rounded p-3"
                  onClick={() => {
                    setApprovalModal(true);
                  }}
                  onKeyDown={() => {
                    setApprovalModal(true);
                  }}
                  role="button"
                >
                  {t("save")}
                </div>
              )}
            </div>
          </div>
        </Fade>
      )}

      <Transition.Root show={approvalModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setApprovalModal}
        >
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle w-mini-modal">
                <CloseModal
                  className="m-4 mb-0 ml-auto"
                  onClick={handleReset}
                />
                <div className="flex items-center justify-center h-mini-modal">
                  <p className="px-4 text-base not-italic font-medium font-customGilroy">
                    {transferQuantity.type === "edit" ||
                    (transferQuantity.type === "new-stock" &&
                      returnEmpties.length > 0)
                      ? t("are_you_sure_you_want_to_update_your_inventory?")
                      : transferQuantity.type === "setup"
                      ? t("save_all_changes_made_to_the_inventory?")
                      : t(
                          "are_you_sure_you_want_to_add_the_selection(s)_to_your_inventory?"
                        )}
                  </p>
                </div>
                <div className="flex flex-row-reverse gap-4 px-4 py-3 border border-solid sm:px-6">
                  <button
                    className="flex px-5 py-2 text-sm not-italic font-bold text-center text-gray-100 rounded bg-gold font-customGilroy"
                    onClick={() => updateDB()}
                  >
                    {save || transferQuantity.type === "edit"
                      ? t("update_inventory")
                      : transferQuantity.type === "setup"
                      ? t("yes, save")
                      : t("add_to_inventory")}
                    {loading ? <Loading /> : ""}
                  </button>

                  <button
                    className="py-2 text-sm not-italic font-bold text-center bg-white border border-solid rounded font-customGilroy text-grey-70 px-7"
                    onClick={handleReset}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={successModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={setSuccessModal}
        >
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle">
                <button
                  className="flex justify-center m-4 mb-0 ml-auto"
                  onClick={() => setSuccessModal(false)}
                >
                  <CloseModal />
                </button>
                <div className="flex flex-col items-center justify-center w-modal h-36">
                  <Checked />
                  <p className="mt-4 text-xl not-italic font-medium text-center font-customGilroy text-grey-85">
                    {t("your_inventory_has_been_updated")}
                  </p>
                </div>
                <div className="flex flex-row-reverse flex-wrap gap-4 px-4 py-3 border border-solid bg-gray-50 sm:px-6">
                  <button
                    className="py-2 text-sm not-italic font-bold text-center text-gray-100 rounded bg-gold font-customGilroy px-14"
                    onClick={() => {
                      dispatch(getAllInventory(dist_code));
                      dispatch(updateTransferChange(false));
                      history.push("/dashboard/inventory/" + dist_code);
                    }}
                  >
                    {t("okay")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={discardModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={setDiscardModal}
        >
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle">
                <button
                  className="flex justify-center m-4 mb-0 ml-auto"
                  onClick={() => setDiscardModal(false)}
                >
                  <CloseModal />
                </button>
                <div className="flex flex-col items-center justify-center h-40 my-4 w-modal">
                  <p className="px-12 mt-4 text-xl not-italic font-medium text-center font-customGilroy text-grey-85">
                    {t("discard_all_changes")}
                  </p>
                </div>
                <div className="gap-4 px-4 py-3 border border-solid sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    className="px-5 py-2 text-sm not-italic font-bold text-center text-gray-100 rounded bg-gold font-customGilroy"
                    onClick={() => {
                      dispatch(discardChanges());
                      window.location.reload();
                    }}
                  >
                    {t("yes, discard changes")}
                  </button>
                  <button
                    className="py-2 text-sm not-italic font-bold text-center bg-white border border-solid rounded font-customGilroy text-grey-70 px-7"
                    onClick={() => setDiscardModal(false)}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default UnsavedTransferChanges;
