export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const UPLOAD_PRODUCT_IMAGE = "UPLOAD_PRODUCT_IMAGE"
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_DRAFTS = "GET_ALL_DRAFTS";
export const LOADING_PRODUCTS = "LOADING_PRODUCTS";
export const DELETE_DRAFT_SUCCESSFUL = "DELETE_DRAFT_SUCCESSFUL";
export const DELETE_SUCCESSFUL = "DELETE_SUCCESSFUL";
export const PRODUCT_UPDATE_SUCCESSFUL = "PRODUCT_UPDATE_SUCCESSFUL";
export const PRODUCT_UPDATE_SUCCESSFUL_RESET = "PRODUCT_UPDATE_SUCCESSFUL_RESET"; 
export const 	GET_PRODUCT_BY_ID= "GET_PRODUCT_BY_ID";
export const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER";
export const GET_DRAFT_BY_ID = "GET_DRAFT_BY_ID";
export const SET_PRODUCT_SUCCESSFUL = "SET_PRODUCT_SUCCESSFUL";
export const SET_DRAFT_PRODUCT_SUCCESSFUL  = "SET_DRAFT_PRODUCT_SUCCESSFUL";
export const SET_UPLOAD_BULK_PRODUCT = "SET_UPLOAD_BULK_PRODUCT";
